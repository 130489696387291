import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewRef,
} from '@angular/core';

@Component({
  selector: 'app-pin',
  templateUrl: 'pin.html',
  styleUrls: ['./pin.scss'],
})
export class PinComponent {
  @Input() classDiv: string;
  @Input() classInputPin: string;
  @Input() codeColor: string;
  @Input() codePresent: string;
  @Input() code: string;
  @Input() idInput: string;

  @Output() validate = new EventEmitter<any>();

  arrayInputs = [0, 1, 2, 3];

  constructor(public cdRef: ChangeDetectorRef) {}

  makeNumber(code): string {
    this.codePresent = '----';
    switch (code.length) {
      case 4:
        return code.slice(0, 3) + code.charAt(code.length - 1);
      case 3:
        return code.slice(0, 2) + code.charAt(code.length - 1) + '-';
      case 2:
        return code.slice(0, 1) + code.charAt(code.length - 1) + '--';
      case 1:
        return code.charAt(code.length - 1) + '---';
      default:
        return '----';
    }
  }

  validarCampo(): void {
    this.codePresent = this.makeNumber(this.code);
    !(this.cdRef as ViewRef).destroyed && this.cdRef.detectChanges();

    this.validate.emit({
      code: this.code,
      codePresent: this.codePresent,
      pin: this,
    });
  }

  alloweddDigit(i: number): boolean {
    switch (i) {
      case 0:
        return !!this.code;
      case 1:
        return !(this.code.length === 1);
      case 2:
        return !(this.code.length === 2);
      case 3:
        return this.code.length <= 2;
    }
  }

  onDigitInput(event, i: number): void {
    let element;
    if (
      event.code !== 'Backspace' &&
      !isNaN(parseInt(event.key)) &&
      this.code.length < 4
    ) {
      element = event.srcElement.nextElementSibling;
      this.code += event.key;
      this.validarCampo();
    }

    if (event.code === 'Backspace') {
      element = event.srcElement.previousElementSibling;
      if (this.code.length === i + 1) {
        this.code = this.code.slice(0, -1);
        this.validarCampo();
      }
    }

    if (element == null) return;
    else element.focus();
  }

  cleanData(): void {
    let doc;
    this.arrayInputs.forEach(elem => {
      doc = document.getElementById(`inp-${elem}-${this.idInput}`);
      if (doc) {
        doc.value = '';
      }
    });

    this.code = '';
    this.codePresent = '----';
  }
}
