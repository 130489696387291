import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TabOptions } from '@models/entities/general';

@Component({
  selector: 'app-list-tab',
  templateUrl: './list-tab.html',
  styleUrls: ['./list-tab.scss'],
})
export class ListTabComponent {
  @Input() list: TabOptions[];
  @Input() classDivFirst: string;
  @Input() classDiv: string;
  @Input() classDivTabs: string;
  @Input() title: string;
  @Input() classTitle: string;

  @Input() clickOpt: boolean;

  @Output() ClickAction = new EventEmitter();

  constructor(private router: Router) {}

  tabChange(tabChangeEvent: MatTabChangeEvent): void {
    const indexTab = tabChangeEvent.index,
      item = this.list[indexTab];
    if (this.clickOpt) {
      this.list.forEach((tab, j) => (tab.operCSS = indexTab === j));
      this.ClickAction.emit(item.action);
    } else {
      this.router.navigate([item.url]);
    }
  }

  selectedTab(): number {
    const ind = this.list.findIndex(tab => tab.url === this.router.url);
    return ind !== -1 ? ind : 0;
  }
}
