import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  @HostListener('keypress', ['$event'])
  onInput(event: KeyboardEvent) {
    const pattern = /[0-9]/; // without ., for integer only
    const inputChar = String.fromCharCode(
      event.which ? event.which : event.keyCode
    );

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
      return false;
    }
    return true;
  }

  @HostListener('paste', ['$event'])
  onPase(event: ClipboardEvent) {
    const pasteText = event.clipboardData.getData('text'),
      convertNumber = Number(pasteText);
    return !isNaN(convertNumber) && typeof convertNumber === 'number';
  }
}
