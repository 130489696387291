import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-title-textArea',
  templateUrl: './title-textArea.html',
})
export class TitleTextAreaComponent {
  @Input() classDiv: string;
  @Input() title: string;
  @Input() textAreaControl: FormControl;
}
