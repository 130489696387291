import { createAction, props } from '@ngrx/store';
import { SnackBar } from '@models/entities/general';

export const CHANGE_THEME = '[shared state] change theme';
export const SHOW_SNACKBAR = '[shared state] show snackbar';
export const CLOSE_SNACKBAR = '[shared state] close snackbar';
export const SET_ERROR_MESSAGE = '[shared state] set error message';

export const changeTheme = createAction(
  CHANGE_THEME,
  props<{ theme: boolean }>()
);

export const showSnackbar = createAction(SHOW_SNACKBAR, props<SnackBar>());

export const closeSnackbar = createAction(CLOSE_SNACKBAR);

export const setErrorMessage = createAction(
  SET_ERROR_MESSAGE,
  props<{ message: string }>()
);
