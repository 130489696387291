// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-spinner {
  background: rgba(0, 0, 0, 0.3);
  top: 65px;
}
.progress-spinner mat-spinner {
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,SAAA;AACF;AAAE;EACE,qBAAA;EACA,sBAAA;AAEJ","sourcesContent":[".progress-spinner {\n  background: rgba(0, 0, 0, 0.3);\n  top: 65px; // DELETE\n  mat-spinner {\n    top: calc(50% - 50px);\n    left: calc(50% - 50px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
