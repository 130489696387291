import { MenuNavbarUrl, NavbarList } from '@models/entities/navbar';
import { ROUTES_ADMIN } from './routes';

const accountProduct: MenuNavbarUrl = {
  icon: 'manage-accounts',
  name: 'navbar.accountProduct',
  route: ROUTES_ADMIN.ACCOUNT_PRODUCTS_PAYMENTS,
  enable: true,
};
const suscription: MenuNavbarUrl = {
  icon: 'rocket-launch',
  name: 'navbar.suscription',
  route: ROUTES_ADMIN.SUSCRIPTIONS,
  enable: true,
};
const accessSecurity: MenuNavbarUrl = {
  icon: 'vpn-key',
  name: 'navbar.accessSecurity',
  route: ROUTES_ADMIN.ACCESS_SECURITY,
  enable: true,
};
const enterprisesConnect: MenuNavbarUrl = {
  icon: 'code',
  name: 'navbar.enterprisesConnect',
  route: ROUTES_ADMIN.ENTERPRISES_CONNECT,
  enable: true,
};
// const  posConnect  = {icon: 'code', name: 'navbar.posConnect', route: ROUTES_ADMIN.POS_CONNECT, enable: true}
const paymentMethod: MenuNavbarUrl = {
  icon: 'DEFAULT_PAYMENT',
  name: 'navbar.paymentMethod',
  route: ROUTES_ADMIN.PAYMENTS_METHOD,
  enable: true,
};
const accountPayments: MenuNavbarUrl = {
  icon: 'insert-chart',
  name: 'navbar.accountPayments',
  route: ROUTES_ADMIN.ACCOUNT_PAYMENTS,
  enable: true,
};

const guides: MenuNavbarUrl = {
  name: 'navbar.guides',
  route: ROUTES_ADMIN.GUIDES,
  enable: false,
};
const openSupportTicket: MenuNavbarUrl = {
  name: 'navbar.openSupportTicket',
  route: ROUTES_ADMIN.OPEN_TICKET_SUPPORT,
  enable: false,
};
const visitBlog: MenuNavbarUrl = {
  name: 'navbar.visitBlog',
  route: ROUTES_ADMIN.VISIT_BLOG,
  enable: false,
};
// const  programFinance: MenuNavbarUrl = {name: 'navbar.programFinance', route: ROUTES_ADMIN.PROGRAM_FINANCE, enable: true}

export const NAV_LEVEL_LIST: NavbarList = {
  owner: {
    topNav: [
      accountProduct,
      suscription,
      accessSecurity,
      enterprisesConnect,
      // posConnect,
      paymentMethod,
      accountPayments,
    ],
    bottomNav: [
      guides,
      openSupportTicket,
      visitBlog,
      // programFinance
    ],
  },
  admin: {
    topNav: [
      accountProduct,
      suscription,
      accessSecurity,
      enterprisesConnect,
      accountPayments,
    ],
    bottomNav: [guides, openSupportTicket, visitBlog],
  },
  dev: {
    topNav: [accountProduct, enterprisesConnect],
    bottomNav: [openSupportTicket, visitBlog],
  },
};
