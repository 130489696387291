import { Injectable } from '@angular/core';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { MethodName, RequestResponse } from '@enums/account-products.enum';
import { ResponseEcommerceCredentials } from '@models/responses/response-credentials';
import { ResponseGeneral } from '@models/responses/response-general';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { ModalService } from '@providers/modal/modal.service';
import { TranslationService } from '@deprecated/providers/translation/translation.service';
import { CredentialsService } from '@deprecated/services/credentials/credentials.service';
import {
  credentialEndpointActions,
  credentialEndpointActionsResponse,
  ecommerceCredentialsResponse,
  getEcommerceCredentials,
  requestEcommerce,
  requestEcommerceResponse,
} from '@deprecated/state/actions/credentials.actions';
import { showSnackbar } from '@deprecated/state/actions/shared.actions';
import { AppState } from '@deprecated/state/app.state';
import { catchError, exhaustMap, map, of } from 'rxjs';

@Injectable()
export class CredentialsEffects {
  constructor(
    private actions$: Actions,
    private credentialService: CredentialsService,
    private loading: LoaderManagmentService,
    private modal: ModalService,
    private store: Store<AppState>,
    private translation: TranslationService
  ) {}

  requestEcommerce$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestEcommerce),
      exhaustMap(action => {
        return this.credentialService.requestEcommerce(action.params).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            this.loading.dismissLoading();
            if (success) {
              const {
                accountProduct: {
                  product: { newAccount },
                },
                actions: { understood },
              } = this.translation.languageMessage;
              const data = {
                title: newAccount.successTitle,
                message: newAccount.successDescription,
                btnSave: understood,
              };
              this.modal.showAlertComp(NormalModalComponent, data);
              return requestEcommerceResponse({
                statusEcommerce: RequestResponse.SUCCESS,
              });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return requestEcommerceResponse({
                statusEcommerce: RequestResponse.FAILED,
              });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            this.store.dispatch(showSnackbar(errResp));
            return of(
              requestEcommerceResponse({
                statusEcommerce: RequestResponse.FAILED,
              })
            );
          })
        );
      })
    );
  });

  getEcommerceCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getEcommerceCredentials),
      exhaustMap(() => {
        return this.credentialService.getEcommerceCredentials().pipe(
          map((response: ResponseEcommerceCredentials) => {
            const { success, message, description, data } = response;
            this.loading.dismissLoading();
            if (success) {
              return ecommerceCredentialsResponse({ ecommerceList: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return ecommerceCredentialsResponse({ ecommerceList: [] });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            this.store.dispatch(showSnackbar(errResp));
            return of(ecommerceCredentialsResponse({ ecommerceList: [] }));
          })
        );
      })
    );
  });

  endpointActions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(credentialEndpointActions),
      exhaustMap(action => {
        return this.credentialService[action.methodName](
          action.id,
          action.params,
          action.update
        ).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            if (success) {
              return credentialEndpointActionsResponse({
                statusResponse: RequestResponse.SUCCESS,
                methodName: action.methodName,
              });
            } else {
              this.loading.dismissLoading();
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return credentialEndpointActionsResponse({
                statusResponse: RequestResponse.FAILED,
                methodName: action.methodName,
              });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            if (action.methodName === MethodName.DISCONNECT_CREDENTIAL) {
              const {
                global: { passer_error_title, passer_error_message },
                actions: { understood },
              } = this.translation.languageMessage;
              this.modal.showAlertComp(NormalModalComponent, {
                title: errResp?.title ?? passer_error_title,
                message: errResp?.message ?? passer_error_message,
                btnSave: understood,
              });
            } else {
              this.store.dispatch(showSnackbar(errResp));
            }
            return of(
              credentialEndpointActionsResponse({
                statusResponse: RequestResponse.FAILED,
                methodName: action.methodName,
              })
            );
          })
        );
      })
    );
  });
}
