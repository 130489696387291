import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.html',
  styleUrls: ['./custom-table.scss'],
})
export class CustomTableComponent<T> {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() classDiv: string;
  @Input() descriptionEmpty: string;
  @Input() emptyData: boolean;
  @Input() listBody: any[] = [];
  @Input() listBodyFilter: any[] = [];
  @Input() listHeader: T[] = [];
  @Input() noBody: boolean;
  @Input() paginator = 1;
  @Input() titleEmpty: string;
  @Input() totalItems = 0;

  @Output() changePager = new EventEmitter();
  @Output() selectedCheck = new EventEmitter();
  @Output() selectedMenu = new EventEmitter();

  checkBefore: number[];
  currentCheck: number[];

  changeCheckSelected(indexRow, act) {
    this.listBody.forEach((row, i) => (row[0].act = i === indexRow && act));
    this.selectedCheck.emit();
  }

  changePaginator(pager): void {
    this.paginator = pager;
    this.changePager.emit(pager);
  }

  selectedOptionMenu(option, currentIndex) {
    this.selectedMenu.emit({ option, currentIndex });
  }
}
