import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.scss'],
})
export class ToggleButtonsComponent implements OnInit {
  @Input() currentTab: number;
  @Input() parentClass: string;
  @Input() text1: string;
  @Input() text2: string;
  @Input() classDiv: string;

  @Output() changeTab = new EventEmitter<number>();

  isChecked = false;

  ngOnInit() {
    this.isChecked = !!this.currentTab;
  }

  onChange(checked) {
    this.changeTab.emit(!checked ? 0 : 1);
  }
}
