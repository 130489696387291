import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { LoginSlider } from '@enums/slider.enum';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { CodeService } from '@deprecated/services/code/code.service';
import { changePosition, loginSuccess } from '@deprecated/state/actions/auth.actions';
import {
  sendCodeSuccess,
  sendCodeTo,
  verifyCode,
  verifyCodeRecover,
  verifyCodeRecoverSuccess,
} from '@deprecated/state/actions/code.actions';
import { setErrorMessage, showSnackbar } from '@deprecated/state/actions/shared.actions';
import { AppState } from '@deprecated/state/app.state';
import { ResponseGeneral } from '@models/responses/response-general';
import { ResponseLogin } from '@models/responses/response-login';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';

@Injectable()
export class CodeEffects {
  constructor(
    private actions$: Actions,
    private codeService: CodeService,
    private loading: LoaderManagmentService,
    private sessionData: SessiondataService,
    private store: Store<AppState>
  ) {}

  sendCodeTo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendCodeTo),
      exhaustMap(action => {
        return this.codeService.sendCode(action.sendCode.type).pipe(
          map(() => {
            this.loading.dismissLoading();
            const { resend } = action.sendCode;
            if (!resend) {
              this.store.dispatch(
                changePosition({
                  pagePosition: {
                    previous: LoginSlider.twoFactor,
                    current: LoginSlider.sendCode,
                  },
                })
              );
            } else {
              this.store.dispatch(showSnackbar(resend));
            }
            return sendCodeSuccess();
          }),
          catchError(error => {
            this.loading.dismissLoading();
            return of(showSnackbar(error));
          })
        );
      })
    );
  });

  verifyCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(verifyCode),
      exhaustMap(action => {
        return this.codeService.verifyCode(action.verificationCode).pipe(
          map((response: ResponseLogin) => {
            const { success, message } = response;
            if (success) {
              this.loading.dismissLoading();
              this.store.dispatch(setErrorMessage({ message: '' }));
              return loginSuccess({
                ...action.twoFactorData,
                verifyCode: true,
                redirect: true,
                updateManagementGroup: false,
                deleteAccess: false,
              });
            } else {
              return showSnackbar({ title: '', message });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar({ ...errResp }));
          })
        );
      })
    );
  });

  verifyCodeRecover$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(verifyCodeRecover),
      exhaustMap(action => {
        return this.codeService
          .verifyCodeRecover(action.verificationCode, action.email)
          .pipe(
            map((response: ResponseGeneral) => {
              const { success, data, description } = response;
              if (success) {
                this.sessionData.accessToken = data;
                this.loading.dismissLoading();
                this.store.dispatch(setErrorMessage({ message: '' }));
                return verifyCodeRecoverSuccess();
              } else {
                this.store.dispatch(setErrorMessage({ message: description }));
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(setErrorMessage({ message: errResp.message }));
            })
          );
      })
    );
  });

  verifyCodeRecoverSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[verifyCodeRecoverSuccess]),
        tap(() => {
          this.store.dispatch(
            changePosition({
              pagePosition: {
                previous: LoginSlider.recoverPassword,
                current: LoginSlider.newPassword,
              },
            })
          );
        })
      );
    },
    { dispatch: false }
  );
}
