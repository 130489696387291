import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ParamsCheckEcommerce,
  ParamsConnectEcommerce,
  ParamsCreateEcommerceCredential,
  ParamsEcommerce,
} from '@models/entities/account';
import {
  ResponseCheckEcommerce,
  ResponseEcommerceCredentials,
  ResponseSubscriptionConnected,
} from '@models/responses/response-credentials';
import { ResponseGeneral } from '@models/responses/response-general';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private url = '';

  private readonly ECOMMERCE = '/api/ecommerce';
  private readonly ECOMMERCE_MERCHANT = '/api/ecommerce/merchant';
  private readonly ECOMMERCE_CREDENTIAL = '/api/ecommerce/credential';
  private readonly SUBSCRIPTION_CONNECTED = '/api/payments';
  private readonly REQUEST = '/api/ecommerce/check';

  constructor(private http: HttpClient) {
    this.url = environment.SERVER_URL;
  }

  async getEcommerceCredentials(): Promise<ResponseEcommerceCredentials> {
    const request$ = this.http.get<ResponseEcommerceCredentials>(
      this.url + this.ECOMMERCE_CREDENTIAL
    );

    return await lastValueFrom(request$);
  }

  async requestEcommerce(params: ParamsEcommerce): Promise<ResponseGeneral> {
    const request$ = this.http.post<ResponseGeneral>(
      this.url +
        (params.isEcommerceCredentials
          ? this.ECOMMERCE
          : this.ECOMMERCE_MERCHANT),
      params
    );

    return await lastValueFrom(request$);
  }

  async disconnectCredential(
    suscriptions: string | number
  ): Promise<ResponseGeneral> {
    const disconnect$ = this.http.delete<ResponseGeneral>(
      this.url + this.SUBSCRIPTION_CONNECTED,
      { body: { suscriptions } }
    );

    return await lastValueFrom(disconnect$);
  }

  async getSubscriptionConnected(
    subscriptionId: number
  ): Promise<ResponseSubscriptionConnected> {
    const connected$ = this.http.get<ResponseSubscriptionConnected>(
      `${this.url}${this.SUBSCRIPTION_CONNECTED}/${subscriptionId}`
    );

    return await lastValueFrom(connected$);
  }

  async saveConnection(
    id: string | number,
    params: ParamsConnectEcommerce,
    update = false
  ): Promise<ResponseGeneral> {
    let request$: any;
    if (update) {
      request$ = this.http.put<ResponseGeneral>(
        `${this.url}${this.SUBSCRIPTION_CONNECTED}/${id}`,
        params
      );
    } else {
      request$ = this.http.post<ResponseGeneral>(
        this.url + this.SUBSCRIPTION_CONNECTED,
        params
      );
    }

    return await lastValueFrom(request$);
  }

  async deleteCredentialEcommerce(
    id: string | number
  ): Promise<ResponseGeneral> {
    const delete$ = this.http.delete<ResponseGeneral>(
      `${this.url}${this.ECOMMERCE}/${id}`
    );

    return await lastValueFrom(delete$);
  }

  async updateCredentialEcommerce(
    id: string | number,
    params: { acquirerFee: number; iva: string }
  ): Promise<ResponseGeneral> {
    const update$ = this.http.put<ResponseGeneral>(
      `${this.url}${this.ECOMMERCE_CREDENTIAL}/${id}`,
      params
    );

    return await lastValueFrom(update$);
  }

  async checkEcommerce(
    params: ParamsCheckEcommerce
  ): Promise<ResponseCheckEcommerce> {
    const check$ = this.http.post<ResponseCheckEcommerce>(
      this.url + this.REQUEST,
      params
    );

    return await lastValueFrom(check$);
  }

  async ecommerceCredential(
    params: ParamsCreateEcommerceCredential
  ): Promise<ResponseGeneral> {
    const credential$ = this.http.post<ResponseGeneral>(
      this.url + this.ECOMMERCE_CREDENTIAL,
      params
    );

    return await lastValueFrom(credential$);
  }
}
