import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'base-checkbox',
  templateUrl: './base-checkbox.component.html',
  styleUrls: ['./base-checkbox.component.scss'],
})
export class BaseCheckboxComponent {
  @Input() color = 'primary';
  @Input() height = 'medium';
  @Input() name = '';
  @Input() text = '';
  @Input() checkboxClass = '';
  @Input() checkboxControl = new FormControl(false);
  @Input() checkboxModel = false;
  @Input() checkboxValue = false;
  @Output() checkboxValueChanged = new EventEmitter<boolean>();

  changeCheckSelected(): void {
    this.checkboxValueChanged.emit(this.checkboxModel);
  }
}
