// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  margin: auto;
  width: 70%;
  transform: translateY(5%);
}

.label-switch,
.toggle {
  border-radius: 100px;
}

.label-switch {
  cursor: pointer;
}

.toggle {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.names {
  font-weight: bolder;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
  top: 50%;
  transform: translateY(-50%);
}

#switch {
  display: none;
}

#switch:checked + .content .toggle {
  transform: translateX(100%);
}`, "",{"version":3,"sources":["webpack://./src/components/toggle-buttons/toggle-buttons.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,yBAAA;AACF;;AAEA;;EAEE,oBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,0CAAA;EACA,+DAAA;AACF;;AAEA;EACE,mBAAA;EACA,8BAAA;EACA,yBAAA;UAAA,iBAAA;EACA,QAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".content {\n  margin: auto;\n  width: 70%;\n  transform: translateY(5%);\n}\n\n.label-switch,\n.toggle {\n  border-radius: 100px;\n}\n\n.label-switch {\n  cursor: pointer;\n}\n\n.toggle {\n  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);\n  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);\n}\n\n.names {\n  font-weight: bolder;\n  justify-content: space-between;\n  user-select: none;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n#switch {\n  display: none;\n}\n\n#switch:checked + .content .toggle {\n  transform: translateX(100%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
