import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CatalogsService } from '@deprecated/services/catalogs/catalogs.service';
import {
  getCountries,
  getCountriesSuccess,
  getCountriesFailure,
  getTypesId,
  getTypesIdSuccess,
  getTypesIdFailure,
  getCurrencies,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  getOcupationsFailure,
  getOcupationsSuccess,
  getBusinessActSuccess,
  getBusinessActFailure,
  getBusinessAct,
  getOcupations,
  getFundSource,
  getFundSourceSuccess,
  getFundSourceFailure,
} from '@deprecated/state/actions/catalogs.actions';
import { Catalogs } from '@enums/catalogs.enum';

@Injectable()
export class CatalogsEffects {
  constructor(
    private actions$: Actions,
    private catalogsService: CatalogsService
  ) {}

  getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountries),
      exhaustMap(() =>
        this.catalogsService.getCatalogsType(Catalogs.GET_COUNTRIES).pipe(
          map((response: any) => {
            return getCountriesSuccess({ countries: response.data });
          }),
          catchError(() => of(getCountriesFailure()))
        )
      )
    )
  );

  getTypesOfIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTypesId),
      exhaustMap(() =>
        this.catalogsService.getCatalogsType(Catalogs.GET_TYPE_ID).pipe(
          map((response: any) => {
            return getTypesIdSuccess({ typesId: response.data });
          }),
          catchError(() => of(getTypesIdFailure()))
        )
      )
    )
  );

  getCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrencies),
      exhaustMap(() =>
        this.catalogsService.getCatalogsType(Catalogs.GET_CURRENCY_LIST).pipe(
          map((response: any) => {
            return getCurrenciesSuccess({ currencies: response.data });
          }),
          catchError(() => of(getCurrenciesFailure()))
        )
      )
    )
  );

  getOcupations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOcupations),
      exhaustMap(() =>
        this.catalogsService.getCatalogsType(Catalogs.GET_OCUPATIONS).pipe(
          map((response: any) => {
            return getOcupationsSuccess({ ocupations: response.data });
          }),
          catchError(() => of(getOcupationsFailure()))
        )
      )
    )
  );

  getBusinessAct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBusinessAct),
      exhaustMap(() =>
        this.catalogsService.getCatalogsType(Catalogs.GET_ACT_COM).pipe(
          map((response: any) => {
            return getBusinessActSuccess({ businessActs: response.data });
          }),
          catchError(() => of(getBusinessActFailure()))
        )
      )
    )
  );

  getFundSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFundSource),
      exhaustMap(() =>
        this.catalogsService.getCatalogsType(Catalogs.GET_FUND_SOURCE).pipe(
          map((response: any) => {
            return getFundSourceSuccess({ fundSources: response.data });
          }),
          catchError(() => of(getFundSourceFailure()))
        )
      )
    )
  );
}
