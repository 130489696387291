import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TitleDescComponent } from '@components/title-desc/title-desc';
import { SnackBar } from '@models/entities/general';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  renderer: Renderer2;
  snackBarOpened: MatSnackBarRef<any>;
  snackData: SnackBar[] = [];

  constructor(
    public snackBar: MatSnackBar,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // --------------- THEME -------------------

  changeTheme(theme) {
    if (theme) {
      this.renderer.addClass(this.document.body, 'dark-theme');
    } else {
      this.renderer.removeClass(this.document.body, 'dark-theme');
    }

    return { theme };
  }

  // --------------- SNACKBAR -----------------------

  reviewSnackbarRep(item, title: string, message: string): boolean {
    return (
      item.findIndex(
        data => data.title === title && data.message === message
      ) === -1
    );
  }

  showSnackBar(title: string, message: string, duration = 5) {
    if (this.reviewSnackbarRep(this.snackData, title, message)) {
      this.snackData.push({ title, message, duration });
      if (!this.snackBarOpened) {
        this.openSnackBar(title, message, duration);
        return this.afterCloseSnack();
      }
    }
  }

  openSnackBar(title, desc, duration): void {
    this.snackBarOpened = this.snackBar.openFromComponent(TitleDescComponent, {
      duration: duration * 1000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      data: {
        classDiv: 'text-center',
        title,
        desc,
        classTitle: 'f13 mt-0',
        classDesc: 'f12 m-0',
      },
    });
  }

  afterCloseSnack() {
    this.snackBarOpened.afterDismissed().subscribe(() => {
      this.snackBarOpened = null;
      if (this.snackData.length > 0) this.snackData.splice(0, 1);
      if (this.snackData.length > 0) {
        const snack = this.snackData.pop();
        this.showSnackBar(snack.title, snack.message, snack.duration);
      }
    });
  }
}
