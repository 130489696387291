import { ActionReducerMap } from '@ngrx/store';
import { AuthState } from '@models/state/auth.state';
import { CodeState } from '@models/state/code.state';
import { SharedState } from '@models/state/shared.state';
import { authReducer } from './reducers/auth.reducer';
import { codeReducer } from './reducers/code.reducer';
import { sharedReducer } from './reducers/shared.reducer';
import { CatalogsState } from '@models/state/catalogs.state';
import { catalogsReducer } from './reducers/catalogs.reducer';
import { SubscriptionState } from '@models/state/subscription.state';
import { subscriptionReducer } from './reducers/subscription.reducer';
import { PaymentMethodState } from '@models/state/payment-method.state';
import { paymentMethodReducer } from './reducers/payment-method.reducer';
import { TransactionState } from '@models/state/transaction.state';
import { transactionReducer } from './reducers/transactions.reducer';
import { AccessSecurityState } from '@models/state/access-security.state';
import { accessSecurityReducer } from './reducers/access-security.reducer';
import { CredentialsState } from '@models/state/credentials.state';
import { credentialsReducer } from './reducers/credentials.reducer';

export interface AppState {
  accessSecurity: AccessSecurityState;
  auth: AuthState;
  catalogs: CatalogsState;
  code: CodeState;
  credentials: CredentialsState;
  paymentMethod: PaymentMethodState;
  shared: SharedState;
  subscription: SubscriptionState;
  transactions: TransactionState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  accessSecurity: accessSecurityReducer,
  auth: authReducer,
  catalogs: catalogsReducer,
  code: codeReducer,
  credentials: credentialsReducer,
  paymentMethod: paymentMethodReducer,
  shared: sharedReducer,
  subscription: subscriptionReducer,
  transactions: transactionReducer,
};
