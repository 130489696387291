// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  background-color: white;
  top: 170px;
  width: 100%;
  height: calc(100% - 130px);
  z-index: 1000;
  transition: max-height 1s ease-in;
}
.skeleton__list-div .element {
  background-color: #eff1f7;
  animation: pulse 2s infinite;
}
@media screen and (min-width: 992px) {
  .skeleton {
    width: calc(100% - 10px);
  }
}

@keyframes pulse {
  0% {
    background-color: #eff1f7;
  }
  50% {
    background-color: #f8fafc;
  }
  100% {
    background-color: #eff1f7;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/skeleton/skeleton.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,UAAA;EACA,WAAA;EACA,0BAAA;EACA,aAAA;EAKA,iCAAA;AACF;AACI;EACE,yBAAA;EACA,4BAAA;AACN;AAEE;EAjBF;IAkBI,wBAAA;EACF;AACF;;AAEA;EACE;IACE,yBAAA;EACF;EACA;IACE,yBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".skeleton {\n  background-color: white;\n  top: 170px;\n  width: 100%;\n  height: calc(100% - 130px);\n  z-index: 1000;\n  -webkit-transition: max-height 1s ease-in;\n  -moz-transition: max-height 1s ease-in;\n  -ms-transition: max-height 1s ease-in;\n  -o-transition: max-height 1s ease-in;\n  transition: max-height 1s ease-in;\n  &__list-div {\n    .element {\n      background-color: #eff1f7;\n      animation: pulse 2s infinite;\n    }\n  }\n  @media screen and (min-width: 992px) {\n    width: calc(100% - 10px);\n  }\n}\n\n@keyframes pulse {\n  0% {\n    background-color: #eff1f7;\n  }\n  50% {\n    background-color: #f8fafc;\n  }\n  100% {\n    background-color: #eff1f7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
