import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PasswordType } from '@enums/input-type.enum';

@Component({
  selector: 'base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
})
export class BaseInputComponent {
  
  @Input() inputClass= '';
  @Input() labelClass= '';
  @Input() label = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() type: any = PasswordType.TEXT;
  @Input() floating = true;
  @Input() hide = false;
  @Input() isPass = false;
  @Input() disabled: any = false;
  @Input() readonly = false;
  @Input() formDataControl: FormControl;
  @Input() maxLength: any = 256;
  @Input() minLength: any = 0;

  @Input() color = 'primary';
  @Input() height = 'medium';
  @Input() rounded = '';

  @Output() changeValue = new EventEmitter<any>();
  @Output() onKeyUp = new EventEmitter<any>();

  passwordIcon = PasswordType.EYE;

  hideShowPassword(): void {
    this.type = this.type === PasswordType.TEXT ? PasswordType.PASSWORD : PasswordType.TEXT;
    this.passwordIcon = this.passwordIcon === PasswordType.EYE_OFF ? PasswordType.EYE : PasswordType.EYE_OFF;
  }

  changeInputValue(): void {
    this.changeValue.emit();
  }

  focusAndGo($event: any): void {
    if (this.onKeyUp) {
      this.onKeyUp.emit($event);
    }
  }

  isDisabled(): string {
    return this.disabled || this.formDataControl.disabled ? 'disabled' : '';
  }

  isReadonly(): string {
    return this.readonly && !this.formDataControl.value ? 'disabled' : '';
  }
}
