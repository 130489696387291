import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-text',
  templateUrl: './icon-text.html',
})
export class IconTextComponent {
  @Input() classDiv: string;
  @Input() icon: string;
  @Input() classIcon = '';
  @Input() iconColor = 'primary';
  @Input() text: string;
}
