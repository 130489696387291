export enum TwoFactorSelector {
  email = 0,
  phone = 1,
}

export enum TwoFactorSelectorName {
  email = 'email',
  phone = 'phone',
}

export enum TypeSendCode {
  EMAIL = 'MAIL',
  PHONE = 'SMS',
}
