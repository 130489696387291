export const ACCOUNT_PRODUCTS_SUB_ROUTE = {
  ACCOUNT: '/account-product/account',
  PRODUCTS: '/account-product/product',
  MY_GROUPS: '/account-product/my-groups',
};

export const SUBSCRIPTION_SUB_ROUTE = {
  LIST: '/subscription/list',
  NEW: '/subscription/new',
};

export const ACCESS_SECURITY_SUB_ROUTE = {
  ACCESS_REGISTER: '/access-security/access-register',
  NEW_ACCESS: '/access-security/new-access',
};

export const ENTERPRISES_CONNECT_SUB_ROUTE = {
  CREDENTIALS: '/enterprises-connect/credentials',
};

export const ACCOUNT_PAYMENTS_SUB_ROUTE = {
  TRANSACTION_LIST: '/account-payment/payment-list',
  TRANSACTION_TAB: '/account-payment/moves',
};

export const ROUTES_ADMIN = {
  ROOT_ROUTE: '/',
  LOGIN_ROUTE: '/login',
  NOT_FOUND_ROUTE: '/404',
  ACCOUNT_PRODUCTS_PAYMENTS: '/account-product',
  ACCOUNT_PRODUCTS_SUB_ROUTE,
  SUSCRIPTIONS: '/subscription',
  SUBSCRIPTION_SUB_ROUTE,
  ACCESS_SECURITY: '/access-security',
  ACCESS_SECURITY_SUB_ROUTE,
  ENTERPRISES_CONNECT: '/enterprises-connect',
  ENTERPRISES_CONNECT_SUB_ROUTE,
  POS_CONNECT: '/pos-connect',
  PAYMENTS_METHOD: '/payment-method',
  ACCOUNT_PAYMENTS: '/account-payment',
  ACCOUNT_PAYMENTS_SUB_ROUTE,
  GUIDES: '/guide',
  OPEN_TICKET_SUPPORT: '/open-ticket',
  VISIT_BLOG: '/visit-blog',
  PROGRAM_FINANCE: '/program-finance',
  SUSPENDED_ACCESS: '/suspended-access',
};
