import {
  ParamsCustomer,
  PaymentMethodByCustomer,
  PaymentMethodsParams,
  TransactionsParams,
} from '@models/entities/transactions';
import { ListSubscription } from '@models/responses/response-subscription';
import {
  Customers,
  PaymentMethodCustomer,
  PaymentMethodsList,
  TransactionList,
} from '@models/responses/response-transaction';
import { createAction, props } from '@ngrx/store';

export const CLEAR_PAYMENT_CUSTOMERS =
  '[transaction state] set clear payment method customers';

export const SET_SUBSCRIPTION_SELECTED =
  '[transaction state] set subscription selected';

export const GET_CUSTOMERS = '[transaction state] get customers';
export const GET_CUSTOMERS_SUCCESS =
  '[transaction state] get customers success';
export const GET_CUSTOMERS_FAILED = '[transaction state] get customers failed';

export const GET_PAYMENT_METHODS = '[transaction state] get payment methods';
export const GET_PAYMENT_METHODS_SUCCESS =
  '[transaction state] get payment methods success';
export const GET_PAYMENT_METHODS_FAILED =
  '[transaction state] get payment methods failed';

export const GET_PAYMENT_METHOD_BY_CUSTOMER =
  '[transaction state] get payment method by customer';
export const GET_PAYMENT_METHOD_BY_CUSTOMER_SUCCESS =
  '[transaction state] get payment method by customer success';
export const GET_PAYMENT_METHOD_BY_CUSTOMER_FAILED =
  '[transaction state] get payment method by customer failed';

export const GET_TRANSACTIONS = '[transaction state] get transactions';
export const GET_TRANSACTIONS_SUCCESS =
  '[transaction state] get transactions success';
export const GET_TRANSACTIONS_FAILED =
  '[transaction state] get transactions failed';

export const CLEAR_DATA = '[transactions page] clear data';

export const clearDataTransactions = createAction(CLEAR_DATA);

export const clearPaymentMethodCustomers = createAction(
  CLEAR_PAYMENT_CUSTOMERS
);

export const setSubscriptionSelected = createAction(
  SET_SUBSCRIPTION_SELECTED,
  props<{ subscription: ListSubscription }>()
);

export const getCustomers = createAction(
  GET_CUSTOMERS,
  props<{ params: ParamsCustomer }>()
);

export const getCustomersSuccess = createAction(
  GET_CUSTOMERS_SUCCESS,
  props<{ listCustomers: Customers }>()
);

export const getCustomersFailed = createAction(
  GET_CUSTOMERS_FAILED,
  props<{ listCustomers: Customers }>()
);

export const getPaymentMethods = createAction(
  GET_PAYMENT_METHODS,
  props<{ params: PaymentMethodsParams }>()
);

export const getPaymentMethodsSuccess = createAction(
  GET_PAYMENT_METHODS_SUCCESS,
  props<{ listPaymentMethods: PaymentMethodsList }>()
);

export const getPaymentMethodsFailed = createAction(
  GET_PAYMENT_METHODS_FAILED,
  props<{ listPaymentMethods: PaymentMethodsList }>()
);

export const getPaymentMethodByCustomer = createAction(
  GET_PAYMENT_METHOD_BY_CUSTOMER,
  props<{ params: PaymentMethodByCustomer }>()
);

export const getPaymentMethodByCustomerSuccess = createAction(
  GET_PAYMENT_METHOD_BY_CUSTOMER_SUCCESS,
  props<{ listPaymentMethod: PaymentMethodCustomer }>()
);

export const getPaymentMethodByCustomerFailed = createAction(
  GET_PAYMENT_METHOD_BY_CUSTOMER_FAILED,
  props<{ listPaymentMethod: PaymentMethodCustomer }>()
);

export const getTransactions = createAction(
  GET_TRANSACTIONS,
  props<{ params: TransactionsParams }>()
);

export const getTransactionsSuccess = createAction(
  GET_TRANSACTIONS_SUCCESS,
  props<{ listTransaction: TransactionList }>()
);

export const getTransactionsFailed = createAction(
  GET_TRANSACTIONS_FAILED,
  props<{ listTransaction: TransactionList }>()
);
