import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { LangState } from '@models/state/lang.state';
import {
  emailRegex,
  lowerCaseRegex,
  numberFormRegex,
  upperCaseRegex,
} from '@data/regex.data';
import { InputValidators } from '@providers/validators/inputvalidators.service';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';

@Component({
  selector: 'app-login-c',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() lang: LangState;
  @Output() recoverPassword = new EventEmitter<void>();
  @Output() sendFormInfo = new EventEmitter<object>();
  @Output() emitOpenLangModal = new EventEmitter<void>();

  eyePass: boolean;
  loginForm: FormGroup;
  email: FormControl;
  password: FormControl;

  constructor(private methodsGService: MethodsGeneralService) {}

  ngOnInit(): void {
    this.eyePass = true;
    this.startForm();
  }

  startForm() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern(emailRegex()),
    ]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(64),
      InputValidators.patternValidator(numberFormRegex(), {
        hasNumber: true,
      }),
      InputValidators.patternValidator(upperCaseRegex(), {
        hasCapitalCase: true,
      }),
      InputValidators.patternValidator(lowerCaseRegex(), {
        hasSmallCase: true,
      }),
    ]);
    this.loginForm = new FormGroup({
      email: this.email,
      password: this.password,
    });
  }

  onLoginSubmit() {
    const loginInfo = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.sendFormInfo.emit(loginInfo);
  }

  forgetPass() {
    this.recoverPassword.emit();
  }

  openLangModal() {
    this.emitOpenLangModal.emit();
  }

  goTo() {
    this.methodsGService.gotToNewTab(environment.serverUrlPasserPublicTransp);
  }
}
