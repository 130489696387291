// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleted-account {
  width: 100%;
}
@media screen and (min-width: 640px) {
  .deleted-account {
    width: 460px;
  }
}
@media screen and (min-width: 768px) {
  .deleted-account {
    width: 600px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/deleted-account/deleted-account.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EAFF;IAGI,YAAA;EAGF;AACF;AADE;EANF;IAOI,YAAA;EAIF;AACF","sourcesContent":[".deleted-account {\n  width: 100%;\n  @media screen and (min-width: 640px) {\n    width: 460px;\n  }\n\n  @media screen and (min-width: 768px) {\n    width: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
