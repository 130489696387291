import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'base-select',
  templateUrl: './base-select.component.html',
  styleUrls: ['./base-select.component.scss'],
})
export class BaseSelectComponent {
  @Input() color = 'primary';
  @Input() height = 'medium';
  @Input() rounded = '';
  @Input() selectClass = '';
  @Input() labelClass = '';

  @Input() contentClass = '';
  @Input() disabled = false;
  @Input() floating = true;
  @Input() name = '';
  @Input() label = '';
  @Input() list = [];
  @Input() selectControl = new FormControl();
  @Input() selectOption = '';

  @Output() selectChange = new EventEmitter<void>();

  changeSelectValue() {
    this.selectChange.emit();
  }

  controlComparer(source, target) {
    return source[this.selectOption] === target[this.selectOption];
  }

  isDisabled(): string {
    return this.disabled || this.selectControl.disabled ? 'disabled' : '';
  }
}
