import { RequestResponse } from '@enums/account-products.enum';
import { CredentialsState } from '@models/state/credentials.state';
import { createReducer, on } from '@ngrx/store';
import {
  clearDataCredentials,
  credentialEndpointActions,
  credentialEndpointActionsResponse,
  ecommerceCredentialsResponse,
  getEcommerceCredentials,
  requestEcommerce,
  requestEcommerceResponse,
  setEcommerceCredentialsLoaded,
} from '@deprecated/state/actions/credentials.actions';

export const initialState: CredentialsState = {
  statusRequestCredential: '',
  ecommerceCredentials: [],
  ecommerceCredentialsLoaded: false,
  mehtodCalled: '',
  statusEndpoint: '',
};

export const credentialsReducer = createReducer(
  initialState,
  on(clearDataCredentials, () => {
    return { ...initialState };
  }),
  on(requestEcommerce, state => {
    return {
      ...state,
      statusRequestCredential: RequestResponse.LOADING,
    };
  }),
  on(requestEcommerceResponse, (state, action) => {
    return {
      ...state,
      statusRequestCredential: action.statusEcommerce,
    };
  }),
  on(getEcommerceCredentials, state => {
    return {
      ...state,
      ecommerceCredentialsLoaded: false,
    };
  }),
  on(ecommerceCredentialsResponse, (state, action) => {
    return {
      ...state,
      ecommerceCredentials: action.ecommerceList,
      ecommerceCredentialsLoaded: true,
    };
  }),
  on(setEcommerceCredentialsLoaded, (state, action) => {
    return {
      ...state,
      ecommerceCredentialsLoaded: action.statusLoaded,
    };
  }),
  on(credentialEndpointActions, (state, action) => {
    return {
      ...state,
      statusEndpoint: RequestResponse.LOADING,
      mehtodCalled: action.methodName,
    };
  }),
  on(credentialEndpointActionsResponse, (state, action) => {
    return {
      ...state,
      statusEndpoint: action.statusResponse,
      mehtodCalled: action.methodName,
    };
  })
);
