// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-content .marg-title {
  margin-left: 0px !important;
}
.header-content .copny-select {
  max-width: 360px;
  min-width: 360px;
  box-shadow: 0px 3px 6px var(--psr-input-border-70);
}
.header-content .copny-select .button-menu-copny__text {
  width: calc(100% - 60px);
}
@media screen and (max-width: 800px) {
  .header-content .copny-select {
    min-width: auto !important;
    box-shadow: none !important;
  }
  .header-content .button-menu-copny__text,
  .header-content .arrow-down {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ;AAEE;EACE,gBAAA;EACA,gBAAA;EACA,kDAAA;AAAJ;AAEM;EACE,wBAAA;AAAR;AAKE;EACE;IACE,0BAAA;IACA,2BAAA;EAHJ;EAKE;;IAEE,wBAAA;EAHJ;AACF","sourcesContent":[".header-content {\n  .marg-title {\n    margin-left: 0px !important;\n  }\n  .copny-select {\n    max-width: 360px;\n    min-width: 360px;\n    box-shadow: 0px 3px 6px var(--psr-input-border-70);\n    .button-menu-copny {\n      &__text {\n        width: calc(100% - 60px);\n      }\n    }\n  }\n\n  @media screen and (max-width: 800px) {\n    .copny-select {\n      min-width: auto !important;\n      box-shadow: none !important;\n    }\n    .button-menu-copny__text,\n    .arrow-down {\n      display: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
