import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuButton } from '@models/entities/general';

@Component({
  selector: 'app-custom-menu',
  templateUrl: './custom-menu.html',
})
export class CustomMenuComponent {
  @Input() classDiv: string;
  @Input() menuColor = 'transparent';
  @Input() menuIconTitle: string;
  @Input() menuIconColor = 'primary';
  @Input() menuTitle!: string;
  @Input() menuList: Array<MenuButton>;

  @Output() selected = new EventEmitter<number>();

  clickOption(index: number): void {
    this.selected.emit(index);
  }
}
