import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-box',
  templateUrl: 'box.html',
})
export class BoxComponent {
  @Input() classDiv: string;
  @Input() title: string;
  @Input() titleClass: string;
  @Input() desc: string;
  @Input() descClass: string;
}
