import { EcommerceCredential, ParamsEcommerce } from '@models/entities/account';
import { createAction, props } from '@ngrx/store';

export const REQUEST_ECOMMERCE = '[credential page] request ecommerce';
export const REQUEST_ECOMMERCE_RESPONSE =
  '[credential page] request ecommerce response';

export const GET_ECOMMERCE_CREDENTIALS =
  '[credential page] get ecommerce credentials';
export const GET_ECOMMERCE_CREDENTIALS_RESPONSE =
  '[credential page] get ecommerce credentials response';
export const SET_ECOMMERCE_CREDENTIALS_LOADED =
  '[credential page] set ecommerce credentials loaded';

export const CREDENTIAL_ENDPOINT_ACTIONS = '[credential page] endpoint actions';
export const CREDENTIAL_ENDPOINT_ACTIONS_RESPONSE =
  '[credential page] endpoint actions';

export const CLEAR_DATA = '[credentials page] clear data';

export const clearDataCredentials = createAction(CLEAR_DATA);

export const requestEcommerce = createAction(
  REQUEST_ECOMMERCE,
  props<{ params: ParamsEcommerce }>()
);

export const requestEcommerceResponse = createAction(
  REQUEST_ECOMMERCE_RESPONSE,
  props<{ statusEcommerce: string }>()
);

export const getEcommerceCredentials = createAction(GET_ECOMMERCE_CREDENTIALS);

export const ecommerceCredentialsResponse = createAction(
  GET_ECOMMERCE_CREDENTIALS_RESPONSE,
  props<{ ecommerceList: EcommerceCredential[] }>()
);

export const setEcommerceCredentialsLoaded = createAction(
  SET_ECOMMERCE_CREDENTIALS_LOADED,
  props<{ statusLoaded: boolean }>()
);

export const credentialEndpointActions = createAction(
  CREDENTIAL_ENDPOINT_ACTIONS,
  props<{
    id: string | number;
    methodName:
      | 'updateCredentialEcommerce'
      | 'deleteCredentialEcommerce'
      | 'saveConnection'
      | 'disconnectCredential';
    params?: any;
    update?: boolean;
  }>()
);

export const credentialEndpointActionsResponse = createAction(
  CREDENTIAL_ENDPOINT_ACTIONS_RESPONSE,
  props<{ statusResponse: string; methodName: string }>()
);
