// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-absolute {
  width: 20px;
  height: 20px;
  font-size: 14px;
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 50%;
  background-color: var(--psr-input-border);
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/box-text/box-text.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,yCAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".tooltip-absolute {\n  width: 20px;\n  height: 20px;\n  font-size: 14px;\n  position: absolute;\n  top: 24px;\n  right: 24px;\n  border-radius: 50%;\n  background-color: var(--psr-input-border);\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
