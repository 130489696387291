import {
  IdPAccessTokenRequest,
  IdPAccessTokenResponse,
  IdPRefreshTokenRequest,
} from '@models/responses/response-login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ResponseGeneral } from '@models/responses/response-general';
import {
  ManagementGroup,
  RoleAssignment,
  User,
} from '@models/state/user.model';
import * as PasserIdP from '@models/state/passerIdP.state';
import { IdPParamKeys } from '@enums/idpparamkeys.enum';
import { ParamsSendOTP, ParamsUserData } from '@models/entities/account';

@Injectable({ providedIn: 'root' })
export class AuthService {
  url: string;
  passerIdP: {
    options: PasserIdP.Options;
  };

  private readonly USER_INFO = '/me';
  private readonly TOKEN = '/token';
  private readonly USER_MANAGEMENT_GROUPS_INFO =
    '/api/providers/Passer.Management/accounts/{{userSub}}/managementGroups';
  private readonly USER_ROLEASSIGNMENT =
    "/api/providers/Passer.Management/managementGroups/{{managementGroupId}}/providers/Passer.Authorization/roleAssignments?$filter=assignedTo('{{userSub}}')";
  private readonly RECOVER_PASSWORD =
    '/api/providers/Passer.Authorization/recovery/password';
  private readonly CHANGE_PASSWORD =
    '/api/providers/Passer.Authorization/accounts/{{userSub}}/password/change';
  private readonly LOGOUT =
    '/api/providers/Passer.Authorization/accounts/{{userSub}}/logout';
  private readonly SEND_OTP = '/api/userdata/sendOtp';
  private readonly UPDATE_USER_DATA = '/api/userdata';
  private readonly DELETE_ACCESS_GROUP = '/api/systemroles';

  constructor(private http: HttpClient) {
    this.url = environment.SERVER_URL;
    this.passerIdP = {
      options: environment.passerIdP.options,
    };
  }

  login(email: string, password: string): Observable<IdPAccessTokenResponse> {
    const requestHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const accessTokenRequest: IdPAccessTokenRequest = {
      grant_type: 'password',
      client_id: this.passerIdP.options.clientId,
      client_secret: this.passerIdP.options.clientSecret,
      username: email,
      password: password,
      scope: this.passerIdP.options.scope,
    };

    return this.http.post<IdPAccessTokenResponse>(
      this.passerIdP.options.serverUrl + this.TOKEN,
      this.toQueryString(accessTokenRequest),
      { headers: requestHeaders }
    );
  }

  refresh(
    refresh_token: string,
    managementGroup: string
  ): Observable<IdPAccessTokenResponse> {
    const basicAuthBase64 = btoa(
      `${this.passerIdP.options.clientId}:${this.passerIdP.options.clientSecret}`
    );
    const requestHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${basicAuthBase64}`,
    });

    const accessTokenRequest: IdPRefreshTokenRequest = {
      grant_type: 'refresh_token',
      refresh_token,
      scope: this.passerIdP.options.scope,
      managementGroup,
    };

    return this.http.post<IdPAccessTokenResponse>(
      this.passerIdP.options.serverUrl + this.TOKEN,
      this.toQueryString(accessTokenRequest),
      { headers: requestHeaders }
    );
  }

  userInfo(): Observable<User> {
    return this.http.get<User>(
      this.passerIdP.options.serverUrl + this.USER_INFO
    );
  }

  userManagementGroupsInfo(userSub: string) {
    return this.http.get<ManagementGroup[]>(
      this.passerIdP.options.serverUrl +
        this.USER_MANAGEMENT_GROUPS_INFO.replace(IdPParamKeys.UserSub, userSub)
    );
  }

  userRoleAssignment(managementGroupId: string, userSub: string) {
    return this.http.get<RoleAssignment[]>(
      this.passerIdP.options.serverUrl +
        this.USER_ROLEASSIGNMENT.replace(
          IdPParamKeys.ManagementGroupId,
          managementGroupId
        ).replace(IdPParamKeys.UserSub, userSub)
    );
  }

  recoverPassword(email: string) {
    return this.http.post<ResponseGeneral>(
      this.passerIdP.options.serverUrl + this.RECOVER_PASSWORD,
      {
        email,
        callbackBaseUrl: this.passerIdP.options.callbackRecoveryPasswordBaseUrl,
      }
    );
  }

  resetPassword(password: string, confirmPassword: string) {
    return this.http.post<ResponseGeneral>(
      this.passerIdP.options.serverUrl + this.CHANGE_PASSWORD,
      {
        password,
        confirmPassword,
      }
    );
  }

  logout(userSub: string, params: { managementGroup: string }) {
    return this.http.post<ResponseGeneral>(
      this.passerIdP.options.serverUrl +
        this.LOGOUT.replace(IdPParamKeys.UserSub, userSub),
      params
    );
  }

  sendOTP(params: ParamsSendOTP): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(this.url + this.SEND_OTP, params);
  }

  deleteAccessGroup(
    activeManagementGroupId: string
  ): Observable<ResponseGeneral> {
    return this.http.delete<ResponseGeneral>(
      `${this.url}${this.DELETE_ACCESS_GROUP}/${activeManagementGroupId}`
    );
  }

  updateUserData(params: ParamsUserData): Observable<ResponseGeneral> {
    return this.http.put<ResponseGeneral>(
      this.url + this.UPDATE_USER_DATA,
      params
    );
  }

  toQueryString(query): string {
    const parts = [];
    for (const property in query) {
      const value = query[property];
      if (value != null && value != undefined)
        parts.push(
          encodeURIComponent(property) + '=' + encodeURIComponent(value)
        );
    }

    return parts.join('&');
  }
}
