import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@providers/translation-new/translation.service';

@Pipe({ name: 'translate', pure: false })
export class TranslatePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(value: any, args?: Record<string, string>) {
    return this.translationService.translate(value, args);
  }
}
