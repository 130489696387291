import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CreateCard, PayBillingRequest } from '@models/entities/payments';
import { ResponseGeneral } from '@models/responses/response-general';
import {
  PaymentMethod,
  ResponseBillings,
  ResponsePaymentMethods,
} from '@models/responses/response-payment';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  url: string;

  private readonly GET_BILLINGS = '/api/billing';
  private readonly PAYMENT_METHODS = '/api/paymentmethods';

  constructor(
    private http: HttpClient,
    private generalMethods: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  async getPaymentMethods(): Promise<ResponsePaymentMethods> {
    const payments$ = this.http.get<ResponsePaymentMethods>(
      this.url + this.PAYMENT_METHODS
    );

    return await lastValueFrom(payments$);
  }

  async getBillings(): Promise<ResponseBillings> {
    const billings$ = this.http.get<ResponseBillings>(
      this.url + this.GET_BILLINGS
    );
    return await lastValueFrom(billings$);
  }

  async payBilling(params: PayBillingRequest): Promise<ResponseGeneral> {
    const pay$ = this.http.put<ResponseGeneral>(this.url + this.GET_BILLINGS, {
      ...params,
    });

    return await lastValueFrom(pay$);
  }

  async createPaymentMethod(
    params: CreateCard
  ): Promise<ResponsePaymentMethods> {
    const create$ = this.http.post<ResponsePaymentMethods>(
      this.url + this.PAYMENT_METHODS,
      { ...params }
    );

    return await lastValueFrom(create$);
  }

  async updatePaymentMethod(
    paymentMethodId: number
  ): Promise<ResponsePaymentMethods> {
    const update$ = this.http.put<ResponsePaymentMethods>(
      this.url + this.PAYMENT_METHODS,
      { paymentMethodId }
    );

    return await lastValueFrom(update$);
  }

  async deletePaymentMethod(
    paymentMethodId: number
  ): Promise<ResponsePaymentMethods> {
    const delete$ = this.http.delete<ResponsePaymentMethods>(
      this.url +
        this.PAYMENT_METHODS +
        '?' +
        this.generalMethods.toQueryString({ paymentMethodId })
    );

    return await lastValueFrom(delete$);
  }

  setPaymentMethodArray(array: PaymentMethod[]) {
    return [...array].map(card => {
      return {
        ...card,
        icon: this.generalMethods.setImage(card.brand),
      };
    });
  }
}
