import { createReducer, on } from '@ngrx/store';
import { AuthState } from '@models/state/auth.state';
import {
  loginSuccess,
  autoLogout,
  changePosition,
  recoverRedirect,
  recoverMessage,
  autoLoginSuccess,
  resetPasswordRedirect,
  recoverPassword,
  resetPassword,
  setAccessLevel,
  logoutSuccess,
  setUser,
  userManagementGroupRedirect,
  updateUserAccount,
  updateUserAccountSuccess,
  twoFactorData,
  setManagementGroups,
  clearTwoFactorData,
  suspendedAccessLogOut,
} from '@deprecated/state/actions/auth.actions';
import { RequestResponse } from '@enums/account-products.enum';

export const initialState: AuthState = {
  user: null,
  accessLevel: '',
  accessLevelName: '',
  activeManagementGroup: '',
  managementGroups: [],
  pagePosition: {
    previous: 0,
    current: 0,
  },
  recoverSuccess: false,
  resetSuccess: false,
  recoverMessage: '',
  updateAccountStatus: RequestResponse.EMPTY,
  twoFactorData: null,
};

export const authReducer = createReducer(
  initialState,
  on(twoFactorData, (state, action) => {
    return {
      ...state,
      twoFactorData: {
        user: action.user,
        activeManagementGroupId: action.activeManagementGroupId,
        roleAssignment: action.roleAssignment,
      },
    };
  }),
  on(clearTwoFactorData, state => {
    return {
      ...state,
      twoFactorData: null,
    };
  }),
  on(loginSuccess, (state, action) => {
    return {
      ...state,
      user: { ...action.user },
    };
  }),
  on(autoLoginSuccess, (state, action) => {
    return {
      ...state,
      user: { ...action.user },
    };
  }),
  on(recoverPassword, state => {
    return {
      ...state,
      recoverSuccess: false,
    };
  }),
  on(recoverRedirect, state => {
    return {
      ...state,
      recoverSuccess: true,
    };
  }),
  on(recoverMessage, (state, action) => {
    return {
      ...state,
      recoverSuccess: action.success,
      recoverMessage: action.message,
    };
  }),
  on(resetPassword, state => {
    return {
      ...state,
      resetSuccess: false,
    };
  }),
  on(resetPasswordRedirect, state => {
    return {
      ...state,
      resetSuccess: true,
      recoverSuccess: false,
      recoverMessage: '',
    };
  }),
  on(autoLogout, state => {
    return {
      ...state,
      user: null,
    };
  }),
  on(changePosition, (state, action) => {
    return {
      ...state,
      pagePosition: action.pagePosition,
    };
  }),
  on(setAccessLevel, (state, action) => {
    return {
      ...state,
      accessLevel: action.level,
      accessLevelName: action.levelName,
      activeManagementGroup: action.activeManagementGroup,
      managementGroups: action.managementGroups,
    };
  }),
  on(setUser, (state, action) => {
    return {
      ...state,
      user: action.user,
    };
  }),
  on(logoutSuccess, suspendedAccessLogOut, () => {
    return { ...initialState };
  }),
  on(userManagementGroupRedirect, (state, action) => {
    return {
      ...state,
      managementGroups: action.managementGroups,
      user: action.user,
    };
  }),
  on(setManagementGroups, (state, action) => {
    return {
      ...state,
      managementGroups: action.managementGroups,
    };
  }),
  on(updateUserAccount, state => {
    return {
      ...state,
      updateAccountStatus: RequestResponse.LOADING,
    };
  }),
  on(updateUserAccountSuccess, (state, action) => {
    return {
      ...state,
      updateAccountStatus: action.status,
      user: action.user,
    };
  })
);
