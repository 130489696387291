import { createAction, props } from '@ngrx/store';
import { Billing, PaymentMethod } from '@models/responses/response-payment';
import { CreateCard, PayBillingRequest } from '@models/entities/payments';

export const GET_BILLING = '[payment method page] get billings';
export const GET_BILLING_SUCCESS = '[payment method page] get billings Success';
export const GET_BILLING_FAIL = '[payment method page] get billings Fail';
export const PAY_BILLING = '[payment method page] pay billing';
export const PAY_BILLING_SUCCESS = '[payment method page] pay billing success';

export const GET_PAYMENT_METHODS = '[payment method page] get payment methods';
export const UPDATE_PAYMENT_METHOD =
  '[payment method page] update payment method';
export const DELETE_PAYMENT_METHOD =
  '[payment method page] delete payment method';

export const CREATE_PAYMENT_METHOD =
  '[payment method page] create payment method';
export const CREATE_PAYMENT_METHOD_SUCCESS =
  '[payment method page] create payment method success';
export const SET_PAYMENT_METHOD_SUCCESS =
  '[payment method page] set payment method success';

export const MAP_PAYMENT_METHODS = '[payment method page] map payment methods';
export const SET_PAYMENT_METHODS = '[payment method page] set payment methods';

export const CLEAR_DATA = '[payment method page] clear data';

export const clearDataPayments = createAction(CLEAR_DATA);

export const getBillings = createAction(GET_BILLING);

export const billingsSuccess = createAction(
  GET_BILLING_SUCCESS,
  props<{ billings: Array<Billing> }>()
);

export const billingsFail = createAction(
  GET_BILLING_FAIL,
  props<{ billings: Array<Billing> }>()
);

export const payBilling = createAction(PAY_BILLING, props<PayBillingRequest>());

export const setPayBillingSuccess = createAction(
  PAY_BILLING_SUCCESS,
  props<{ status: boolean }>()
);

export const getPaymentMethods = createAction(GET_PAYMENT_METHODS);

export const createPaymentMethod = createAction(
  CREATE_PAYMENT_METHOD,
  props<{ card: CreateCard }>()
);

export const createPaymentMethodSuccess = createAction(
  CREATE_PAYMENT_METHOD_SUCCESS,
  props<{ paymentMethods: Array<PaymentMethod> }>()
);

export const setPaymentMethodSuccess = createAction(
  SET_PAYMENT_METHOD_SUCCESS,
  props<{ status: boolean }>()
);

export const mapPaymentMethods = createAction(
  MAP_PAYMENT_METHODS,
  props<{ data: Array<PaymentMethod> }>()
);

export const setPaymentMethods = createAction(
  SET_PAYMENT_METHODS,
  props<{ paymentMethods: Array<PaymentMethod> }>()
);

export const updatePaymentMethod = createAction(
  UPDATE_PAYMENT_METHOD,
  props<{ paymentMethodId: number }>()
);

export const deletePaymentMethod = createAction(
  DELETE_PAYMENT_METHOD,
  props<{ paymentMethodId: number }>()
);
