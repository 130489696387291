export const AVAILABLELANGUAGES = [
  {
    code: 'en',
    name: 'home.english',
    icon: './assets/icon/flags/us.svg',
    selected: true,
    value: 0,
  },
  {
    code: 'es',
    name: 'home.spanish',
    icon: './assets/icon/flags/cr.svg',
    selected: false,
    value: 1,
  },
];

export const AVAILABLELANGUAGES_ES = [
  {
    code: 'en',
    name: 'home.english',
    icon: './assets/icon/flags/us.svg',
    selected: false,
    value: 0,
  },
  {
    code: 'es',
    name: 'home.spanish',
    icon: './assets/icon/flags/cr.svg',
    selected: true,
    value: 1,
  },
];

export const objLangs = {
  es: AVAILABLELANGUAGES_ES,
  en: AVAILABLELANGUAGES,
};

export const DEFAULTLANGUAGE = 'es';

export const SYSOPTIONS = {
  systemLanguage: DEFAULTLANGUAGE,
};
