/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: Storage) {}

  setData(key, data): Promise<boolean> {
    return new Promise(async resolve => {
      try {
        const response: any = await this.storage.set(key, data);
        resolve(response ? true : false);
      } catch (e) {
        resolve(false);
      }
    });
  }

  getData(key): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: any = await this.storage.get(key);
        resolve(response);
      } catch (e) {
        reject(undefined);
      }
    });
  }

  removeData(key): Promise<boolean> {
    return new Promise(async resolve => {
      try {
        const response: boolean = await this.storage.remove(key);
        resolve(response);
      } catch (e) {
        resolve(false);
      }
    });
  }

  clearAllData(): Promise<boolean> {
    return new Promise(async resolve => {
      try {
        await this.storage.clear();
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    });
  }
}
