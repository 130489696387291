import { Injectable } from '@angular/core';
import { enLocale } from '@assets/i18n/en';
import { esLocale } from '@assets/i18n/es';
import { DEFAULTLANGUAGE } from '@data/i18n.data';
import { StorageKeys } from '@enums/storage-keys.enum';
import { LangState } from '@models/state/lang.state';
import { StorageService } from '@providers/storage/storage.service';

export class TranslationSet {
  public language = '';
  public data: any;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public languages = ['es', 'en'];
  private langObject: any;
  private _currentlanguage = DEFAULTLANGUAGE;
  private _languageData: LangState;
  private dictionary: { [key: string]: TranslationSet } = {
    es: esLocale,
    en: enLocale,
  };

  constructor(private storage: StorageService) {
    this.langObject = this.flatten(this.dictionary[this._currentlanguage].data);
  }

  async setCurrentLang(language: LangState): Promise<void> {
    this._languageData = language;
    if (language?.code !== this._currentlanguage) {
      this._currentlanguage = language?.code || DEFAULTLANGUAGE;
      this.langObject = this.flatten(
        this.dictionary[this._currentlanguage].data
      );
    }
    const html = document.querySelector('html');
    html?.setAttribute('lang', this._currentlanguage);
    await this.storage.setData(StorageKeys.LANG, this._currentlanguage);
  }

  getLangMessages(): any {
    return this.dictionary[this._currentlanguage].data;
  }

  getLangMessagesByKeys(keys: string | string[]): any {
    const data = this.dictionary[this._currentlanguage].data;
    if (typeof keys === 'string' || keys instanceof String) {
      return data[keys as string];
    }
    const dictionary = {};
    for (const key of keys) {
      dictionary[key] = data[key];
    }
    return dictionary;
  }

  translate(
    value: string,
    keysValue?: Record<string, string>
  ): string | undefined {
    if (this.dictionary[this._currentlanguage] != null) {
      let result = this.langObject?.[value] ?? '';
      if (keysValue) {
        result = this.replacePlaceholders(result, keysValue);
      }
      return result;
    }
    return undefined;
  }

  replacePlaceholders(
    str: string,
    replacements: Record<string, string>
  ): string {
    return str.replace(/{(.*?)}/g, (match, key) => {
      return replacements[key] ?? match;
    });
  }

  getPaths = (obj): any =>
    Object(obj) === obj
      ? Object.entries(obj).flatMap(([k, v]) =>
          this.getPaths(v).map((p: any) => [k, ...p])
        )
      : [[]];

  path = (ps: any) => (obj: any) =>
    ps.reduce((o: any, p: any) => (o || {})[p], obj);

  flatten = (obj: any) =>
    Object.fromEntries(
      this.getPaths(obj).map((p: any) => [
        p
          .reduce(
            (a: any, k: any) =>
              /^\d+$/.test(k)
                ? [...a.slice(0, -1), a[a.length - 1] + (1 + +k)]
                : [...a, k],
            []
          )
          .join('.'),
        this.path(p)(obj),
      ])
    );

  public get currentlanguage(): string {
    return this._currentlanguage;
  }

  public set currentlanguage(language: string) {
    this._currentlanguage = language;
  }

  public get languageData(): LangState {
    return this._languageData;
  }

  public set languageData(value: LangState) {
    this._languageData = value;
  }
}
