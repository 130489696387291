export enum AccessType {
  accountAccess = 1,
  subscriptionAccess = 2,
}

export enum AccessLevel {
  'Propietario de grupo' = 'owner',
  'Administrador de grupo' = 'admin',
  'Desarrollador' = 'dev',
}

export enum AccessLevelType {
  OWNER = 'owner',
  ADMIN = 'admin',
  DEV = 'dev',
}

export enum HistoryFilter {
  CLEAR = 0,
  RANGE = 1,
  ACTION = 2,
}

export enum TypeUser {
  OWNER_ES = 'Propietario de grupo',
  GROUP_MEMBER_ES = 'Miembro de grupo',
  OWNER_EN = 'Owner',
  GROUP_MEMBER_EN = 'Group member',
}

export enum StatusDeleteChange {
  EMPTY = 0,
  LOADING = 1,
  SUCCESS = 2,
  FAILED = 3,
  CANCEL = 4,
}

export enum DashboardID {
  DEV = 22,
  ADMIN = 21,
  OWNER = 20,
  MECHANIC = 5,
  ANALYST = 4,
  ENROLLER = 3,
  SELLER = 2,
  ADMIN_SUBS = 1,
}
