import { TransactionState } from '@models/state/transaction.state';
import { createReducer, on } from '@ngrx/store';
import {
  clearDataTransactions,
  clearPaymentMethodCustomers,
  getCustomers,
  getCustomersFailed,
  getCustomersSuccess,
  getPaymentMethodByCustomer,
  getPaymentMethodByCustomerFailed,
  getPaymentMethodByCustomerSuccess,
  getPaymentMethods,
  getPaymentMethodsFailed,
  getPaymentMethodsSuccess,
  getTransactions,
  getTransactionsFailed,
  getTransactionsSuccess,
  setSubscriptionSelected,
} from '@deprecated/state/actions/transactions.actions';

export const initialState: TransactionState = {
  subscriptionSelected: null,
  customers: null,
  customersLoaded: false,
  paymentMethods: null,
  paymentMethodsLoaded: false,
  paymentMethodCustomers: null,
  paymentMethodCustomersLoaded: false,
  transactionList: null,
  transactionListLoaded: false,
};

export const transactionReducer = createReducer(
  initialState,
  on(clearDataTransactions, () => {
    return { ...initialState };
  }),
  on(clearPaymentMethodCustomers, state => {
    return {
      ...state,
      paymentMethodCustomers: null,
      paymentMethodCustomersLoaded: false,
    };
  }),
  on(setSubscriptionSelected, (state, action) => {
    return {
      ...state,
      subscriptionSelected: action.subscription,
    };
  }),
  on(getCustomers, state => {
    return {
      ...state,
      customersLoaded: false,
      paymentMethodsLoaded: false,
      transactionListLoaded: false,
      paymentMethodCustomersLoaded: false,
    };
  }),
  on(getCustomersSuccess, getCustomersFailed, (state, action) => {
    return {
      ...state,
      customers: action.listCustomers,
      customersLoaded: true,
      paymentMethodsLoaded: false,
      transactionListLoaded: false,
      paymentMethodCustomersLoaded: false,
    };
  }),
  on(getPaymentMethods, state => {
    return {
      ...state,
      paymentMethodsLoaded: false,
      customersLoaded: false,
      transactionListLoaded: false,
      paymentMethodCustomersLoaded: false,
    };
  }),
  on(getPaymentMethodsSuccess, getPaymentMethodsFailed, (state, action) => {
    return {
      ...state,
      paymentMethods: action.listPaymentMethods,
      paymentMethodsLoaded: true,
      customersLoaded: false,
      transactionListLoaded: false,
      paymentMethodCustomersLoaded: false,
    };
  }),
  on(getPaymentMethodByCustomer, state => {
    return {
      ...state,
      paymentMethodCustomersLoaded: false,
      customersLoaded: false,
      paymentMethodsLoaded: false,
      transactionListLoaded: false,
    };
  }),
  on(
    getPaymentMethodByCustomerSuccess,
    getPaymentMethodByCustomerFailed,
    (state, action) => {
      return {
        ...state,
        paymentMethodCustomers: action.listPaymentMethod,
        paymentMethodCustomersLoaded: true,
        customersLoaded: false,
        paymentMethodsLoaded: false,
        transactionListLoaded: false,
      };
    }
  ),
  on(getTransactions, state => {
    return {
      ...state,
      transactionListLoaded: false,
      customersLoaded: false,
      paymentMethodsLoaded: false,
      paymentMethodCustomersLoaded: false,
    };
  }),
  on(getTransactionsSuccess, getTransactionsFailed, (state, action) => {
    return {
      ...state,
      transactionList: action.listTransaction,
      transactionListLoaded: true,
      customersLoaded: false,
      paymentMethodsLoaded: false,
      paymentMethodCustomersLoaded: false,
    };
  })
);
