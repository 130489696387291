import { Injectable } from '@angular/core';
import { ReSend } from '@models/entities/user';
import {
  ResponseCustomers,
  ResponsePaymentMethodCustomer,
  ResponsePaymentMethods,
  ResponseTransactions,
} from '@models/responses/response-transaction';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslationService } from '@deprecated/providers/translation/translation.service';
import { TransactionService } from '@deprecated/services/transactions/transactions.service';
import { showSnackbar } from '@deprecated/state/actions/shared.actions';
import {
  getCustomers,
  getCustomersFailed,
  getCustomersSuccess,
  getPaymentMethodByCustomer,
  getPaymentMethodByCustomerFailed,
  getPaymentMethodByCustomerSuccess,
  getPaymentMethods,
  getPaymentMethodsFailed,
  getPaymentMethodsSuccess,
  getTransactions,
  getTransactionsFailed,
  getTransactionsSuccess,
} from '@deprecated/state/actions/transactions.actions';
import { AppState } from '@deprecated/state/app.state';
import { catchError, exhaustMap, map, of } from 'rxjs';

@Injectable()
export class TransactionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private transactionService: TransactionService,
    private translation: TranslationService
  ) {}

  getCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomers),
      exhaustMap(actions =>
        this.transactionService.getCustomers(actions.params).pipe(
          map((response: ResponseCustomers) => {
            const { success, data, message, description } = response;
            if (success) {
              return getCustomersSuccess({ listCustomers: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getCustomersFailed({ listCustomers: null });
            }
          }),
          catchError((error: ReSend) => {
            const { title, message } = error;
            this.store.dispatch(showSnackbar({ title, message }));
            return of(getCustomersFailed({ listCustomers: null }));
          })
        )
      )
    )
  );

  getPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentMethods),
      exhaustMap(actions =>
        this.transactionService.getPaymentMethods(actions.params).pipe(
          map((response: ResponsePaymentMethods) => {
            const { success, data, message, description } = response;
            if (success) {
              const {
                accountPayment: {
                  client: { status },
                },
              } = this.translation.languageMessage;
              for (const item of data.paymentMethods) {
                item.statusName = status[item.status] || '-';
              }
              return getPaymentMethodsSuccess({ listPaymentMethods: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getPaymentMethodsFailed({ listPaymentMethods: null });
            }
          }),
          catchError((error: ReSend) => {
            const { title, message } = error;
            this.store.dispatch(showSnackbar({ title, message }));
            return of(getPaymentMethodsFailed({ listPaymentMethods: null }));
          })
        )
      )
    )
  );

  getPaymentMethodByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentMethodByCustomer),
      exhaustMap(actions =>
        this.transactionService.getPaymentMethodByCustomer(actions.params).pipe(
          map((response: ResponsePaymentMethodCustomer) => {
            const { success, data, message, description } = response;
            if (success) {
              if (data?.paymentMethods?.length === 0) {
                const {
                  accountPayment: { client },
                } = this.translation.languageMessage;
                this.store.dispatch(
                  showSnackbar({
                    title: client.noPaymentMethodTitle,
                    message: client.noPaymentMethodDescription,
                  })
                );
              } else {
                const {
                  accountPayment: {
                    client: { status },
                  },
                } = this.translation.languageMessage;
                for (const item of data.paymentMethods) {
                  item.statusName = status[item.status];
                  item.idCustomer = actions.params.customer;
                  item.company = actions.params.company;
                }
              }
              return getPaymentMethodByCustomerSuccess({
                listPaymentMethod: data,
              });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getPaymentMethodByCustomerFailed({
                listPaymentMethod: null,
              });
            }
          }),
          catchError((error: ReSend) => {
            const { title, message } = error;
            this.store.dispatch(showSnackbar({ title, message }));
            return of(
              getPaymentMethodByCustomerFailed({ listPaymentMethod: null })
            );
          })
        )
      )
    )
  );

  getTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTransactions),
      exhaustMap(actions =>
        this.transactionService.getTransactions(actions.params).pipe(
          map((response: ResponseTransactions) => {
            const { success, data, message, description } = response;
            if (success) {
              return getTransactionsSuccess({ listTransaction: data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return getTransactionsFailed({ listTransaction: null });
            }
          }),
          catchError((error: ReSend) => {
            const { title, message } = error;
            this.store.dispatch(showSnackbar({ title, message }));
            return of(getTransactionsFailed({ listTransaction: null }));
          })
        )
      )
    )
  );
}
