// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table__tbody:nth-of-type(even) {
  background-color: var(--psr-secondary-background);
}
.custom-table__tbody:hover {
  background-color: var(--psr-box-value);
}

.table-row-400 ion-row {
  min-width: 400px !important;
}

.table-row-600 ion-row {
  min-width: 600px !important;
}

.table-row-900 ion-row {
  min-width: 900px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-table/custom-table.scss"],"names":[],"mappings":"AAEI;EACE,iDAAA;AADN;AAGI;EACE,sCAAA;AADN;;AAOE;EACE,2BAAA;AAJJ;;AASE;EACE,2BAAA;AANJ;;AAWE;EACE,2BAAA;AARJ","sourcesContent":[".custom-table {\n  &__tbody {\n    &:nth-of-type(even) {\n      background-color: var(--psr-secondary-background);\n    }\n    &:hover {\n      background-color: var(--psr-box-value);\n    }\n  }\n}\n\n.table-row-400 {\n  ion-row {\n    min-width: 400px !important;\n  }\n}\n\n.table-row-600 {\n  ion-row {\n    min-width: 600px !important;\n  }\n}\n\n.table-row-900 {\n  ion-row {\n    min-width: 900px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
