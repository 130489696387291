import { createReducer, on } from '@ngrx/store';
import { CodeState } from '@models/state/code.state';
import {
  clearDataCode,
  sendCodeFailed,
  sendCodeSuccess,
  sendCodeTo,
  setActiveGroup,
  verifyCode,
  verifyCodeFailed,
  verifyCodeRecover,
  verifyCodeRecoverSuccess,
} from '@deprecated/state/actions/code.actions';
import { recoverPassword, recoverRedirect } from '@deprecated/state/actions/auth.actions';

export const initialState: CodeState = {
  sendCodeSuccess: false,
  verifySuccess: false,
  verifyCodeSuccess: false,
  activeManagementGroupId: '',
  roleAssignment: null,
};

export const codeReducer = createReducer(
  initialState,
  on(clearDataCode, () => {
    return { ...initialState };
  }),
  on(verifyCode, state => {
    return {
      ...state,
    };
  }),
  on(verifyCodeRecover, state => {
    return {
      ...state,
      verifySuccess: false,
    };
  }),
  on(verifyCodeRecoverSuccess, state => {
    return {
      ...state,
      verifySuccess: true,
    };
  }),
  on(verifyCodeFailed, state => {
    return {
      ...state,
      verifyCodeSuccess: false,
    };
  }),
  on(sendCodeTo, state => {
    return {
      ...state,
      sendCodeSuccess: false,
    };
  }),
  on(sendCodeSuccess, state => {
    return {
      ...state,
      sendCodeSuccess: true,
    };
  }),
  on(sendCodeFailed, state => {
    return {
      ...state,
      sendCodeSuccess: false,
    };
  }),
  on(recoverRedirect, state => {
    return {
      ...state,
      sendCodeSuccess: true,
    };
  }),
  on(recoverPassword, state => {
    return {
      ...state,
      sendCodeSuccess: false,
    };
  }),
  on(setActiveGroup, (state, action) => {
    return {
      ...state,
      activeManagementGroupId: action.activeManagementGroupId,
      roleAssignment: action.roleAssignment,
    };
  })
);
