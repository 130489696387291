/* eslint-disable no-async-promise-executor */
import { Injectable } from '@angular/core';
import { CryptoConfig } from '@enums/general-data.enum';
import { EncryptPayload } from '@models/entities/payments';
import { environment } from '@environments/environment';

declare let passerCrypto: any;

@Injectable({ providedIn: 'root' })
export class PasserCryptoService {
  async configCrypto(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          environment: passerCrypto.environment[environment.enviromentCrypto],
          service: CryptoConfig.SERVICE,
          client: CryptoConfig.CLIENT,
        };
        await passerCrypto.setConfig(config);
        resolve(true);
      } catch (error) {
        reject({
          message: error.message,
          description: error.description,
        });
      }
    });
  }

  async encrypData(data: EncryptPayload): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const encrypt = await passerCrypto.encrypt(data);
        resolve(encrypt);
      } catch (error) {
        reject({
          message: error.message,
          description: error.description,
        });
      }
    });
  }
}
