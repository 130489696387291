import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-box-text',
  templateUrl: './box-text.html',
  styleUrls: ['./box-text.scss'],
})
export class BoxTextComponent {
  @Input() classDiv: string;
  @Input() icon: string;
  @Input() iconClass: string;
  @Input() iconColor = 'primary';
  @Input() text: string;
  @Input() classText: string;

  @Input() toolText: string;
  @Input() classToolText: string;
  @Output() ClickAction = new EventEmitter<void>();

  clickAction(): void {
    this.ClickAction.emit();
  }
}
