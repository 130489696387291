import {
  NewSubscription,
  UpdateSubscription,
} from '@models/entities/subscription';
import { ListSubscription } from '@models/responses/response-subscription';
import { createAction, props } from '@ngrx/store';

export const GET_SUBSCRIPTION = '[subscription state] get subscriptions';
export const GET_SUBSCRIPTION_SUCCESS =
  '[subscription state] get subscription Success';
export const GET_SUBSCRIPTION_FAILED =
  '[subscription state] get subscription Failed';

export const CREATE_SUBSCRIPTION =
  '[subscription state] create new subscription';
export const CREATE_SUBSCRIPTION_SUCCESS =
  '[subscription state] create new subscription success';

export const UPDATE_SUBSCRIPTION =
  '[subscription state] create update subscription';
export const UPDATE_SUBSCRIPTION_SUCCESS =
  '[subscription state] create update subscription success';

export const CANCEL_SUBSCRIPTION =
  '[subscription state] create cancel subscription';
export const CANCEL_SUBSCRIPTION_SUCCESS =
  '[subscription state] create cancel subscription success';

export const CLEAR_DATA = '[subscription page] clear data';

export const clearDataSubs = createAction(CLEAR_DATA);

export const getSubscription = createAction(
  GET_SUBSCRIPTION,
  props<{ managementGroup: string }>()
);

export const getSubscriptionSuccess = createAction(
  GET_SUBSCRIPTION_SUCCESS,
  props<{ subscription: ListSubscription[] }>()
);

export const getSubscriptionFailure = createAction(
  GET_SUBSCRIPTION_FAILED,
  props<{ subscription: ListSubscription[] }>()
);

export const createSubscription = createAction(
  CREATE_SUBSCRIPTION,
  props<{ newSubscription: NewSubscription }>()
);

export const setCreateSubscriptionSuccess = createAction(
  CREATE_SUBSCRIPTION_SUCCESS,
  props<{ status: boolean }>()
);

export const updateSubscription = createAction(
  UPDATE_SUBSCRIPTION,
  props<{ updateData: UpdateSubscription }>()
);

export const setUpdateSubscriptionSuccess = createAction(
  UPDATE_SUBSCRIPTION_SUCCESS,
  props<{ status: boolean }>()
);

export const cancelSubscription = createAction(
  CANCEL_SUBSCRIPTION,
  props<{ company: number }>()
);

export const setCancelSubscriptionSuccess = createAction(
  CANCEL_SUBSCRIPTION_SUCCESS,
  props<{ status: boolean }>()
);
