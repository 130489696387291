import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '@environments/environment';
import { ResponseGeneral } from '@models/responses/response-general';

@Injectable({
  providedIn: 'root',
})
export class CatalogsConnections {
  private url = '';

  constructor(private http: HttpClient) {
    this.url = environment.serverUrlCatalogs;
  }

  async getCatalog(type: string): Promise<ResponseGeneral> {
    const catalog$ = this.http.get<ResponseGeneral>(this.url + type);

    return await lastValueFrom(catalog$);
  }
}
