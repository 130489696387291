import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { ModalData } from '@models/entities/general';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogRefOpened: MatDialogRef<unknown, any>;
  modalData: ModalData[] = [];
  modalDataArray: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private dialog: MatDialog
  ) {}

  isModalPresent(id: string): boolean {
    return this.modalDataArray.some(modal => modal.id === id);
  }

  async openModal(component, componentProps, cssClass = '', id = '') {
    if (!this.isModalPresent(id)) {
      this.modalDataArray.push(
        await this.modalCtrl.create({
          animated: true,
          cssClass,
          component,
          componentProps,
          backdropDismiss: false,
          id,
        })
      );

      await this.modalDataArray[this.modalDataArray.length - 1].present();
      const { data } =
        await this.modalDataArray[
          this.modalDataArray.length - 1
        ].onWillDismiss();
      const indexModal = this.modalDataArray.findIndex(
        modal => modal.id === id
      );
      this.modalDataArray.splice(indexModal, 1);
      return data;
    }
  }

  async dismissModal(data?, type = 'cancel', id = '') {
    const indexModal = this.modalDataArray.findIndex(modal => modal.id === id);
    if (indexModal !== -1) {
      await this.modalDataArray[indexModal].dismiss(data, type, id);
    } else if (this.modalDataArray?.length > 0) {
      await this.modalDataArray[this.modalDataArray.length - 1].dismiss(
        data,
        type,
        id
      );
    }
  }

  closeCurrentModals(): void {
    if (this.modalDataArray.length) {
      for (const modal of this.modalDataArray) {
        this.modalCtrl.dismiss(null, 'cancel', modal.id);
      }
    }
    this.modalDataArray = [];
  }

  // --------------- MODAL ANGULAR -------------------

  reviewModalRep(item, title: string, message: string): boolean {
    return (
      item.findIndex(
        data => data.title === title && data.message === message
      ) === -1
    );
  }

  async showAlert(data: ModalData) {
    data.dataa = true;
    if (this.reviewModalRep(this.modalData, data.title, data.message)) {
      this.modalData.push(data);
      if (!this.dialogRefOpened) {
        this.dialogRefOpened = this.dialog.open(NormalModalComponent, {
          width: '100%',
          data,
        });
        return await this.closeModal();
      }
    }
  }

  async closeModal() {
    const result = await this.dialogRefOpened.afterClosed().toPromise();
    this.dialogRefOpened = null;
    if (this.modalData.length > 0) this.modalData.splice(0, 1);
    if (this.modalData.length > 0) {
      this.dialogRefOpened = this.dialog.open(NormalModalComponent, {
        width: '100%',
        data: this.modalData.pop(),
      });
      return await this.closeModal();
    }
    return result;
  }

  showAlertComp(component, data): void {
    data.dataa = true;
    this.dialog.open(component, { width: '100%', data });
  }

  async showAlertCompReturn(component, data): Promise<any> {
    data.dataa = true;
    const dialogRef = this.dialog.open(component, { width: '100%', data });
    const result = await dialogRef.afterClosed().toPromise();
    return result;
  }

  showAlertCompReturnObs(component, data): Observable<any> {
    data.dataa = true;
    const dialogRef = this.dialog.open(component, { width: '100%', data });
    return dialogRef.afterClosed();
  }

  closeAllModal(): void {
    this.dialog.closeAll();
  }
}
