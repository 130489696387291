export enum EPaymentCards {
  VISA = 'visa',
  MASTERCARD = 'master',
  DISCOVER = 'discover',
  AMEX = 'amex',
  AMERICAN_EXPRESS = 'american_express',
  DINERS = 'diners',
  JCB = 'jcb',
  PASSER = 'passerCard',
  DEFAULT_PAYMENT = 'DEFAULT_PAYMENT',
  CARD_OFF = 'credit-card-off',
}

export enum EIcons {
  ACCOUNT_BALANCE = 'account-balance',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  CANCEL = 'cancel',
  CHECK_BOX = 'check-box',
  CIRCLE = 'circle',
  CLOSE = 'close',
  CODE = 'code',
  CONTENT_COPY = 'content-copy',
  DELETE = 'delete',
  DESCRIPTION = 'description',
  DONE = 'done',
  EDIT = 'edit',
  EYE = 'eye',
  EYE_OFF = 'eye-off',
  FILTER = 'filter',
  FILTER_OFF = 'filter-off',
  INSERT_CHART = 'insert-chart',
  LINK = 'link',
  LINK_OFF = 'link-off',
  LOGOUT = 'logout',
  MANAGE_ACCOUNTS = 'manage-accounts',
  MENU = 'menu',
  MORE_VERT = 'more-vert',
  PENDING_ACTIONS = 'pending-actions',
  PERSON_OFF = 'person-off',
  PERSON_REMOVE = 'person-remove',
  PLUS = 'plus',
  REVERSE = 'reverse',
  ROCKET_LAUNCH = 'rocket-launch',
  SECURITY = 'security',
  SLIDERS = 'sliders',
  SWITCH_ACCOUNT = 'switch-account',
  SYNC = 'sync',
  TOKEN = 'token',
  VPN_KEY = 'vpn-key',
}

export const icons = {
  [EIcons.ACCOUNT_BALANCE]: 'assets/icon/account-balance.svg',
  [EIcons.ARROW_DOWN]: 'assets/icon/arrow-down.svg',
  [EIcons.ARROW_LEFT]: 'assets/icon/arrow-left.svg',
  [EIcons.CANCEL]: 'assets/icon/cancel.svg',
  [EIcons.CHECK_BOX]: 'assets/icon/check-box.svg',
  [EIcons.CIRCLE]: 'assets/icon/circle.svg',
  [EIcons.CLOSE]: 'assets/icon/close.svg',
  [EIcons.CODE]: 'assets/icon/code.svg',
  [EIcons.CONTENT_COPY]: 'assets/icon/content-copy.svg',
  [EIcons.DELETE]: 'assets/icon/delete.svg',
  [EIcons.DESCRIPTION]: 'assets/icon/description.svg',
  [EIcons.DONE]: 'assets/icon/done.svg',
  [EIcons.EDIT]: 'assets/icon/edit.svg',
  [EIcons.EYE]: 'assets/icon/eye.svg',
  [EIcons.EYE_OFF]: 'assets/icon/eye-off.svg',
  [EIcons.FILTER]: 'assets/icon/filter.svg',
  [EIcons.FILTER_OFF]: 'assets/icon/filter-off.svg',
  [EIcons.INSERT_CHART]: 'assets/icon/insert-chart.svg',
  [EIcons.LINK]: 'assets/icon/link.svg',
  [EIcons.LINK_OFF]: 'assets/icon/link-off.svg',
  [EIcons.LOGOUT]: 'assets/icon/logout.svg',
  [EIcons.MANAGE_ACCOUNTS]: 'assets/icon/manage-accounts.svg',
  [EIcons.MENU]: 'assets/icon/menu.svg',
  [EIcons.MORE_VERT]: 'assets/icon/more-vert.svg',
  [EIcons.PENDING_ACTIONS]: 'assets/icon/pending-actions.svg',
  [EIcons.PERSON_OFF]: 'assets/icon/person-off.svg',
  [EIcons.PERSON_REMOVE]: 'assets/icon/person-remove.svg',
  [EIcons.PLUS]: 'assets/icon/plus.svg',
  [EIcons.REVERSE]: 'assets/icon/reverse.svg',
  [EIcons.ROCKET_LAUNCH]: 'assets/icon/rocket-launch.svg',
  [EIcons.SECURITY]: 'assets/icon/security.svg',
  [EIcons.SLIDERS]: 'assets/icon/sliders.svg',
  [EIcons.SWITCH_ACCOUNT]: 'assets/icon/switch-account.svg',
  [EIcons.SYNC]: 'assets/icon/sync.svg',
  [EIcons.TOKEN]: 'assets/icon/token.svg',
  [EIcons.VPN_KEY]: 'assets/icon/vpn-key.svg',
  [EPaymentCards.VISA]: 'assets/icon/cards/cc-visa.svg',
  [EPaymentCards.MASTERCARD]: 'assets/icon/cards/cc-mastercard.svg',
  [EPaymentCards.DISCOVER]: 'assets/icon/cards/cc-discover.svg',
  [EPaymentCards.AMEX]: 'assets/icon/cards/cc-amex.svg',
  [EPaymentCards.AMERICAN_EXPRESS]: 'assets/icon/cards/cc-amex.svg',
  [EPaymentCards.DINERS]: 'assets/icon/cards/cc-diners-club.svg',
  [EPaymentCards.JCB]: 'assets/icon/cards/cc-jcb.svg',
  [EPaymentCards.PASSER]: 'assets/icon/passerCard.svg',
  [EPaymentCards.DEFAULT_PAYMENT]: 'assets/icon/cards/credit-card.svg',
  [EPaymentCards.CARD_OFF]: 'assets/icon/cards/credit-card-off.svg',
};
