import { Component, Input } from '@angular/core';
import { ModalService } from '@providers/modal/modal.service';

@Component({
  selector: 'modal-image',
  templateUrl: './modal-image.html',
})
export class ModalImageComponent {
  @Input() title: string;
  @Input() classTitle: string;
  @Input() description: string;
  @Input() classDesc: string;
  @Input() img: string;
  @Input() btnCancel: string;
  @Input() btnSave: string;
  @Input() btnBlue: string;
  @Input() modalId = '';

  constructor(private modalCtrl: ModalService) {}

  closeModal(data = false) {
    this.modalCtrl.dismissModal(data, 'cancel', this.modalId);
  }
}
