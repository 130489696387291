export enum SubscriptionRenovation {
  YEARLY = 1,
  MONTHLY = 2,
}

export enum SubscriptionType {
  OPENING_ONLINE = 'OPENING ONLINE',
  OPENING = 'OPENING',
  BASE = 'BASE',
}

export enum SubscriptionPlanType {
  START = 1,
  MEDIUM = 2,
  PLUS = 3,
  PARTNER = 4,
  START_NAME = 'START',
  MEDIUM_NAME = 'MEDIUM',
  PLUS_NAME = 'PLUS',
  PARTNER_NAME = 'PARTNER',
}

export enum StatusSubscription {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  SUSPENDED = 'suspended',
  CANCELED = 'canceled',
}
