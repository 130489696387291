import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'base-button',
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss'],
})
export class BaseButtonComponent {

  @Input() buttonClass: string = '';
  @Input() color: string = 'primary';
  @Input() classContent: string ='';
  @Input() disabled: boolean = false;
  @Input() height = 'medium'; 
  @Input() icon: string = '';
  @Input() iconClass: string = '';
  @Input() iconColor: string = 'primary';
  @Input() loading: boolean = false;
  @Input() rounded: string = 'normal';
  @Input() text: string = '';
  @Input() type: string = 'button';
  @Output() emitClick = new EventEmitter();

  onClick(): void {
    if (!this.loading && !this.disabled) {
      this.emitClick.emit();
    }
  }

}
