import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-deleted-account',
  templateUrl: './deleted-account.component.html',
  styleUrls: ['./deleted-account.component.scss'],
})
export class DeletedAccountComponent {
  @Output() emitTo = new EventEmitter<void>();

  company = 'Company 1';

  goTo() {
    this.emitTo.emit();
  }
}
