import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { showSnackbar } from '@deprecated/state/actions/shared.actions';
import { AppState } from '@deprecated/state/app.state';
import { PaymentMethodService } from '@deprecated/services/payment-method/payment-method.service';
import {
  billingsFail,
  billingsSuccess,
  createPaymentMethod,
  createPaymentMethodSuccess,
  deletePaymentMethod,
  getBillings,
  getPaymentMethods,
  mapPaymentMethods,
  payBilling,
  setPayBillingSuccess,
  setPaymentMethods,
  updatePaymentMethod,
} from '@deprecated/state/actions/payment-method.actions';
import {
  ResponseBillings,
  ResponsePaymentMethods,
} from '@models/responses/response-payment';
import { ResponseGeneral } from '@models/responses/response-general';
import { TranslationService } from '@deprecated/providers/translation/translation.service';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';

@Injectable()
export class PaymentMethodEffects {
  constructor(
    private actions$: Actions,
    private loading: LoaderManagmentService,
    private paymentService: PaymentMethodService,
    private store: Store<AppState>,
    private translation: TranslationService
  ) {}

  getBillings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getBillings),
      exhaustMap(() => {
        return this.paymentService.getBillings().pipe(
          map((response: ResponseBillings) => {
            const { data, success, message, description } = response;
            if (success) {
              return billingsSuccess({ billings: [...data] });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return billingsFail({ billings: [] });
            }
          }),
          catchError(errResp => {
            this.store.dispatch(showSnackbar(errResp));
            return of(billingsFail({ billings: [] }));
          })
        );
      })
    );
  });

  payBilling$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(payBilling),
      exhaustMap(action => {
        const { billing, paymentMethod } = action;
        return this.paymentService.payBilling({ billing, paymentMethod }).pipe(
          map((response: ResponseGeneral) => {
            const { success, message, description } = response;
            if (success) {
              return setPayBillingSuccess({ status: true });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
            }
          }),
          catchError(errResp => {
            this.store.dispatch(showSnackbar(errResp));
            return of();
          })
        );
      })
    );
  });

  getPaymentMethods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPaymentMethods),
      exhaustMap(() => {
        return this.paymentService.getPaymentMethods().pipe(
          map((response: ResponsePaymentMethods) => {
            const { data, success, message, description } = response;
            if (success) {
              return mapPaymentMethods({ data });
            } else {
              this.store.dispatch(
                showSnackbar({ title: message, message: description })
              );
              return mapPaymentMethods({ data: [] });
            }
          }),
          catchError(errResp => {
            this.store.dispatch(showSnackbar(errResp));
            return of(mapPaymentMethods({ data: [] }));
          })
        );
      })
    );
  });

  createPaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createPaymentMethod),
      exhaustMap(action => {
        return this.paymentService.createPaymentMethod(action.card).pipe(
          map((response: ResponsePaymentMethods) => {
            const { data, success, message, description } = response;
            this.loading.dismissLoading();
            if (success) {
              const methods = this.paymentService.setPaymentMethodArray(data);
              const {
                actions: { successAction },
                payment_method: { successCreate },
              } = this.translation.languageMessage;
              this.store.dispatch(
                showSnackbar({ title: successAction, message: successCreate })
              );
              return createPaymentMethodSuccess({
                paymentMethods: [...methods],
              });
            } else {
              return showSnackbar({ title: message, message: description });
            }
          }),
          catchError(errResp => {
            this.loading.dismissLoading();
            return of(showSnackbar(errResp));
          })
        );
      })
    );
  });

  updatePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatePaymentMethod),
      exhaustMap(action => {
        return this.paymentService
          .updatePaymentMethod(action.paymentMethodId)
          .pipe(
            map((response: ResponsePaymentMethods) => {
              const { data, success, message, description } = response;
              this.loading.dismissLoading();
              if (success) {
                const {
                  actions: { successAction },
                  payment_method: { successUpdate },
                } = this.translation.languageMessage;
                this.store.dispatch(
                  showSnackbar({ title: successAction, message: successUpdate })
                );
                return mapPaymentMethods({ data });
              } else {
                return showSnackbar({ title: message, message: description });
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  deletePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deletePaymentMethod),
      exhaustMap(action => {
        const { paymentMethodId } = action;
        return this.paymentService
          .deletePaymentMethod({ paymentMethodId })
          .pipe(
            map((response: ResponsePaymentMethods) => {
              const { data, success, message, description } = response;
              this.loading.dismissLoading();
              if (success) {
                const {
                  actions: { successAction },
                  payment_method: { successDelete },
                } = this.translation.languageMessage;
                this.store.dispatch(
                  showSnackbar({ title: successAction, message: successDelete })
                );
                return mapPaymentMethods({ data });
              } else {
                return showSnackbar({ title: message, message: description });
              }
            }),
            catchError(errResp => {
              this.loading.dismissLoading();
              return of(showSnackbar(errResp));
            })
          );
      })
    );
  });

  mapPaymentMethods$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[mapPaymentMethods]),
        tap(action => {
          const methods = this.paymentService.setPaymentMethodArray(
            action.data
          );
          this.store.dispatch(
            setPaymentMethods({ paymentMethods: [...methods] })
          );
        })
      );
    },
    { dispatch: false }
  );
}
