import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RadioBaseList } from '@models/entities/general';

@Component({
  selector: 'base-radio',
  templateUrl: './base-radio.component.html',
  styleUrls: ['./base-radio.component.scss'],
})
export class BaseRadioComponent {
    
  @Input() color = 'primary';
  @Input() height = 'medium';
  @Input() list: RadioBaseList[] = [];
  @Input() name = '';
  @Input() text = '';
  @Input() radioClass = '';
  @Input() radioControl = new FormControl(false);
}