import {
  AccessSecurityParams,
  ActionData,
  DataChangeAccess,
  ParamsChangeAccess,
  ParamsUserLog,
  SystemRoleList,
  UserDataList,
  UserLog,
} from '@models/entities/access';
import { createAction, props } from '@ngrx/store';

export const GET_SYSTEM_ROLE_LIST =
  '[access security page] get system role list';
export const GET_SYSTEM_ROLE_LIST_SUCCESS =
  '[access security page] get system role list success';
export const GET_SYSTEM_ROLE_LIST_FAILED =
  '[access security page] get system role list failed';

export const GET_USER_DATA_LIST = '[access security page] get user data list';
export const GET_USER_DATA_LIST_SUCCESS =
  '[access security page] get user data list success';
export const GET_USER_DATA_LIST_FAILED =
  '[access security page] get user data list failed';
export const SET_USER_DATA_LIST_LOADED =
  '[access security page] set user data list loaded';

export const GET_USER_LOG = '[access security page] get user log';
export const GET_USER_LOG_SUCCESS =
  '[access security page] get user log success';
export const GET_USER_LOG_FAILED = '[access security page] get user log failed';
export const SET_USER_LOG = '[access security page] set user log';

export const GET_ACTIONS = '[access security page] get actions';
export const GET_ACTIONS_SUCCESS = '[access security page] get actions success';
export const GET_ACTIONS_FAILED = '[access security page] get actions failed';

export const CLOSE_USER_SESSION = '[access security page] close user sessions';
export const CLOSE_ALL_SESSIONS = '[access security page] close all sessions';
export const SHOW_MODAL_SUCCESS_ACTION =
  '[access security page] show modal success action';

export const DELETE_ACCESS = '[access security page] delete access';
export const SET_STATUS_DELETE_ACCESS =
  '[access security] set status delete access';

export const CHANGE_ACCESS = '[access security page] change access';
export const SET_STATUS_CHANGE_ACCESS =
  '[access security] set status change access';

export const CLEAR_DATA = '[access security page] clear data';

export const clearDataAccess = createAction(CLEAR_DATA);

export const getSystemRoleList = createAction(GET_SYSTEM_ROLE_LIST);

export const getSystemRoleListSuccess = createAction(
  GET_SYSTEM_ROLE_LIST_SUCCESS,
  props<{
    systemRoleDEAList: SystemRoleList[];
    systemRoleDESList: SystemRoleList[];
  }>()
);

export const getSystemRoleListFailed = createAction(
  GET_SYSTEM_ROLE_LIST_FAILED,
  props<{
    systemRoleDEAList: SystemRoleList[];
    systemRoleDESList: SystemRoleList[];
  }>()
);

export const getUserDataList = createAction(
  GET_USER_DATA_LIST,
  props<{ params: AccessSecurityParams }>()
);

export const getUserDataListSuccess = createAction(
  GET_USER_DATA_LIST_SUCCESS,
  props<{ userList: UserDataList }>()
);

export const getUserDataListFailed = createAction(
  GET_USER_DATA_LIST_FAILED,
  props<{ userList: UserDataList }>()
);

export const setUserDataListLoaded = createAction(
  SET_USER_DATA_LIST_LOADED,
  props<{ status: boolean }>()
);

export const getUserLogData = createAction(
  GET_USER_LOG,
  props<{ params: ParamsUserLog }>()
);

export const getUserLogDataSuccess = createAction(
  GET_USER_LOG_SUCCESS,
  props<{ userLogData: UserLog }>()
);

export const getUserLogDataFailed = createAction(
  GET_USER_LOG_FAILED,
  props<{ userLogData: UserLog }>()
);

export const setUserLogData = createAction(
  SET_USER_LOG,
  props<{ data: any; status: boolean }>()
);

export const getActionsData = createAction(GET_ACTIONS);

export const getActionsDataSuccess = createAction(
  GET_ACTIONS_SUCCESS,
  props<{ actionList: ActionData[] }>()
);

export const getActionsDataFailed = createAction(
  GET_ACTIONS_FAILED,
  props<{ actionList: ActionData[] }>()
);

export const closeUserSession = createAction(
  CLOSE_USER_SESSION,
  props<{ id: number; params: { hasMfa: boolean } }>()
);

export const closeAllSessions = createAction(
  CLOSE_ALL_SESSIONS,
  props<{ managementGroup: string; hasMfa: boolean }>()
);

export const showModalSuccess = createAction(SHOW_MODAL_SUCCESS_ACTION);

export const deleteAccess = createAction(
  DELETE_ACCESS,
  props<{ id: number }>()
);

export const setStatusDeleteAccess = createAction(
  SET_STATUS_DELETE_ACCESS,
  props<{ statusDelete: number }>()
);

export const changeAccess = createAction(
  CHANGE_ACCESS,
  props<{ params: ParamsChangeAccess; id: number }>()
);

export const setStatusChangeAccess = createAction(
  SET_STATUS_CHANGE_ACCESS,
  props<{ statusChange: number; data: DataChangeAccess }>()
);
