import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseGeneral } from '@models/responses/response-general';
import { environment } from '@environments/environment';
import { ResponseLogin } from '@models/responses/response-login';

@Injectable({
  providedIn: 'root',
})
export class CodeService {
  url: string;
  token: any;
  public readonly REQUEST = '/api/auth/otp';
  public readonly VERIFY = '/api/auth/verify-otp';
  public readonly VERIFY_RESET = '/api/auth/verify-reset-otp';

  constructor(private http: HttpClient) {
    this.url = environment.SERVER_URL;
  }

  sendCode(channel: string): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(this.url + this.REQUEST, {
      channel,
    });
  }

  verifyCode(verificationCode: string): Observable<ResponseLogin> {
    return this.http.post<ResponseLogin>(this.url + this.VERIFY, {
      verificationCode,
    });
  }

  verifyCodeRecover(
    verificationCode: string,
    email: string
  ): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(this.url + this.VERIFY_RESET, {
      verificationCode,
      email,
    });
  }
}
