import { createReducer, on } from '@ngrx/store';
import { CatalogsState } from '@models/state/catalogs.state';
import {
  getBusinessActFailure,
  getBusinessActSuccess,
  getCountriesFailure,
  getCountriesSuccess,
  getCurrenciesFailure,
  getCurrenciesSuccess,
  getFundSourceFailure,
  getFundSourceSuccess,
  getOcupationsFailure,
  getOcupationsSuccess,
  getTypesIdFailure,
  getTypesIdSuccess,
} from '@deprecated/state/actions/catalogs.actions';

export const initialState: CatalogsState = {
  countries: [],
  typeOfIds: [],
  currencyList: [],
  ocupations: [],
  businessActivities: [],
  fundSource: [],
};

export const catalogsReducer = createReducer(
  initialState,
  on(getCountriesSuccess, (state, action) => {
    return {
      ...state,
      countries: action.countries,
    };
  }),
  on(getCountriesFailure, state => {
    return {
      ...state,
      countries: [],
    };
  }),
  on(getTypesIdSuccess, (state, action) => {
    return {
      ...state,
      typeOfIds: action.typesId,
    };
  }),
  on(getTypesIdFailure, state => {
    return {
      ...state,
      typeOfIds: [],
    };
  }),
  on(getCurrenciesSuccess, (state, action) => {
    return {
      ...state,
      currencyList: action.currencies,
    };
  }),
  on(getCurrenciesFailure, state => {
    return {
      ...state,
      currencyList: [],
    };
  }),
  on(getOcupationsSuccess, (state, action) => {
    return {
      ...state,
      ocupations: action.ocupations,
    };
  }),
  on(getOcupationsFailure, state => {
    return {
      ...state,
      ocupations: [],
    };
  }),
  on(getBusinessActSuccess, (state, action) => {
    return {
      ...state,
      businessActivities: action.businessActs,
    };
  }),
  on(getBusinessActFailure, state => {
    return {
      ...state,
      businessActivities: [],
    };
  }),
  on(getFundSourceSuccess, (state, action) => {
    return {
      ...state,
      fundSource: action.fundSources,
    };
  }),
  on(getFundSourceFailure, state => {
    return {
      ...state,
      fundSource: [],
    };
  })
);
