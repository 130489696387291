import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'base-button',
  templateUrl: './base-button.component.html',
  styleUrls: ['./base-button.component.scss'],
})
export class BaseButtonComponent {
  @Input() buttonClass = '';
  @Input() color = 'primary';
  @Input() classContent = '';
  @Input() disabled = false;
  @Input() height = 'medium';
  @Input() icon = '';
  @Input() iconClass = '';
  @Input() iconColor = 'primary';
  @Input() loading = false;
  @Input() rounded = 'normal';
  @Input() text = '';
  @Input() type = 'button';
  @Output() emitClick = new EventEmitter();

  onClick(): void {
    if (!this.loading && !this.disabled) {
      this.emitClick.emit();
    }
  }
}
