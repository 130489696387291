import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  NewSubscription,
  ParamsCode,
  UpdateSubscription,
} from '@models/entities/subscription';
import { ResponseGeneral } from '@models/responses/response-general';
import {
  ResponseApplyCode,
  ResponseListPlans,
  ResponseListSubscription,
} from '@models/responses/response-subscription';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  url: string;

  private readonly GET_SUBSCRIPTION = '/api/company/subscription';
  private readonly GET_PLANS = '/api/subscriptions';
  private readonly UPDATE_SUBSCRIPTION = '/api/subscriptions/change';
  private readonly CREATE_SUBSCRIPTION = '/api/company/subscription';
  private readonly APPLY_CODE = '/api/company/checkDiscount';

  constructor(
    private http: HttpClient,
    private generalMethods: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  async getSubscriptions(id: string): Promise<ResponseListSubscription> {
    const subscriptions$ = this.http.get<ResponseListSubscription>(
      `${this.url}${this.GET_SUBSCRIPTION}/${id}`
    );
    return await lastValueFrom(subscriptions$);
  }

  async getPlansByType(type: { type: number }): Promise<ResponseListPlans> {
    const plan$ = this.http.get<ResponseListPlans>(
      this.url + this.GET_PLANS + '?' + this.generalMethods.toQueryString(type)
    );
    return await lastValueFrom(plan$);
  }

  async createSubscription(
    dataSubscription: NewSubscription
  ): Promise<ResponseGeneral> {
    const create$ = this.http.post<ResponseGeneral>(
      this.url + this.CREATE_SUBSCRIPTION,
      dataSubscription
    );
    return await lastValueFrom(create$);
  }

  async updateSubscription(
    updateData: UpdateSubscription
  ): Promise<ResponseGeneral> {
    const update$ = this.http.put<ResponseGeneral>(
      this.url + this.UPDATE_SUBSCRIPTION,
      updateData
    );
    return await lastValueFrom(update$);
  }

  async cancelSubscription(company: number): Promise<ResponseGeneral> {
    const cancel$ = this.http.delete<ResponseGeneral>(
      this.url +
        this.GET_PLANS +
        '?' +
        this.generalMethods.toQueryString({ company })
    );
    return await lastValueFrom(cancel$);
  }

  async applyCode(params: ParamsCode): Promise<ResponseApplyCode> {
    const apply$ = this.http.post<ResponseApplyCode>(
      this.url + this.APPLY_CODE,
      params
    );
    return await lastValueFrom(apply$);
  }
}
