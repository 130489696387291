/* eslint-disable max-len */
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormControl,
  FormGroup,
} from '@angular/forms';
import libphonenumber from 'google-libphonenumber';
import {
  amexCardRegex,
  creditcardRegex,
  emailRegex,
  identityValidatorRegex,
  numberPhoneRegex,
} from '@data/regex.data';

export class InputValidators {
  static matchPassword(abstractC: AbstractControl) {
    const password = abstractC.get('currentPassword').value, // to get value in input tag
      confirmPassword = abstractC.get('confirmPassword').value; // to get value in input tag
    if (confirmPassword === '') {
      abstractC.get('confirmPassword').setErrors({ required: true });
      return;
    } else if (password !== confirmPassword) {
      abstractC.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      abstractC.get('confirmPassword').setErrors(null);
      abstractC.get('confirmPassword').markAsPristine();
      return;
    }
  }

  static areEqual(formGroup: FormGroup) {
    let val;
    let valid = true;

    for (const key in formGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (formGroup.controls.hasOwnProperty(key)) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (val === undefined) {
          val = control.value;
        } else {
          if (val !== control.value) {
            valid = false;
            break;
          }
        }
      }
    }
    if (valid) {
      return null;
    }
    return {
      areEqual: true,
    };
  }

  // Inspired on: https://github.com/yuyang041060120/ng2-validation/blob/master/src/equal-to/validator.ts
  static validCountryPhone = (countryControl: AbstractControl): ValidatorFn => {
    let subscribe = false;

    return (phoneControl: AbstractControl): { [key: string]: boolean } => {
      if (!subscribe) {
        subscribe = true;
        countryControl.valueChanges.subscribe(() => {
          phoneControl.updateValueAndValidity();
        });
      }
      if (phoneControl.value !== '') {
        try {
          const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
          const phoneNumber = '' + phoneControl.value + '';
          const region = countryControl.value.iso;
          const number1 = phoneUtil.parse(phoneNumber, region);
          const isValidNumber = phoneUtil.isValidNumber(number1);
          if (isValidNumber) {
            return null;
          }
        } catch (e) {
          return {
            validCountryPhone: true,
          };
        }
        return {
          validCountryPhone: true,
        };
      } else {
        return null;
      }
    };
  };

  static confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static emailValidator(AC: AbstractControl) {
    const email = AC.get('email').value;
    if (email.match(emailRegex())) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static phoneNumerValidator(AC: AbstractControl) {
    const phone = AC.value;
    if (phone.match(numberPhoneRegex())) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  static identityValidator(AC: AbstractControl) {
    const phone = AC.value;
    if (phone.match(identityValidatorRegex())) {
      return null;
    } else {
      return { identityPhone: true };
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static creditCardValidator(AC: AbstractControl) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    let creditCard = AC.get('creditCard').value;
    const cardno = /^(?:3[47][0-9]{13})$/;
    creditCard = creditCard.replace(/\s/g, '');
    if (creditCard === '') {
      AC.get('creditCard').setErrors({ required: true });
    } else if (creditCard.match(creditcardRegex())) {
      AC.get('creditCard').setErrors(null);
    } else if (cardno.test(creditCard)) {
      AC.get('creditCard').setErrors(null);
    } else {
      AC.get('creditCard').setErrors({ invalidCreditCard: true });
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static AmexCardnumber(inputtxt) {
    const cardno = amexCardRegex();
    return cardno.test(inputtxt);
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
}
