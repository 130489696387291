import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ParamsCustomer,
  PaymentMethodByCustomer,
  PaymentMethodsParams,
  TransactionsParams,
} from '@models/entities/transactions';
import {
  ResponseCustomers,
  ResponsePaymentMethodCustomer,
  ResponsePaymentMethods,
  ResponseTransactions,
} from '@models/responses/response-transaction';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  url: string;

  private readonly GET_CUSTOMERS = '/api/payments/transactions/clients';
  private readonly GET_PAYMENT_METHODS =
    '/api/payments/transactions/clients/payment-methods';
  private readonly GET_PAYMENT_METHOD_BY_CUSTOMER =
    '/api/payments/transactions/payment-methods';
  private readonly GET_TRANSACTIONS = '/api/payments/transactions';

  constructor(
    private http: HttpClient,
    private generalMethods: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  getCustomers(params: ParamsCustomer): Observable<ResponseCustomers> {
    return this.http.get<ResponseCustomers>(
      this.url +
        this.GET_CUSTOMERS +
        '?' +
        this.generalMethods.toQueryString(params)
    );
  }

  getPaymentMethods(
    params: PaymentMethodsParams
  ): Observable<ResponsePaymentMethods> {
    return this.http.get<ResponsePaymentMethods>(
      this.url +
        this.GET_PAYMENT_METHODS +
        '?' +
        this.generalMethods.toQueryString(params)
    );
  }

  getPaymentMethodByCustomer(
    params: PaymentMethodByCustomer
  ): Observable<ResponsePaymentMethodCustomer> {
    return this.http.get<ResponsePaymentMethodCustomer>(
      this.url +
        this.GET_PAYMENT_METHOD_BY_CUSTOMER +
        '?' +
        this.generalMethods.toQueryString(params)
    );
  }

  getTransactions(
    params: TransactionsParams
  ): Observable<ResponseTransactions> {
    return this.http.get<ResponseTransactions>(
      this.url +
        this.GET_TRANSACTIONS +
        '?' +
        this.generalMethods.toQueryString(params)
    );
  }
}
