import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() list: any;
  @Output() emitChangeLink = new EventEmitter<any>();
  currentTab = '';

  ngOnInit() {
    this.currentTab = this.list[0].action;
  }

  changeLink(action) {
    if (action !== this.currentTab) {
      this.currentTab = action;
      this.emitChangeLink.emit(action);
    }
  }
}
