import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { TranslationService } from '@providers/translation-new/translation.service';
import { RequestErrors } from '@enums/requesterrors.enum';
import { Router } from '@angular/router';
import { ROUTES_ADMIN } from '@constants/routes';
import { loginEndpoints } from '@data/newtork.data';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { LoginService } from '@endpoints/login.service';
import { LoginEndpoints } from '@enums/network.enum';
import { StorageService } from '@providers/storage/storage.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { StorageKeys } from '@enums/storage-keys.enum';
import { ModalService } from '@providers/modal/modal.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private methodGenerals: MethodsGeneralService,
    private modalService: ModalService,
    private router: Router,
    private sessionData: SessiondataService,
    private storage: StorageService,
    private translation: TranslationService,
    private userManager: UserManagerService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.reviewMultipleSession()).pipe(
      switchMap(() => {
        if (
          (!this.loginService.unauthorized && !this.noRequest(request.url)) ||
          (request.url.includes(loginEndpoints[LoginEndpoints.idpRequest]) &&
            request.url.includes('/logout'))
        ) {
          if (!window.navigator.onLine) {
            return throwError(() => ({
              status: RequestErrors.NoConnection,
              error: { message: 'no connection' },
            }));
          } else {
            const { accessToken, idpSub, activeManagementGroupId } =
              this.sessionData;
            const { currentlanguage } = this.translation;
            return next.handle(
              this.methodGenerals.getHeaders(
                request,
                accessToken,
                currentlanguage,
                idpSub,
                activeManagementGroupId
              )
            );
          }
        }
      })
    );
  }

  async reviewMultipleSession(): Promise<void> {
    const userCached = await this.storage.getData(StorageKeys.USER);
    const userEmailMemory = this.userManager.email;
    const activeGroupCached = await this.storage.getData(
      StorageKeys.ACTIVE_MANAGEMENT_GROUP
    );
    const activeGroupMemory = this.sessionData.activeManagementGroupId;
    if (
      (userEmailMemory !== userCached?.email ||
        activeGroupMemory !== activeGroupCached) &&
      this.router.url !== ROUTES_ADMIN.LOGIN_ROUTE &&
      this.sessionData.isLoggedIn
    ) {
      if (
        !userEmailMemory ||
        !userCached?.email ||
        !activeGroupMemory ||
        !activeGroupCached
      ) {
        // Si alguno de estos datos no existe, posiblemente quiere decir que se cerró sesión en otra pestaña,
        // por lo que se refresca la página para que el auth se encargue de botarlo al login
        location.reload();
      }
      const {
        actions: { understood },
        alerts: { anotherSessionTitle, anotherSessionDescription },
      } = this.translation.getLangMessagesByKeys(['actions', 'alerts']);
      await this.modalService.showAlert({
        title: anotherSessionTitle,
        message: anotherSessionDescription,
        btnSave: understood,
      });
      location.reload();
    }
  }

  private noRequest(url: string): boolean {
    if (this.router.url === ROUTES_ADMIN.LOGIN_ROUTE) {
      const requestFound = Object.entries(loginEndpoints).every(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) => !url.includes(value)
      );
      if (requestFound) {
        return true;
      }
    }
    return false;
  }
}
