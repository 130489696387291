import { createAction, props } from '@ngrx/store';
import { CodeVerify } from '@models/entities/user';
import { TwoFactorData, RoleAssignment } from '@models/state/user.model';

export const SEND_CODE = '[code page] send code';
export const SEND_CODE_SUCCESS = '[code page] send code success';
export const SEND_CODE_FAILED = '[code page] send code failed';

export const VERIFY_CODE = '[code page] verify code';
export const VERIFY_CODE_FAILED = '[code page] verify code failed';

export const VERIFY_CODE_RECOVER = '[code page] verify code recover';
export const VERIFY_CODE_RECOVER_SUCCESS =
  '[code page] verify code recover success';
export const VERIFY_CODE_RECOVER_FAILED = '[code page] send code failed';

export const SET_ACTIVE_GROUP = '[code page] set active group';

export const CLEAR_DATA = '[code page] clear data';

export const clearDataCode = createAction(CLEAR_DATA);

export const verifyCode = createAction(
  VERIFY_CODE,
  props<{ verificationCode: string; twoFactorData: TwoFactorData }>()
);

export const verifyCodeFailed = createAction(VERIFY_CODE_FAILED);

export const verifyCodeRecover = createAction(
  VERIFY_CODE_RECOVER,
  props<{ verificationCode: string; email: string }>()
);

export const verifyCodeRecoverSuccess = createAction(
  VERIFY_CODE_RECOVER_SUCCESS
);

export const verifyCodeRecoverFailed = createAction(VERIFY_CODE_RECOVER_FAILED);

export const sendCodeTo = createAction(
  SEND_CODE,
  props<{ sendCode: CodeVerify }>()
);

export const sendCodeSuccess = createAction(SEND_CODE_SUCCESS);

export const sendCodeFailed = createAction(SEND_CODE_FAILED);

export const setActiveGroup = createAction(
  SET_ACTIVE_GROUP,
  props<{ activeManagementGroupId: string; roleAssignment: RoleAssignment }>()
);
