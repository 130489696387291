import { Injectable } from '@angular/core';
import { IdPAccessTokenResponse } from '@models/responses/response-login';
import { ManagementGroup } from '@models/state/user.model';

@Injectable({
  providedIn: 'root',
})
export class SessiondataService {
  private _accessToken: IdPAccessTokenResponse;
  private _activeManagementGroupId: string;
  private _managementGroups: ManagementGroup[];
  private _isLoggedIn: boolean;
  private _idpSub: string;

  clearDataSession(): void {
    this._accessToken = undefined;
    this._activeManagementGroupId = '';
    this._managementGroups = undefined;
    this._isLoggedIn = false;
    this._idpSub = '';
  }

  public set accessToken(value: IdPAccessTokenResponse) {
    this._accessToken = value;
  }

  public get accessToken(): IdPAccessTokenResponse {
    return this._accessToken;
  }

  public set activeManagementGroupId(value: string) {
    this._activeManagementGroupId = value;
  }

  public get activeManagementGroupId(): string {
    return this._activeManagementGroupId;
  }

  public set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public set idpSub(value: string) {
    this._idpSub = value;
  }

  public get idpSub(): string {
    return this._idpSub;
  }

  public get managementGroups(): ManagementGroup[] {
    return this._managementGroups;
  }

  public set managementGroups(value: ManagementGroup[]) {
    this._managementGroups = value;
  }
}
