import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'base-toggle',
  templateUrl: './base-toggle.component.html',
  styleUrls: ['./base-toggle.component.scss'],
})
export class BaseToggleComponent {
  @Input() color = 'primary';
  @Input() height = 'small';
  @Input() textNegative = '';
  @Input() textPositive = '';
  @Input() toggleClass = '';
  @Input() toggleControl = new FormControl(false);
}
