import { EndpointNames, LoginEndpoints } from '@enums/network.enum';

export const oneRetryReqs = {
  [EndpointNames.payBilling]: '/api/billing',
  [EndpointNames.bankCardRegister]: '/api/paymentmethods',
  [EndpointNames.createSubscription]: '/api/company/subscription',
  [EndpointNames.cancelSubscription]: '/api/subscriptions',
};

export const loginEndpoints = {
  [LoginEndpoints.loginToken]: 'idp.passer.live/token',
  [LoginEndpoints.userInfo]: 'idp.passer.live/me',
  [LoginEndpoints.idpRequest]: 'idp.passer.live/api/providers/Passer.',
  [LoginEndpoints.requestOTP]: 'deaapi.passer.live/api/auth/otp',
  [LoginEndpoints.verifyOtp]: 'deaapi.passer.live/api/auth/verify-otp',
  [LoginEndpoints.VerifyResetOTP]:
    'deaapi.passer.live/api/auth/verify-reset-otp',
};
