import { Component } from '@angular/core';
import { FOOTER_LOGIN } from '@data/loginFooter';

@Component({
  selector: 'app-login-wrapper',
  templateUrl: './login-wrapper.component.html',
  styleUrls: ['./login-wrapper.component.scss'],
})
export class LoginWrapperComponent {
  footer = FOOTER_LOGIN;
}
