import { createReducer, on } from '@ngrx/store';
import { PaymentMethodState } from '@models/state/payment-method.state';
import {
  billingsFail,
  billingsSuccess,
  clearDataPayments,
  createPaymentMethodSuccess,
  setPayBillingSuccess,
  setPaymentMethods,
  setPaymentMethodSuccess,
} from '@deprecated/state/actions/payment-method.actions';

export const initialState: PaymentMethodState = {
  billings: [],
  paymentMethods: [],
  paymentMethodSuccess: false,
  payBillingSuccess: false,
};

export const paymentMethodReducer = createReducer(
  initialState,
  on(clearDataPayments, () => {
    return { ...initialState };
  }),
  on(billingsSuccess, billingsFail, (state, action) => {
    return {
      ...state,
      billings: [...action.billings],
    };
  }),
  on(setPayBillingSuccess, (state, action) => {
    return {
      ...state,
      payBillingSuccess: action.status,
    };
  }),
  on(setPaymentMethods, (state, action) => {
    return {
      ...state,
      paymentMethods: [...action.paymentMethods],
    };
  }),
  on(createPaymentMethodSuccess, (state, action) => {
    return {
      ...state,
      paymentMethodSuccess: true,
      paymentMethods: [...action.paymentMethods],
    };
  }),
  on(setPaymentMethodSuccess, (state, action) => {
    return {
      ...state,
      paymentMethodSuccess: action.status,
    };
  })
);
