import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Tab {
  name: string;
  action: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() list: Tab[];
  @Output() emitChangeLink = new EventEmitter();
  currentTab = '';

  ngOnInit() {
    this.currentTab = this.list[0].action;
  }

  changeLink(action) {
    if (action !== this.currentTab) {
      this.currentTab = action;
      this.emitChangeLink.emit(action);
    }
  }
}
