import { createReducer, on } from '@ngrx/store';
import { SharedState } from '@models/state/shared.state';
import {
  changeTheme,
  closeSnackbar,
  setErrorMessage,
  showSnackbar,
} from '@deprecated/state/actions/shared.actions';

export const initialState: SharedState = {
  theme: false,
  showSnackbar: false,
  errorMessage: '',
};

export const sharedReducer = createReducer(
  initialState,
  on(changeTheme, (state, action) => {
    return {
      ...state,
      theme: action.theme,
    };
  }),
  on(showSnackbar, state => {
    return {
      ...state,
      showSnackbar: true,
    };
  }),
  on(closeSnackbar, state => {
    return {
      ...state,
      showSnackbar: false,
    };
  }),
  on(setErrorMessage, (state, action) => {
    return {
      ...state,
      errorMessage: action.message,
    };
  })
);
