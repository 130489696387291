// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-icon.primary {
  fill: var(--psr-text-color);
}
.base-icon.white {
  fill: var(--psr-text-light);
}
.base-icon.red {
  fill: var(--icon-red);
}
.base-icon.purple {
  fill: var(--bg-purple);
}
.base-icon.light-purple {
  fill: var(--bg-light-purple);
}
.base-icon.color-disabled {
  fill: var(--psr-disabled-color);
}
.base-icon.color-check {
  fill: var(--psr-color-checks);
}`, "",{"version":3,"sources":["webpack://./src/components/_base/base-icon/base-icon.component.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ;AAEE;EACE,2BAAA;AAAJ;AAEE;EACE,qBAAA;AAAJ;AAEE;EACE,sBAAA;AAAJ;AAEE;EACE,4BAAA;AAAJ;AAEE;EACE,+BAAA;AAAJ;AAEE;EACE,6BAAA;AAAJ","sourcesContent":[".base-icon {\n  &.primary {\n    fill: var(--psr-text-color);\n  }\n  &.white {\n    fill: var(--psr-text-light);\n  }\n  &.red {\n    fill: var(--icon-red);\n  }\n  &.purple {\n    fill: var(--bg-purple);\n  }\n  &.light-purple {\n    fill: var(--bg-light-purple);\n  }\n  &.color-disabled {\n    fill: var(--psr-disabled-color);\n  }\n  &.color-check {\n    fill: var(--psr-color-checks);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
