import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
})
export class AlertBoxComponent implements OnInit {
  hasContent: boolean = false;

  @Input() classDiv = '';
  @Input() color = 'warning';
  @Input() size = 'normal-upper';
  @Input() rounded = 'normal';
  @Input() iconCancel = false;
  @Input() alertParam = false;
  @Input() text = '';
  @Input() textDescription = '';
  @Output() ClickAction = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.hasContent = Boolean(!this.text && !this.textDescription);
  }

  clickAction(): void {
    this.alertParam = !this.alertParam;
    this.ClickAction.emit(this.alertParam);
  }
}
