import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-plan',
  templateUrl: './card-plan.component.html',
  styleUrls: ['./card-plan.component.scss'],
})
export class CardPlanComponent {
  @Input() detail: boolean;
  @Input() info: any;
  @Input() isSpecial: boolean | undefined;
}
