export const emailRegex = () => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
};

export const upperCaseRegex = () => {
  return /[A-Z]/;
};

export const lowerCaseRegex = () => {
  return /[a-z]/;
};

export const numberFormRegex = () => {
  return /\d/;
};

export const numberRegex = () => {
  return /^\d+$/;
};

export const numberPercentageRegex = () => {
  return /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/;
};

export const numberPhoneRegex = () => {
  return /^[0-9]{8}$/;
};

export const identityValidatorRegex = () => {
  return /^[0-9]{9,15}$/;
};

export const creditcardRegex = () => {
  return /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
};

export const amexCardRegex = () => {
  return /^(?:3[47][0-9]{13})$/;
};

export const ibanRegex = () => {
  return /^[a-zA-Z]{2}[0-9]{20}$/;
};
