import { createReducer, on } from '@ngrx/store';
import { SubscriptionState } from '@models/state/subscription.state';
import {
  setCreateSubscriptionSuccess,
  getSubscription,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  setUpdateSubscriptionSuccess,
  setCancelSubscriptionSuccess,
  clearDataSubs,
} from '@deprecated/state/actions/subscription.actions';

export const initialState: SubscriptionState = {
  subscription: [],
  subscriptionLoaded: false,
  newSubscriptionSuccess: false,
  updateSubscriptionSuccess: false,
  cancelSubscriptionSuccess: false,
};

export const subscriptionReducer = createReducer(
  initialState,
  on(clearDataSubs, () => {
    return { ...initialState };
  }),
  on(getSubscription, state => {
    return {
      ...state,
      subscriptionLoaded: false,
    };
  }),
  on(getSubscriptionSuccess, getSubscriptionFailure, (state, action) => {
    return {
      ...state,
      subscription: action.subscription,
      subscriptionLoaded: true,
    };
  }),
  on(setCreateSubscriptionSuccess, (state, action) => {
    return {
      ...state,
      newSubscriptionSuccess: action.status,
    };
  }),
  on(setUpdateSubscriptionSuccess, (state, action) => {
    return {
      ...state,
      updateSubscriptionSuccess: action.status,
    };
  }),
  on(setCancelSubscriptionSuccess, (state, action) => {
    return {
      ...state,
      cancelSubscriptionSuccess: action.status,
    };
  })
);
