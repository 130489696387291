import { createAction, props } from '@ngrx/store';
import { Country } from '@models/entities/general';
import { Currency } from '@models/entities/payS';

export const GET_COUNTRIES = '[catalogs] get countries';
export const GET_COUNTRIES_SUCCESS = '[catalogs] get countries Success';
export const GET_COUNTRIES_FAILED = '[catalogs] get countries Failed';

export const GET_TYPES_ID = '[catalogs] get types id';
export const GET_TYPES_ID_SUCCESS = '[catalogs] get types id Success';
export const GET_TYPES_ID_FAILED = '[catalogs] get types id Failed';

export const GET_CURRENCIES = '[catalogs] get currencies';
export const GET_CURRENCIES_SUCCESS = '[catalogs] get currencies Success';
export const GET_CURRENCIES_FAILED = '[catalogs] get currencies Failed';

export const GET_OCUPATIONS = '[catalogs] get Ocupations';
export const GET_OCUPATIONS_SUCCESS = '[catalogs] get Ocupations Success';
export const GET_OCUPATIONS_FAILED = '[catalogs] get Ocupations Failed';

export const GET_BUSINESS_ACT = '[catalogs] get business act';
export const GET_BUSINESS_ACT_SUCCESS = '[catalogs] get business act Success';
export const GET_BUSINESS_ACT_FAILED = '[catalogs] get business act Failed';

export const GET_FUND_SOURCE = '[catalogs] get fund source';
export const GET_FUND_SOURCE_SUCCESS = '[catalogs] get fund source Success';
export const GET_FUND_SOURCE_FAILED = '[catalogs] get fund source Failed';

export const getCountries = createAction(GET_COUNTRIES);

export const getCountriesSuccess = createAction(
  GET_COUNTRIES_SUCCESS,
  props<{ countries: Country[] }>()
);

export const getCountriesFailure = createAction(GET_COUNTRIES_FAILED);

export const getTypesId = createAction(GET_TYPES_ID);

export const getTypesIdSuccess = createAction(
  GET_TYPES_ID_SUCCESS,
  props<{ typesId: any[] }>()
);

export const getTypesIdFailure = createAction(GET_TYPES_ID_FAILED);

export const getCurrencies = createAction(GET_CURRENCIES);

export const getCurrenciesSuccess = createAction(
  GET_CURRENCIES_SUCCESS,
  props<{ currencies: Currency[] }>()
);

export const getCurrenciesFailure = createAction(GET_CURRENCIES_FAILED);

export const getOcupations = createAction(GET_OCUPATIONS);

export const getOcupationsSuccess = createAction(
  GET_OCUPATIONS_SUCCESS,
  props<{ ocupations: any[] }>()
);

export const getOcupationsFailure = createAction(GET_OCUPATIONS_FAILED);

export const getBusinessAct = createAction(GET_BUSINESS_ACT);

export const getBusinessActSuccess = createAction(
  GET_BUSINESS_ACT_SUCCESS,
  props<{ businessActs: any[] }>()
);

export const getBusinessActFailure = createAction(GET_BUSINESS_ACT_FAILED);

export const getFundSource = createAction(GET_FUND_SOURCE);

export const getFundSourceSuccess = createAction(
  GET_FUND_SOURCE_SUCCESS,
  props<{ fundSources: any[] }>()
);

export const getFundSourceFailure = createAction(GET_FUND_SOURCE_FAILED);
