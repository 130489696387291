import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CatalogsService {
  URL = environment.serverUrlCatalogs;

  constructor(private http: HttpClient) {}

  getCatalogsType(type) {
    const options = { headers: this.headers() };
    return this.http.get(`${this.URL}${type}`, options).pipe(
      map((response: Response) => response),
      catchError(() => of([]))
    );
  }

  headers() {
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }
}
