import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CreditCardPipe',
})
export class CreditCardPipe implements PipeTransform {
  transform(valString: string): string {
    let newval = '';
    valString = valString.replace(/\s/g, '');
    const re = new RegExp('^3[47]');
    if (valString.match(re) != null) {
      if (valString.length > 4 && valString.length <= 10) {
        newval = [valString.slice(0, 4), ' ', valString.slice(4)].join('');
        return newval;
      }
      if (valString.length > 10) {
        newval = [
          valString.slice(0, 4),
          ' ',
          valString.slice(4, 10),
          ' ',
          valString.slice(10, valString.length),
        ].join('');
        return newval;
      }
      return valString;
    } else {
      for (let i = 0; i < valString.length; i++) {
        if (i % 4 === 0 && i > 0) newval = newval.concat(' ');
        newval = newval.concat(valString[i]);
      }
    }
    return newval;
  }

  getFormatSixDigits(sixDigits: string): string {
    if (sixDigits?.length === 6) {
      const lastTwo = sixDigits.slice(-2);
      const firstFour = sixDigits.slice(0, 4);
      return `${firstFour}-${lastTwo}`;
    }
    return sixDigits ? sixDigits : undefined;
  }
}
