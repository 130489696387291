import { Component, Input } from '@angular/core';

@Component({
  selector: 'background-content',
  templateUrl: './background-content.component.html',
})
export class BackgroundContentComponent {
  @Input() classDiv: string;
  @Input() classSubDiv: string;
  @Input() idDiv: string;
  @Input() center: boolean;
}
