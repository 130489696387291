import { StatusDeleteChange } from '@enums/accessType.enum';
import { AccessSecurityState } from '@models/state/access-security.state';
import { createReducer, on } from '@ngrx/store';
import {
  changeAccess,
  clearDataAccess,
  deleteAccess,
  getActionsDataFailed,
  getActionsDataSuccess,
  getSystemRoleListFailed,
  getSystemRoleListSuccess,
  getUserDataList,
  getUserDataListFailed,
  getUserDataListSuccess,
  getUserLogData,
  getUserLogDataFailed,
  getUserLogDataSuccess,
  setStatusChangeAccess,
  setStatusDeleteAccess,
  setUserDataListLoaded,
  setUserLogData,
} from '@deprecated/state/actions/access-security.actions';

export const initialState: AccessSecurityState = {
  actionList: [],
  actionListLoaded: false,
  systemRoleDEAList: [],
  systemRoleDESList: [],
  userList: null,
  userListLoaded: false,
  userLogData: null,
  userLogDataLoaded: false,
  statusDelete: 0,
  statusChange: 0,
  responseChange: null,
};

export const accessSecurityReducer = createReducer(
  initialState,
  on(clearDataAccess, () => {
    return { ...initialState };
  }),
  on(getSystemRoleListSuccess, getSystemRoleListFailed, (state, action) => {
    return {
      ...state,
      systemRoleDEAList: action.systemRoleDEAList,
      systemRoleDESList: action.systemRoleDESList,
    };
  }),
  on(getUserDataList, state => {
    return {
      ...state,
      userListLoaded: false,
    };
  }),
  on(getUserDataListSuccess, getUserDataListFailed, (state, action) => {
    return {
      ...state,
      userList: action.userList,
      userListLoaded: true,
    };
  }),
  on(setUserDataListLoaded, (state, action) => {
    return {
      ...state,
      userListLoaded: action.status,
    };
  }),
  on(getUserLogData, state => {
    return {
      ...state,
      userLogDataLoaded: false,
    };
  }),
  on(getUserLogDataSuccess, getUserLogDataFailed, (state, action) => {
    return {
      ...state,
      userLogData: action.userLogData,
      userLogDataLoaded: true,
    };
  }),
  on(setUserLogData, (state, action) => {
    return {
      ...state,
      userLogData: action.data,
      userLogDataLoaded: action.status,
    };
  }),
  on(getActionsDataSuccess, getActionsDataFailed, (state, action) => {
    return {
      ...state,
      actionList: action.actionList,
      actionListLoaded: true,
    };
  }),
  on(deleteAccess, state => {
    return {
      ...state,
      statusDelete: StatusDeleteChange.LOADING,
    };
  }),
  on(setStatusDeleteAccess, (state, action) => {
    return {
      ...state,
      statusDelete: action.statusDelete,
    };
  }),
  on(changeAccess, state => {
    return {
      ...state,
      statusChange: StatusDeleteChange.LOADING,
    };
  }),
  on(setStatusChangeAccess, (state, action) => {
    return {
      ...state,
      statusChange: action.statusChange,
      responseChange: action.data,
    };
  })
);
