import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Document } from '@models/entities/combosData';
import { TranslationService } from '@providers/translation-new/translation.service';
import { SharedService } from '@providers/shared/shared.service';

@Component({
  selector: 'app-load-file',
  templateUrl: './load-file.html',
})
export class LoadFileComponent {
  @Input() classDiv: string;
  @Input() icon: string;
  @Input() iconClass = '';
  @Input() iconColor = 'primary';
  @Input() text: string;
  @Input() accept: string;
  @Input() acceptLower: Array<string>;
  @Input() disable: number;
  @Input() doc: Array<Document> = [];
  @Output() clickAction = new EventEmitter<any>();

  constructor(
    private sharedService: SharedService,
    private translation: TranslationService
  ) {}

  onFileSelectedDoc(event: any): FileReader {
    if (this.doc.length < this.disable) {
      const selectedFile = event.target.files[0],
        reader = new FileReader();
      reader.onload = (fileLoaded: any) => {
        const f = selectedFile.type.split('/');
        const {
          alerts: { sizeImage },
          actions: { inv_Form },
        } = this.translation.getLangMessagesByKeys(['alerts', 'actions']);
        if (selectedFile.size <= 10000000) {
          if (f.length > 1 && this.acceptLower.includes(f[1].toLowerCase())) {
            this.doc.push({
              name: selectedFile.name,
              base64: fileLoaded.target.result,
              extension: `.${f[1]}`,
            });
            this.clickActionMethod();
          } else {
            this.sharedService.showSnackBar('', inv_Form);
          }
        } else {
          this.sharedService.showSnackBar('', sizeImage);
        }
      };

      reader.readAsDataURL(selectedFile);
      return reader;
    }
  }

  deleteDoc(indexD: number): void {
    this.doc.splice(indexD, 1);
    this.clickActionMethod();
  }

  clickActionMethod(): void {
    this.clickAction.emit(this.doc);
  }
}
