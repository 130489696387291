import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { MatInput } from '@angular/material/input';
import { FormControl, FormGroup } from '@angular/forms';
import { SetDateStringService } from '@providers/set-date-string/set-date-string';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.html',
})
export class CalendarComponent implements OnInit {
  @Input() classDiv: string;
  @Input() idCalendar: string;
  @Input() placeholder: string;
  @Input() max: Date;
  @Input() min: Date;
  @Input() modelData: any;
  @Input() days: any;

  @Input() newCalendar: boolean;
  @Input() picker: FormControl;
  @Input() rangeCalendar: boolean;
  @Input() range: FormGroup;

  modelControl: any;

  @Output() ClickAction = new EventEmitter<any>();
  @Output() ClearAction = new EventEmitter<any>();
  @ViewChild('input', { read: MatInput }) input: MatInput;

  constructor(private formatDate: SetDateStringService) {}

  ngOnInit(): void {
    this.modelControl = moment(this.modelData, 'DD-MM-YYYY').toDate();
  }

  filterDate = (d: Date): boolean => {
    if (this.days) {
      const date = this.formatDate.setDateString(d, 1, '-');
      return (
        this.days.length === 0 ||
        !this.days.some(
          day => day === (!!Number(day) || day === 0 ? d.getDay() : date)
        )
      );
    } else return true;
  };

  clickAction($event): void {
    const { value } = $event;
    this.modelControl = value;
    this.modelData = this.formatDate.setDateString(value, 1, '-');
    this.ClickAction.emit({ model: this.modelData, input: this.input });
  }

  clickRange(): void {
    if (this.range.value.end) {
      const { start, end } = this.range.value;
      this.ClickAction.emit({ start: start.getTime(), end: end.getTime() });
    }
  }

  clickRangeClear(): void {
    this.range.controls.start.setValue('');
    this.range.controls.end.setValue('');
    this.ClearAction.emit();
  }

  clickCalendar(): void {
    this.ClickAction.emit();
  }
}
