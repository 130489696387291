import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.html',
})
export class AutocompleteComponent implements OnInit {
  @Input() classAutoMat: string;
  @Input() selectionMaterial: FormControl;
  @Input() itemsOrigi = [];
  @Input() search: string;
  @Input() placeholder: string;

  @Input() selection: string;
  @Output() selectedChange = new EventEmitter<{
    item: string;
    clear: boolean;
  }>();

  filteredOptions$: Observable<string[]>;

  ngOnInit(): void {
    this.filteredOptions$ = this.selectionMaterial.valueChanges.pipe(
      startWith(''),
      map(value => (value ? this._filter(value) : this.itemsOrigi.slice()))
    );
  }

  _filter(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.itemsOrigi.filter(option =>
      `${this.search ? option[this.search] : option}`
        .toLowerCase()
        .includes(`${filterValue}`)
    );
  }

  changeAutocompl(item, empty = false): void {
    let response = '';
    if (!empty && item) {
      response = item;
    }

    this.selectedChange.emit({ item: response, clear: !(!empty || item) });
  }
}
