import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { icons } from '@data/icons.data';

@Component({
  selector: 'base-icon',
  templateUrl: './base-icon.component.html',
  styleUrls: ['./base-icon.component.scss'],
})
export class BaseIconComponent implements OnInit, OnChanges {
  @Input() icon = '';
  @Input() iconClass = '';
  @Input() iconColor = 'primary';
  @Input() label = '';
  @Input() srcIcon = '';

  srcSelected = '';

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.srcSelected = icons[this.srcIcon];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.srcIcon?.currentValue) {
      this.srcSelected = icons[this.srcIcon];
    } else {
      this.srcSelected = '';
    }

    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }
}
