import { Injectable } from '@angular/core';
import { isLessThanXMins, ITEM_CACHE } from '@data/times';
import { AccessSecurityService } from '@endpoints/access-security.service';
import { CatalogsConnections } from '@endpoints/catalogs.service';
import { CredentialsService } from '@endpoints/credentials.service';
import { PaymentMethodService } from '@endpoints/payment-method.service';
import { SubscriptionService } from '@endpoints/subscription.service';
import { Catalogs } from '@enums/catalogs.enum';
import { DashboardType } from '@enums/general-data.enum';
import { StorageKeys } from '@enums/storage-keys.enum';
import {
  ActionData,
  RolePrivileges,
  SystemRoleList,
} from '@models/entities/access';
import { EcommerceCredential } from '@models/entities/account';
import { Country, CrudCache, TypeId } from '@models/entities/general';
import { Currency } from '@models/entities/payS';
import {
  ResponseActions,
  ResponsePrivileges,
  ResponseSystemRoleList,
} from '@models/responses/response-access';
import {
  ResponseBusinessActivityAndFunds,
  ResponseCountries,
  ResponseCurrencyList,
  ResponseTypeID,
} from '@models/responses/response-catalogs';
import { ResponseEcommerceCredentials } from '@models/responses/response-credentials';
import {
  Billing,
  PaymentMethod,
  ResponseBillings,
  ResponsePaymentMethods,
} from '@models/responses/response-payment';
import {
  ListSubscription,
  ResponseListSubscription,
} from '@models/responses/response-subscription';
import { EventCallbackService } from '@providers/event-callback/event-callback.service';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { StorageService } from '@providers/storage/storage.service';
import { TranslationService } from '@providers/translation-new/translation.service';
import { SharedService } from '@providers/shared/shared.service';
import { DashboardID } from '@enums/accessType.enum';

@Injectable({
  providedIn: 'root',
})
export class CallCacheEndpointsService {
  constructor(
    private accessService: AccessSecurityService,
    private callbackService: EventCallbackService,
    private catalogService: CatalogsConnections,
    private credentialService: CredentialsService,
    private paymentMethodService: PaymentMethodService,
    private session: SessiondataService,
    private sharedService: SharedService,
    private storage: StorageService,
    private subscriptionService: SubscriptionService,
    private translation: TranslationService
  ) {}

  async getInitialValues(
    option: number,
    itemTime: string,
    item: string,
    timeData: number
  ): Promise<any> {
    try {
      const time: number = await this.storage.getData(itemTime);
      if (time) {
        const validated = isLessThanXMins(new Date(), new Date(time), timeData);
        return validated
          ? await this.getLocalData(item, option)
          : await this.selectedOption(option);
      } else {
        return await this.selectedOption(option);
      }
    } catch (error) {
      return await this.selectedOption(option);
    }
  }

  async selectedOption(opt: number): Promise<any> {
    const getCrudData: CrudCache = this.callbackService.getCrudObjectCach();
    return await this.callbackService.handleEventCallback(opt, {
      [getCrudData.country]: await this.getCountries.bind(this),
      [getCrudData.idType]: await this.getTypeID.bind(this),
      [getCrudData.businessActivity]:
        await this.getBusinessActivities.bind(this),
      [getCrudData.position]: await this.getPositions.bind(this),
      [getCrudData.fund]: await this.getFunds.bind(this),
      [getCrudData.currency]: await this.getCurrencies.bind(this),
      [getCrudData.subscription]: await this.getSubscriptions.bind(this),
      [getCrudData.ecommerceCredentials]:
        await this.getEcommerceCredentialsList.bind(this),
      [getCrudData.paymentMethods]: await this.getPaymentMethods.bind(this),
      [getCrudData.billings]: await this.getBilling.bind(this),
      [getCrudData.systemRoles]: await this.getSystemRoles.bind(this),
      [getCrudData.actions]: await this.getActionsLog.bind(this),
      [getCrudData.privileges]: await this.getSysRolesPrivileges.bind(this),
    });
  }

  async getLocalData(dataName: string, option: number): Promise<any> {
    try {
      const resolved = await this.storage.getData(dataName);
      return resolved ? resolved : await this.selectedOption(option);
    } catch (error) {
      return await this.selectedOption(option);
    }
  }

  async clearDataCache(): Promise<void> {
    await this.storage.removeData(ITEM_CACHE.subscription);
    await this.storage.removeData(ITEM_CACHE.subscriptionLocal);
    await this.storage.removeData(ITEM_CACHE.subscriptionTime);
    await this.storage.removeData(ITEM_CACHE.ecommerceCredentails);
    await this.storage.removeData(ITEM_CACHE.ecommerceCredentailsTime);
    await this.storage.removeData(ITEM_CACHE.ecommerceCredentailsLocal);
    await this.storage.removeData(ITEM_CACHE.paymentMethods);
    await this.storage.removeData(ITEM_CACHE.paymentMethodsTime);
    await this.storage.removeData(ITEM_CACHE.paymentMethodsLocal);
    await this.storage.removeData(ITEM_CACHE.billings);
    await this.storage.removeData(ITEM_CACHE.billingsTime);
    await this.storage.removeData(ITEM_CACHE.billingsLocal);
    await this.storage.removeData(ITEM_CACHE.systemRoles);
    await this.storage.removeData(ITEM_CACHE.systemRolesTime);
    await this.storage.removeData(ITEM_CACHE.systemRolesLocal);
    await this.storage.removeData(ITEM_CACHE.actions);
    await this.storage.removeData(ITEM_CACHE.actionsTime);
    await this.storage.removeData(ITEM_CACHE.actionsLocal);
    await this.storage.removeData(StorageKeys.subscriptionSelected);
  }

  // COUNTRY LIST
  async getCountries(): Promise<Country[]> {
    const {
      cacheErrors: { country },
    } = this.translation.getLangMessagesByKeys('alerts');
    try {
      const response: ResponseCountries = await this.catalogService.getCatalog(
        Catalogs.GET_COUNTRIES
      );
      if (response.success) {
        await this.storage.setData(ITEM_CACHE.countryTime, Date.now());
        await this.storage.setData(ITEM_CACHE.countryLocal, response.data);
        return response.data;
      } else {
        this.sharedService.showSnackBar('', country);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      } else {
        this.sharedService.showSnackBar('', country);
      }
      return [];
    }
  }

  // ID TYPE
  async getTypeID(): Promise<TypeId[]> {
    const {
      cacheErrors: { tipeId },
    } = this.translation.getLangMessagesByKeys('alerts');
    try {
      const response: ResponseTypeID = await this.catalogService.getCatalog(
        Catalogs.GET_TYPE_ID
      );
      if (response.success) {
        await this.storage.setData(ITEM_CACHE.idTypeTime, Date.now());
        await this.storage.setData(ITEM_CACHE.idTypeLocal, response.data);
        return response.data;
      } else {
        this.sharedService.showSnackBar('', tipeId);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      } else {
        this.sharedService.showSnackBar('', tipeId);
      }
      return [];
    }
  }

  // BUSINESS ACTIVITIES
  async getBusinessActivities(): Promise<TypeId[]> {
    const {
      cacheErrors: { businessActivity },
    } = this.translation.getLangMessagesByKeys('alerts');
    try {
      const response: ResponseBusinessActivityAndFunds =
        await this.catalogService.getCatalog(Catalogs.GET_ACT_COM);
      if (response.success) {
        await this.storage.setData(ITEM_CACHE.businessActivityTime, Date.now());
        await this.storage.setData(
          ITEM_CACHE.businessActivityLocal,
          response.data
        );
        return response.data;
      } else {
        this.sharedService.showSnackBar('', businessActivity);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      } else {
        this.sharedService.showSnackBar('', businessActivity);
      }
      return [];
    }
  }

  // POSITIONS
  async getPositions(): Promise<TypeId[]> {
    const {
      cacheErrors: { positions },
    } = this.translation.getLangMessagesByKeys('alerts');
    try {
      const response: ResponseBusinessActivityAndFunds =
        await this.catalogService.getCatalog(Catalogs.GET_OCUPATIONS);
      if (response.success) {
        await this.storage.setData(ITEM_CACHE.positionTime, Date.now());
        await this.storage.setData(ITEM_CACHE.positionLocal, response.data);
        return response.data;
      } else {
        this.sharedService.showSnackBar('', positions);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      } else {
        this.sharedService.showSnackBar('', positions);
      }
      return [];
    }
  }

  // FUNDS
  async getFunds(): Promise<TypeId[]> {
    const {
      cacheErrors: { funds },
    } = this.translation.getLangMessagesByKeys('alerts');
    try {
      const response: ResponseBusinessActivityAndFunds =
        await this.catalogService.getCatalog(Catalogs.GET_FUND_SOURCE);
      if (response.success) {
        await this.storage.setData(ITEM_CACHE.fundTime, Date.now());
        await this.storage.setData(ITEM_CACHE.fundLocal, response.data);
        return response.data;
      } else {
        this.sharedService.showSnackBar('', funds);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      } else {
        this.sharedService.showSnackBar('', funds);
      }
      return [];
    }
  }

  // CURRENCY
  async getCurrencies(): Promise<Currency[]> {
    const {
      cacheErrors: { currency },
    } = this.translation.getLangMessagesByKeys('alerts');
    try {
      const response: ResponseCurrencyList =
        await this.catalogService.getCatalog(Catalogs.GET_CURRENCY_LIST);
      if (response.success) {
        await this.storage.setData(ITEM_CACHE.currencyTime, Date.now());
        await this.storage.setData(ITEM_CACHE.currencyLocal, response.data);
        return response.data;
      } else {
        this.sharedService.showSnackBar('', currency);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      } else {
        this.sharedService.showSnackBar('', currency);
      }
      return [];
    }
  }

  // SUBSCRIPTIONS
  async getSubscriptions(): Promise<ListSubscription[]> {
    try {
      const response: ResponseListSubscription =
        await this.subscriptionService.getSubscriptions(
          this.session.activeManagementGroupId
        );
      const { data, success, message, description } = response;
      if (success) {
        await this.storage.setData(ITEM_CACHE.subscriptionTime, Date.now());
        await this.storage.setData(ITEM_CACHE.subscriptionLocal, data);
        return data;
      } else {
        this.sharedService.showSnackBar(message, description);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.message, error.description);
      }
      return [];
    }
  }

  // ECOMMERCE CREDENTIALS
  async getEcommerceCredentialsList(): Promise<EcommerceCredential[]> {
    try {
      const response: ResponseEcommerceCredentials =
        await this.credentialService.getEcommerceCredentials();
      const { success, data, message, description } = response;
      if (success) {
        await this.storage.setData(
          ITEM_CACHE.ecommerceCredentailsTime,
          Date.now()
        );
        await this.storage.setData(
          ITEM_CACHE.ecommerceCredentailsLocal,
          response.data
        );
        return data;
      } else {
        this.sharedService.showSnackBar(message, description);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return [];
    }
  }

  // PAYMENT METHODS
  async getPaymentMethods(): Promise<PaymentMethod[]> {
    try {
      const response: ResponsePaymentMethods =
        await this.paymentMethodService.getPaymentMethods();
      const { success, data, message, description } = response;
      if (success) {
        const methods: PaymentMethod[] =
          this.paymentMethodService.setPaymentMethodArray(data);
        await this.storage.setData(ITEM_CACHE.paymentMethodsTime, Date.now());
        await this.storage.setData(ITEM_CACHE.paymentMethodsLocal, methods);
        return methods;
      } else {
        this.sharedService.showSnackBar(message, description);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return [];
    }
  }

  // BILLING
  async getBilling(): Promise<Billing[]> {
    try {
      const response: ResponseBillings =
        await this.paymentMethodService.getBillings();
      const { success, data, message, description } = response;
      if (success) {
        await this.storage.setData(ITEM_CACHE.billingsTime, Date.now());
        await this.storage.setData(ITEM_CACHE.billingsLocal, response.data);
        return data;
      } else {
        this.sharedService.showSnackBar(message, description);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return [];
    }
  }

  // SYSTEM ROLES
  async getSystemRoles(): Promise<{
    systemRoleDEAList: SystemRoleList[];
    systemRoleDESList: SystemRoleList[];
  }> {
    try {
      const response: ResponseSystemRoleList =
        await this.accessService.getSystemRoleList();
      const { success, data, message, description } = response;
      if (success) {
        const systemRoleDEAList = [];
        const systemRoleDESList = [];
        const {
          accessManagement: { descriptionRoleDEA, descriptionRoleDES },
        } = this.translation.getLangMessagesByKeys('accessSecurity');
        let roleSelected;
        for (const systemRole of data) {
          if (
            systemRole.systemPlatform.platformName ===
            DashboardType.DASHBOARD_ACCOUNT
          ) {
            roleSelected = descriptionRoleDEA.find(
              roleDEA => roleDEA.role === systemRole.id
            );
            if (roleSelected) {
              systemRole.systemPlatform.description = roleSelected.description;
              systemRoleDEAList.push(systemRole);
            }
          } else {
            roleSelected = descriptionRoleDES.find(
              roleDES => roleDES.role === systemRole.id
            );
            if (roleSelected) {
              systemRole.systemPlatform.description = roleSelected.description;
              systemRoleDESList.push(systemRole);
            }
          }
        }
        await this.storage.setData(ITEM_CACHE.systemRolesTime, Date.now());
        await this.storage.setData(ITEM_CACHE.systemRolesLocal, {
          systemRoleDEAList,
          systemRoleDESList,
        });
        return {
          systemRoleDEAList,
          systemRoleDESList,
        };
      } else {
        this.sharedService.showSnackBar(message, description);
        return {
          systemRoleDEAList: [],
          systemRoleDESList: [],
        };
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return {
        systemRoleDEAList: [],
        systemRoleDESList: [],
      };
    }
  }

  // ACTIONS LOG
  async getActionsLog(): Promise<ActionData[]> {
    try {
      const response: ResponseActions = await this.accessService.getActions();
      const { success, data, message, description } = response;
      if (success) {
        await this.storage.setData(ITEM_CACHE.actionsTime, Date.now());
        await this.storage.setData(ITEM_CACHE.actionsLocal, response.data);
        return data;
      } else {
        this.sharedService.showSnackBar(message, description);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return [];
    }
  }

  // PRIVILEGES
  async getSysRolesPrivileges(): Promise<RolePrivileges[]> {
    try {
      const response: ResponsePrivileges =
        await this.accessService.getPrivileges();
      const { success, data, message, description } = response;
      if (success) {
        await this.storage.setData(ITEM_CACHE.privilegesTime, Date.now());
        await this.storage.setData(ITEM_CACHE.privilegesLocal, response.data);
        return data;
      } else {
        this.sharedService.showSnackBar(message, description);
        return [];
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return [];
    }
  }
}
