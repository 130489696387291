import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'; // Make sure this is correctly imported

interface Tab {
  name: string;
  action: string;
}

@Component({
  selector: 'base-tabs',
  templateUrl: './base-tabs.component.html',
  styleUrls: ['./base-tabs.component.scss'],
})
export class BaseTabsComponent implements OnInit, OnChanges {
  @Input() list: Tab[];
  @Input() isSubTab: boolean = false;
  @Input() changeCurrentTab;
  @Output() emitChangeLink = new EventEmitter();
  currentTab = '';

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (this.isSubTab) {
        this.currentTab = params['subTab'] || this.list[0].action;
      } else {
        this.currentTab = params['tab'] || this.list[0].action;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.changeCurrentTab?.currentValue) {
      this.currentTab = this.changeCurrentTab;
    }
  }

  changeLink(tab): void {
    if (tab !== this.currentTab) {
      const queryStringSearch = window.location.search;
      const queryParamsSnap = this.parseQueryParams(queryStringSearch);
      let queryParams = { ...queryParamsSnap };
      if (this.isSubTab) {
        queryParams = { ...queryParams, subTab: tab };
      } else {
        if (queryParams.subTab) {
          delete queryParams.subTab;
        }
        queryParams = { ...queryParams, tab };
      }
      const queryString = new URLSearchParams(queryParams).toString();
      const currentPath = this.location.path().split('?')[0];
      const newUrl = `${currentPath}?${queryString}`;

      this.location.replaceState(newUrl);
      this.currentTab = tab;
      this.emitChangeLink.emit({ action: tab, component: this });
    }
  }

  private parseQueryParams(queryString: string): Record<string, string> {
    const params: Record<string, string> = {};
    if (queryString) {
      queryString
        .substring(1) // Remove the '?' at the start
        .split('&') // Split by '&'
        .forEach(param => {
          const [key, value] = param.split('=');
          params[decodeURIComponent(key)] = decodeURIComponent(value || '');
        });
    }
    return params;
  }
}
