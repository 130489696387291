import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PaymentMethod,
  ResponseBillings,
  ResponsePaymentMethods,
} from '@models/responses/response-payment';
import { environment } from '@environments/environment';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { CreateCard, PayBillingRequest } from '@models/entities/payments';
import { ResponseGeneral } from '@models/responses/response-general';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  url: string;

  public readonly GET_BILLINGS = '/api/billing';
  public readonly PAYMENT_METHODS = '/api/paymentmethods';

  constructor(
    private http: HttpClient,
    private generalMethods: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  getBillings(): Observable<ResponseBillings> {
    return this.http.get<ResponseBillings>(this.url + this.GET_BILLINGS);
  }

  payBilling(params: PayBillingRequest): Observable<ResponseGeneral> {
    return this.http.put<ResponseGeneral>(this.url + this.GET_BILLINGS, {
      ...params,
    });
  }

  getPaymentMethods(): Observable<ResponsePaymentMethods> {
    return this.http.get<ResponsePaymentMethods>(
      this.url + this.PAYMENT_METHODS
    );
  }

  createPaymentMethod(params: CreateCard): Observable<ResponsePaymentMethods> {
    return this.http.post<ResponsePaymentMethods>(
      this.url + this.PAYMENT_METHODS,
      { ...params }
    );
  }

  updatePaymentMethod(
    paymentMethodId: number
  ): Observable<ResponsePaymentMethods> {
    return this.http.put<ResponsePaymentMethods>(
      this.url + this.PAYMENT_METHODS,
      { paymentMethodId }
    );
  }

  deletePaymentMethod(paymentMethodId): Observable<ResponsePaymentMethods> {
    return this.http.delete<ResponsePaymentMethods>(
      this.url +
        this.PAYMENT_METHODS +
        '?' +
        this.generalMethods.toQueryString(paymentMethodId)
    );
  }

  setPaymentMethodArray(array: Array<PaymentMethod>) {
    return [...array].map(card => {
      return {
        ...card,
        icon: this.generalMethods.setImage(card.brand),
      };
    });
  }
}
