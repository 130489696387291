import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TitleDescComponent } from './title-desc/title-desc';
import { NormalModalComponent } from './normal-modal/normal-modal';
import { DeletedAccountComponent } from './deleted-account/deleted-account.component';
import { InsertCodeComponent } from './insert-code/insert-code.component';
import { LoginComponent } from './login/login.component';
import { TwoFactorComponent } from './two-factor/two-factor.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { IonicModule } from '@ionic/angular';

import { MaterialModule } from '@app/app-material.module';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { PinComponent } from './pin/pin';
import { BoxWhiteComponent } from './box-white/box-white';
import { LoaderComponent } from './loader/loader';
import { SkeletonComponent } from './skeleton/skeleton';
import { LangComponent } from './lang/lang';
import { BackgroundContentComponent } from './background-content/background-content.component';
import { HeaderComponent } from './header/header';
import { NavbarComponent } from './navbar/navbar';
import { ListTabComponent } from './list-tab/list-tab';
import { DoubleInputComponent } from './double-input/double-input';
import { BoxTextComponent } from './box-text/box-text';
import { TabsComponent } from './tabs/tabs.component';
import { AutocompleteComponent } from './autocomplete/autocomplete';
import { CustomTableComponent } from './custom-table/custom-table';
import { CustomMenuComponent } from './custom-menu/custom-menu';
import { CalendarComponent } from './calendar/calendar';
import { LoadFileComponent } from './load-file/load-file';
import { ModalImageComponent } from './modal-image/modal-image';
import { DirectivesModule } from '@directive/directives.module';
import { SimpleInfoComponent } from './simple-info/simple-info.component';
import { CompoundCardComponent } from './compound-card/compound-card.component';
import { CardPlanComponent } from './card-plan/card-plan.component';
import { ToggleButtonsComponent } from './toggle-buttons/toggle-buttons.component';
import { TitleTextAreaComponent } from './title-textArea/title-textArea';
import { BoxComponent } from './box/box';
import { NgxPaginationModule } from 'ngx-pagination';
import { IconTextComponent } from './icon-text/icon-text';
import { BaseIconComponent } from './_base/base-icon/base-icon.component';
import { BaseButtonComponent } from './_base/base-button/base-button.component';
import { BaseInputComponent } from './_base/base-input/base-input.component';
import { BaseSelectComponent } from './_base/base-select/base-select.component';
import { BaseToggleComponent } from './_base/base-toggle/base-toggle.component';
import { BaseCheckboxComponent } from './_base/base-checkbox/base-checkbox.component';
import { BaseRadioComponent } from './_base/base-radio/base-radio.component';
import { BaseDatatableComponent } from './_base/base-datatable/base-datatable.component';
import { ConfirmCredentialsComponent } from './confirm-credentials/confirm-credentials.component';
import { PipeModule } from '@pipes/pipes.modules';
import { BaseTabsComponent } from './_base/base-tabs/base-tabs.component';
import { AlertBoxComponent } from './alert-box/alert-box.component';

@NgModule({
  declarations: [
    AlertBoxComponent,
    AutocompleteComponent,
    BaseButtonComponent,
    BaseCheckboxComponent,
    BaseDatatableComponent,
    BaseIconComponent,
    BaseInputComponent,
    BaseRadioComponent,
    BaseSelectComponent,
    BaseTabsComponent,
    BaseToggleComponent,
    ConfirmCredentialsComponent,
    TitleDescComponent,
    NormalModalComponent,
    DeletedAccountComponent,
    InsertCodeComponent,
    LoginComponent,
    TwoFactorComponent,
    ErrorMessageComponent,
    LoginWrapperComponent,
    PinComponent,
    BoxWhiteComponent,
    LoaderComponent,
    SkeletonComponent,
    LangComponent,
    BackgroundContentComponent,
    HeaderComponent,
    NavbarComponent,
    ListTabComponent,
    DoubleInputComponent,
    BoxTextComponent,
    TabsComponent,
    CustomTableComponent,
    CustomMenuComponent,
    CalendarComponent,
    LoadFileComponent,
    ModalImageComponent,
    SimpleInfoComponent,
    CompoundCardComponent,
    CardPlanComponent,
    ToggleButtonsComponent,
    TitleTextAreaComponent,
    BoxComponent,
    IconTextComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    IonicModule.forRoot(),
    DirectivesModule,
    NgxPaginationModule,
    PipeModule,
  ],
  exports: [
    AlertBoxComponent,
    AutocompleteComponent,
    BaseButtonComponent,
    BaseCheckboxComponent,
    BaseDatatableComponent,
    BaseIconComponent,
    BaseInputComponent,
    BaseRadioComponent,
    BaseSelectComponent,
    BaseTabsComponent,
    BaseToggleComponent,
    ConfirmCredentialsComponent,
    TitleDescComponent,
    NormalModalComponent,
    DeletedAccountComponent,
    InsertCodeComponent,
    LoginComponent,
    TwoFactorComponent,
    ErrorMessageComponent,
    LoginWrapperComponent,
    PinComponent,
    BoxWhiteComponent,
    LoaderComponent,
    SkeletonComponent,
    LangComponent,
    BackgroundContentComponent,
    HeaderComponent,
    NavbarComponent,
    ListTabComponent,
    DoubleInputComponent,
    BoxTextComponent,
    TabsComponent,
    CustomTableComponent,
    CustomMenuComponent,
    CalendarComponent,
    LoadFileComponent,
    ModalImageComponent,
    SimpleInfoComponent,
    CompoundCardComponent,
    CardPlanComponent,
    ToggleButtonsComponent,
    TitleTextAreaComponent,
    BoxComponent,
    IconTextComponent,
  ],
})
export class ComponentsModule {}
