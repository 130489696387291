import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ParamsCheckEcommerce,
  ParamsConnectEcommerce,
  ParamsEcommerce,
  ParamsCreateEcommerceCredential,
} from '@models/entities/account';
import {
  ResponseCheckEcommerce,
  ResponseEcommerceCredentials,
  ResponseSubscriptionConnected,
} from '@models/responses/response-credentials';
import { ResponseGeneral } from '@models/responses/response-general';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  url: string;
  token: any;
  private readonly REQUEST = '/api/ecommerce/check';
  private readonly ECOMMERCE = '/api/ecommerce';
  private readonly ECOMMERCE_MERCHANT = '/api/ecommerce/merchant';
  private readonly ECOMMERCE_CREDENTIAL = '/api/ecommerce/credential';
  private readonly SUBSCRIPTION_CONNECTED = '/api/payments';

  constructor(private http: HttpClient) {
    this.url = environment.SERVER_URL;
  }

  checkEcommerce(
    params: ParamsCheckEcommerce
  ): Observable<ResponseCheckEcommerce> {
    return this.http.post<ResponseCheckEcommerce>(
      this.url + this.REQUEST,
      params
    );
  }

  requestEcommerce(params: ParamsEcommerce): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(
      this.url +
        (params.isEcommerceCredentials
          ? this.ECOMMERCE
          : this.ECOMMERCE_MERCHANT),
      params
    );
  }

  ecommerceCredential(
    params: ParamsCreateEcommerceCredential
  ): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(
      this.url + this.ECOMMERCE_CREDENTIAL,
      params
    );
  }

  getEcommerceCredentials(): Observable<ResponseEcommerceCredentials> {
    return this.http.get<ResponseEcommerceCredentials>(
      this.url + this.ECOMMERCE_CREDENTIAL
    );
  }

  getSubscriptionConnected(
    subscriptionId: number
  ): Observable<ResponseSubscriptionConnected> {
    return this.http.get<ResponseSubscriptionConnected>(
      `${this.url}${this.SUBSCRIPTION_CONNECTED}/${subscriptionId}`
    );
  }

  saveConnection(
    id: string | number,
    params: ParamsConnectEcommerce,
    update: boolean
  ): Observable<ResponseGeneral> {
    if (update) {
      return this.http.put<ResponseGeneral>(
        `${this.url}${this.SUBSCRIPTION_CONNECTED}/${id}`,
        params
      );
    } else {
      return this.http.post<ResponseGeneral>(
        this.url + this.SUBSCRIPTION_CONNECTED,
        params
      );
    }
  }

  disconnectCredential(
    suscriptions: string | number
  ): Observable<ResponseGeneral> {
    return this.http.delete<ResponseGeneral>(
      this.url + this.SUBSCRIPTION_CONNECTED,
      { body: { suscriptions } }
    );
  }

  deleteCredentialEcommerce(id: string | number): Observable<ResponseGeneral> {
    return this.http.delete<ResponseGeneral>(
      `${this.url}${this.ECOMMERCE}/${id}`
    );
  }

  updateCredentialEcommerce(
    id: string | number,
    params: { acquirerFee: number }
  ): Observable<ResponseGeneral> {
    return this.http.put<ResponseGeneral>(
      `${this.url}${this.ECOMMERCE_CREDENTIAL}/${id}`,
      params
    );
  }
}
