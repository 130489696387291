// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-menu .sidenav,
.navbar-menu .nav-admin {
  max-width: 270px;
}
.navbar-menu .sidenav__action-button,
.navbar-menu .nav-admin__action-button {
  min-height: 42.5px;
}
.navbar-menu .sidenav__action-button base-icon ::ng-deep ion-icon,
.navbar-menu .nav-admin__action-button base-icon ::ng-deep ion-icon {
  fill: var(--psr-input-border) !important;
}
.navbar-menu .sidenav__action-button .span-text,
.navbar-menu .nav-admin__action-button .span-text {
  color: var(--psr-text-color);
}
.navbar-menu .sidenav__action-button.active, .navbar-menu .sidenav__action-button:active, .navbar-menu .sidenav__action-button:hover, .navbar-menu .sidenav__action-button:focus,
.navbar-menu .nav-admin__action-button.active,
.navbar-menu .nav-admin__action-button:active,
.navbar-menu .nav-admin__action-button:hover,
.navbar-menu .nav-admin__action-button:focus {
  color: var(--psr-radio-button-bg) !important;
  background-color: var(--psr-lighter-purple) !important;
}
.navbar-menu .sidenav__action-button.active base-icon ::ng-deep ion-icon, .navbar-menu .sidenav__action-button:active base-icon ::ng-deep ion-icon, .navbar-menu .sidenav__action-button:hover base-icon ::ng-deep ion-icon, .navbar-menu .sidenav__action-button:focus base-icon ::ng-deep ion-icon,
.navbar-menu .nav-admin__action-button.active base-icon ::ng-deep ion-icon,
.navbar-menu .nav-admin__action-button:active base-icon ::ng-deep ion-icon,
.navbar-menu .nav-admin__action-button:hover base-icon ::ng-deep ion-icon,
.navbar-menu .nav-admin__action-button:focus base-icon ::ng-deep ion-icon {
  fill: var(--psr-radio-button-bg) !important;
}
.navbar-menu .sidenav__action-button.active .span-text, .navbar-menu .sidenav__action-button:active .span-text, .navbar-menu .sidenav__action-button:hover .span-text, .navbar-menu .sidenav__action-button:focus .span-text,
.navbar-menu .nav-admin__action-button.active .span-text,
.navbar-menu .nav-admin__action-button:active .span-text,
.navbar-menu .nav-admin__action-button:hover .span-text,
.navbar-menu .nav-admin__action-button:focus .span-text {
  color: var(--psr-radio-button-bg) !important;
}
.navbar-menu .nav-admin {
  background-color: var(--psr-input-border-62) !important;
}
.navbar-menu .nav-admin__action-button {
  min-height: auto;
  color: var(--psr-text-color);
}
.navbar-menu .nav-admin__action-button.active, .navbar-menu .nav-admin__action-button:active, .navbar-menu .nav-admin__action-button:hover, .navbar-menu .nav-admin__action-button:focus {
  color: var(--psr-text-title-modal) !important;
  background-color: transparent !important;
}
.navbar-menu .nav-admin__action-button.active .span-text, .navbar-menu .nav-admin__action-button:active .span-text, .navbar-menu .nav-admin__action-button:hover .span-text, .navbar-menu .nav-admin__action-button:focus .span-text {
  color: var(--psr-text-title-modal) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.scss"],"names":[],"mappings":"AACE;;EAEE,gBAAA;AAAJ;AACI;;EACE,kBAAA;AAEN;AAAQ;;EACE,wCAAA;AAGV;AAAM;;EACE,4BAAA;AAGR;AADM;;;;;EAIE,4CAAA;EACA,sDAAA;AAIR;AAFU;;;;;EACE,2CAAA;AAQZ;AALQ;;;;;EACE,4CAAA;AAWV;AANE;EACE,uDAAA;AAQJ;AAPI;EACE,gBAAA;EACA,4BAAA;AASN;AARM;EAIE,6CAAA;EACA,wCAAA;AAOR;AANQ;EACE,6CAAA;AAQV","sourcesContent":[".navbar-menu {\n  .sidenav,\n  .nav-admin {\n    max-width: 270px;\n    &__action-button {\n      min-height: 42.5px;\n      base-icon {\n        ::ng-deep ion-icon {\n          fill: var(--psr-input-border) !important;\n        }\n      }\n      .span-text {\n        color: var(--psr-text-color);\n      }\n      &.active,\n      &:active,\n      &:hover,\n      &:focus {\n        color: var(--psr-radio-button-bg) !important;\n        background-color: var(--psr-lighter-purple) !important;\n        base-icon {\n          ::ng-deep ion-icon {\n            fill: var(--psr-radio-button-bg) !important;\n          }\n        }\n        .span-text {\n          color: var(--psr-radio-button-bg) !important;\n        }\n      }\n    }\n  }\n  .nav-admin {\n    background-color: var(--psr-input-border-62) !important;\n    &__action-button {\n      min-height: auto;\n      color: var(--psr-text-color);\n      &.active,\n      &:active,\n      &:hover,\n      &:focus {\n        color: var(--psr-text-title-modal) !important;\n        background-color: transparent !important;\n        .span-text {\n          color: var(--psr-text-title-modal) !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
