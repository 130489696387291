import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from '@providers/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [authGuard],
    data: {
      title: 'home',
    },
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [authGuard],
    data: {
      title: 'login',
    },
  },
  {
    path: 'suspended-access',
    loadChildren: () =>
      import('@pages/suspended-access/suspended-access.module').then(
        m => m.SuspendedAccessModule
      ),
    data: {
      title: 'suspended-access',
    },
  },
  {
    path: '404',
    loadChildren: () =>
      import('@pages/not-found/not-found.module').then(
        mod => mod.NotFoundModule
      ),
    data: {
      title: '404',
    },
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
