import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-compound-card',
  templateUrl: './compound-card.component.html',
  styleUrls: ['./compound-card.component.scss'],
})
export class CompoundCardComponent {
  @Input() classCard: string;
}
