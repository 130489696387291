// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  height: 1px;
  background-color: var(--psr-input-border);
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/compound-card/compound-card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yCAAA;EACA,YAAA;AACF","sourcesContent":["hr {\n  height: 1px;\n  background-color: var(--psr-input-border);\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
