export enum POLITICALLY_EXPOSED_PERSO {
  YES = 'yes',
  NO = 'no',
}

export enum TabType {
  NEW_ACCOUNT = 'newAccount',
  CONNECTED_SUBSCRIPTIONS = 'connectedSubscriptions',
  ECOMMERCE_CREDENTIALS = 'ecommerceCredentials',
  // SINPE_CREDENTIALS = 'sinpeCredentials'
}

export enum RequestType {
  BOTH = 'both',
  ONLY_ECOMMERCE = 'ecommerce',
}

// TODO - MISSING BACKEND DATA
export enum PaymentGatewayType {
  PERCENTAGE = 'Percentage',
  FIXED = 'Flat',
}

export enum TypeIdSelected {
  COMPANY = 1,
  REPRESENTATIVE = 2,
  ONLY_CREDENTIALS = 3,
}

export enum RequestResponse {
  SUCCESS = 'success',
  FAILED = 'failed',
  LOADING = 'loading',
  EMPTY = '',
}

export enum TypeOtp {
  EMAIL = 'Email',
  PHONE = 'Sms',
}

export enum MethodName {
  DELETE_CREDENTIAL_ECOMMERCE = 'deleteCredentialEcommerce',
  UPDATE_CREDENTIAL_ECOMMERCE = 'updateCredentialEcommerce',
  SAVE_UPDATE_CONNECTION = 'saveConnection',
  DISCONNECT_CREDENTIAL = 'disconnectCredential',
}
