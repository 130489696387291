import { Component, OnInit } from '@angular/core';
import { StorageKeys } from '@enums/storage-keys.enum';
import { StorageService } from '@providers/storage/storage.service';
import { isLessThanXMins, ITEM_CACHE, TIMES_TO_CHECK } from '@data/times';
import { ModalService } from '@providers/modal/modal.service';
import { ModalImageComponent } from '@components/modal-image/modal-image';
import { Router } from '@angular/router';
import { ROUTES_ADMIN } from '@constants/routes';
import { ModalsFullScreen } from '@enums/modals-fullscreen.enum';
import { TranslationService } from '@providers/translation-new/translation.service';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { LoginService } from '@endpoints/login.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { SharedService } from '@providers/shared/shared.service';
import { CallCacheEndpointsService } from '@providers/call-cache-endpoints/call-cache-endpoints.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private cache: CallCacheEndpointsService,
    private loading: LoaderManagmentService,
    private loginService: LoginService,
    private modalService: ModalService,
    private router: Router,
    private sharedService: SharedService,
    private storageService: StorageService,
    private translation: TranslationService,
    private userManager: UserManagerService
  ) {}

  ngOnInit(): void {
    this.handleSubscriptions();
  }

  getImage(): string {
    return this.userManager.imageUser;
  }

  getEmailUser(): string {
    return this.userManager.email;
  }

  getNameUser(): string {
    return this.userManager.name;
  }

  async handleSubscriptions(): Promise<void> {
    const listSubscriptionData = await this.cache.getInitialValues(
      ITEM_CACHE.subscription,
      ITEM_CACHE.subscriptionTime,
      ITEM_CACHE.subscriptionLocal,
      TIMES_TO_CHECK.TEN_MIN
    );

    const hasSubscription = await this.storageService.getData(
      StorageKeys.MISSING_SUBSCRIPTION
    );
    let validTime;
    if (hasSubscription) {
      validTime = isLessThanXMins(
        new Date(),
        new Date(hasSubscription.time),
        TIMES_TO_CHECK.WEEK
      );
    }

    const isNotSuspended =
      this.router.url !== ROUTES_ADMIN.SUSPENDED_ACCESS &&
      this.router.url !== ROUTES_ADMIN.LOGIN_ROUTE;
    if (
      isNotSuspended &&
      listSubscriptionData?.length === 0 &&
      (!hasSubscription || (!hasSubscription.subscription && !validTime))
    ) {
      const {
        subscription: { remindSubscription },
        actions,
      } = this.translation.getLangMessagesByKeys(['actions', 'subscription']);
      const componentProps = {
        isPage: false,
        title: remindSubscription.title,
        description: remindSubscription.desc,
        btnCancel: actions.close,
        btnSave: remindSubscription.add,
        img: './assets/img/rocket.svg',
        modalId: ModalsFullScreen.SUBSCRIPTION_REMINDER_MODAL,
      };
      const result = await this.modalService.openModal(
        ModalImageComponent,
        componentProps,
        'modal-passer-remind ww-100 -max-w-520px',
        ModalsFullScreen.SUBSCRIPTION_REMINDER_MODAL
      );
      if (result) {
        this.router.navigate([ROUTES_ADMIN.SUBSCRIPTION_SUB_ROUTE.NEW]);
      }
      this.storageService.setData(StorageKeys.MISSING_SUBSCRIPTION, {
        subscription: false,
        time: new Date().getTime(),
      });
    }
  }

  changeTheme(event) {
    this.storageService.setData(StorageKeys.DARK_MODE, event);
    this.sharedService.changeTheme(event);
  }

  logOut(): void {
    this.loading.presentLoading();
    this.loginService.logoutMethod();
  }
}
