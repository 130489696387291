import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TwoFactorSelector, TypeSendCode } from '@enums/two-factor.enum';
import { IndPhone } from '@models/entities/general';
import { CodeVerify } from '@models/entities/user';
import { User } from '@models/state/user.model';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
})
export class TwoFactorComponent implements OnChanges {
  @Input() user: User;
  @Output() emitSendTwoFactor = new EventEmitter<CodeVerify>();
  @Output() returnEmit = new EventEmitter<void>();

  phone: IndPhone;
  email = '';
  phoneText = '';
  emailText = '';
  isPhone = TwoFactorSelector.phone;
  twoFactor = TwoFactorSelector;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.user?.currentValue) {
      this.phone = this.user.phone;
      this.email = this.user.email;
      this.setEmailPhone();
    }
  }

  setEmailPhone() {
    if (this.phone) {
      const { nationalNumber } = this.phone;
      this.phoneText = nationalNumber.charAt(0);
      this.phoneText += `*** *${nationalNumber.slice(-3)}`;
    }
    if (this.email) {
      const splitEmluser = this.email.split('@'),
        countpoint = splitEmluser[0].slice(1);
      this.emailText = splitEmluser[0].charAt(0);
      this.emailText += `${countpoint.replace(/[\S]/g, '*')}@${
        splitEmluser[1]
      }`;
    }
  }

  sendTwoFactor() {
    this.emitSendTwoFactor.emit({
      resend: null,
      to:
        this.isPhone === TwoFactorSelector.phone ? this.phone.e164 : this.email,
      email: this.email,
      type: this.isPhone ? TypeSendCode.PHONE : TypeSendCode.EMAIL,
    });
  }

  returnLogin(): void {
    this.returnEmit.emit();
  }
}
