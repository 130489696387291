import { createAction, props } from '@ngrx/store';
import { PagePosition } from '@models/state/auth.state';
import {
  ManagementGroup,
  RoleAssignment,
  TwoFactorData,
  User,
} from '@models/state/user.model';
import { ReSend } from '@models/entities/user';
import { IdPAccessTokenResponse } from '@models/responses/response-login';
import { ParamsDeleteAccess, ParamsUserData } from '@models/entities/account';

export const LOGIN_START = '[auth page] login start';
export const LOGIN_SUCCESS = '[auth page] login Success';
export const LOGIN_FAIL = '[auth page] login Fail';
export const REFRESH_TOKEN = '[auth page] login refreshToken';
export const AUTO_REFRESH_TOKEN_SUCCESS =
  '[auth page] login autoRefreshTokenSuccess';

export const SIGNUP_START = '[auth page] signup start';
export const SIGNUP_SUCCESS = '[auth page] signup success';

export const AUTO_LOGIN_ACTION = '[auth page] auto login';
export const AUTO_LOGIN_SUCCESS = '[auth page] auto login Success';

export const SUSPENDED_ACCESS_LOGOUT =
  '[auth state] logout by suspended access';
export const LOGOUT = '[auth state] logout';
export const LOGOUT_SUCCESS = '[auth state] logout success';
export const LOGOUT_ACTION = '[auth page] logout';

export const RECOVER_PASSWORD = '[auth page] recover password';
export const RECOVER_REDIRECT = '[auth page] recover redirect';
export const RECOVER_MESSAGE = '[auth page] recover showMessage';

export const RESET_PASSWORD = '[auth page] reset password';
export const RESET_PASSWORD_REDIRECT = '[auth page] reset password redirect';

export const CHANGE_POSITION = '[auth page] change position';

export const SET_ACCESS_LEVEL = '[auth page] set access level';
export const SET_USER = '[auth page] set user';

export const USERINFO = '[auth page] userinfo';
export const USERINFO_MANAGEMENTGROUP = '[auth page] userinfo managementGroup';
export const USER_MANAGMENTGROUP_REDIRECT =
  '[auth page] userinfo managementGroup redirect';
export const USERINFO_ROLEASSIGNMENT = '[auth page] userinfo roleAssignment';
export const SET_MANAGEMENT_GROUPS = '[auth page] set management groups';

export const UPDATE_USER_DATA = '[auth page] update user data';
export const UPDATE_USER_DATA_SUCCESS = '[auth page] update user data success';

export const TWO_FACTOR_DATA = '[auth page] two factor data';
export const CLEAR_TWO_FACTOR_DATA = '[auth page] clear two factor data';

export const DELETE_ACCESS_GROUP = '[auth page] delete access group';
export const DELETE_CHANGE_ACCESS_GROUP_SUCCESS =
  '[auth page] delete/change access group success';

export const twoFactorData = createAction(
  TWO_FACTOR_DATA,
  props<TwoFactorData>()
);

export const clearTwoFactorData = createAction(CLEAR_TWO_FACTOR_DATA);

export const loginStart = createAction(
  LOGIN_START,
  props<{ email: string; password: string }>()
);

export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{
    user: User;
    activeManagementGroupId: string;
    roleAssignment: RoleAssignment;
    redirect: boolean;
    updateManagementGroup: boolean;
    verifyCode: boolean;
    deleteAccess: boolean;
  }>()
);

export const recoverPassword = createAction(
  RECOVER_PASSWORD,
  props<{ email: string; resend: ReSend | null }>()
);

export const recoverRedirect = createAction(RECOVER_REDIRECT);

export const recoverMessage = createAction(
  RECOVER_MESSAGE,
  props<{ success: boolean; message: string }>()
);

export const resetPassword = createAction(
  RESET_PASSWORD,
  props<{ password: string; confirmPassword: string }>()
);

export const resetPasswordRedirect = createAction(RESET_PASSWORD_REDIRECT);
export const userManagementGroupRedirect = createAction(
  USER_MANAGMENTGROUP_REDIRECT,
  props<{ managementGroups: ManagementGroup[]; user: User }>()
);

export const changePosition = createAction(
  CHANGE_POSITION,
  props<{ pagePosition: PagePosition }>()
);

export const setAccessLevel = createAction(
  SET_ACCESS_LEVEL,
  props<{
    level: string;
    levelName: string;
    activeManagementGroup: string;
    managementGroups: ManagementGroup[];
  }>()
);

export const setUser = createAction(SET_USER, props<{ user: User }>());

export const autoLogin = createAction(AUTO_LOGIN_ACTION);

export const autoLoginSuccess = createAction(
  AUTO_LOGIN_SUCCESS,
  props<{ user: User; redirect: boolean }>()
);

export const suspendedAccessLogOut = createAction(
  SUSPENDED_ACCESS_LOGOUT,
  props<{ unauthorized: boolean }>()
);

export const autoLogout = createAction(
  LOGOUT_ACTION,
  props<{
    user: User;
    redirect: boolean;
    managementGroup: string;
    unauthorized?: boolean;
  }>()
);

export const logOut = createAction(LOGOUT, props<{ unauthorized: boolean }>());

export const logoutSuccess = createAction(
  LOGOUT_SUCCESS,
  props<{ unauthorized: boolean }>()
);

export const userInfo = createAction(
  USERINFO,
  props<{ access_token: IdPAccessTokenResponse }>()
);

export const userManagementGroup = createAction(
  USERINFO_MANAGEMENTGROUP,
  props<{
    user: User;
    access_token: IdPAccessTokenResponse;
    deleteAccess?: string;
  }>()
);

export const userRoleAssignment = createAction(
  USERINFO_ROLEASSIGNMENT,
  props<{
    activeManagementGroupId: string;
    user: User;
    access_token: IdPAccessTokenResponse;
    updateManagementGroup?: boolean;
    deleteAccess?: boolean;
  }>()
);

export const setManagementGroups = createAction(
  SET_MANAGEMENT_GROUPS,
  props<{ managementGroups: ManagementGroup[] }>()
);

export const refreshToken = createAction(
  REFRESH_TOKEN,
  props<{
    user: User;
    activeManagementGroupId: string;
    roleAssignment: RoleAssignment;
    redirect: boolean;
    refresh_token: string;
    updateManagementGroup: boolean;
    deleteAccess: boolean;
  }>()
);

export const autoRefreshTokenSuccess = createAction(
  AUTO_REFRESH_TOKEN_SUCCESS,
  props<{ response: IdPAccessTokenResponse }>()
);

export const updateUserAccount = createAction(
  UPDATE_USER_DATA,
  props<{ params: ParamsUserData; user: User }>()
);

export const updateUserAccountSuccess = createAction(
  UPDATE_USER_DATA,
  props<{ status: string; user: User }>()
);

export const deleteAccessGroup = createAction(
  DELETE_ACCESS_GROUP,
  props<ParamsDeleteAccess>()
);

export const deleteChangeAccessGroupSuccess = createAction(
  DELETE_CHANGE_ACCESS_GROUP_SUCCESS,
  props<{ deleteAccess: boolean; reload: boolean }>()
);
