import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTableItem, DataTableHeader } from '@models/entities/datatable';
import { MenuButton } from '@models/entities/general';

@Component({
  selector: 'base-datatable',
  templateUrl: './base-datatable.component.html',
  styleUrls: ['./base-datatable.component.scss'],
})
export class BaseDatatableComponent {
  @Input() color = 'primary';
  @Input() width = 'medium';
  @Input() datatableClass = '';

  @Input() descriptionEmpty = '';
  @Input() titleEmpty = '';

  @Input() isSelectable: boolean = false;
  @Input() loading: boolean = false;
  @Input() actions: MenuButton[] = [];
  @Input() headers: DataTableHeader[] = [];
  @Input() items: DataTableItem[] = [];
  @Input() itemsFilter: DataTableItem[] = [];
  @Input() paginator = 1;
  @Input() totalItems = 0;

  @Output() changePager = new EventEmitter<number>();
  @Output() selectedCheck = new EventEmitter<void>();
  @Output() selectedMenu = new EventEmitter<{ option: number; currentIndex: number; }>();

  changePaginator(pager: number): void {
    this.paginator = pager;
    this.changePager.emit(pager);
  }

  changeCheckSelected(indexRow: number, act: boolean) {
    this.items.forEach((row, i) => (row.isSelected = i === indexRow && act));
    this.selectedCheck.emit();
  }

  selectedOptionMenu(option: number, currentIndex: number) {
    this.selectedMenu.emit({ option, currentIndex });
  }
}