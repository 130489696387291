import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: 'skeleton.html',
  styleUrls: ['skeleton.scss'],
})
export class SkeletonComponent {
  items = new Array(7).fill('');
  @Input() element: any;
}
