import { Injectable } from '@angular/core';
import {
  CrudAccess,
  CrudAccessSecurity,
  CrudAddedCredentials,
  CrudCache,
  CrudConnect,
  CrudCredentials,
  CrudCustomer,
  CrudSubscription,
} from '@models/entities/general';

@Injectable({
  providedIn: 'root',
})
export class EventCallbackService {
  getCrudCredentials(): CrudCredentials {
    return { EDIT: 0, DISCONNECT_CARD: 1, DISCONNECT_SINPE: 2 };
  }

  getCrudAddedCredentials(): CrudAddedCredentials {
    return { EDIT: 0, DELETE: 1 };
  }

  getCrudCustomer(): CrudCustomer {
    return { ASSOCIATED: 0 };
  }

  getCrudAccess(): CrudAccess {
    return { CONTROL_ACCESS: 0, HISTORY_ACTIONS: 1, UNSUBSCRIBE: 2 };
  }

  getCrudSubscription(): CrudSubscription {
    return { CONNECT: 0, DISCONNECT: 1, DETAILS: 2 };
  }

  getCrudConnect(): CrudConnect {
    return { TOKEN: 0, REGENERATE: 1, COPY: 2, CONFIGURE: 3 };
  }

  getCrudAccessSecurity(): CrudAccessSecurity {
    return {
      CLOSE_ALL_AND_TWO_FACTOR: 3,
      CLOSE_ALL_SESSIONS: 2,
      CLOSE_AND_TWO_FACTOR: 4,
      CLOSE_SESSION: 1,
    };
  }

  getCrudObjectCach(): CrudCache {
    return {
      country: 1,
      idType: 2,
      businessActivity: 3,
      position: 4,
      fund: 5,
      currency: 6,
      subscription: 7,
      ecommerceCredentials: 8,
      paymentMethods: 9,
      billings: 10,
      systemRoles: 11,
      actions: 12,
      privileges: 13,
    };
  }

  handleEventCallback = async (key, callbacks) => {
    if (callbacks && callbacks[key]) return await callbacks[key]();
  };
}
