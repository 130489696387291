import { Injectable } from '@angular/core';
import { AccessLevelType } from '@enums/accessType.enum';
import { User } from '@models/state/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserManagerService {
  private _currentUser: User;
  private _level: string;
  private _levelName: string;

  clearDataUser(): void {
    this._currentUser = undefined;
    this._level = '';
    this._levelName = '';
  }

  public get currentUser(): User {
    return this._currentUser;
  }

  public set currentUser(user: User) {
    this._currentUser = user;
  }

  public get levelName(): string {
    return this._levelName;
  }

  public set levelName(name: string) {
    this._levelName = name;
  }

  public get level(): string {
    return this._level;
  }

  public set level(name: string) {
    this._level = name;
  }

  public get isOwner(): boolean {
    return this._level === AccessLevelType.OWNER;
  }

  public get email(): string {
    return this._currentUser?.email || '';
  }

  public get sub(): string {
    return this._currentUser?.sub || '';
  }

  public get middle_name(): string {
    return this._currentUser?.middle_name || '';
  }

  public get e164(): string {
    return this._currentUser?.phone?.e164 || '';
  }

  public get name(): string {
    return this._currentUser?.name;
  }

  public get imageUser(): string {
    return this._currentUser?.photo || './assets/img/user.png';
  }
}
