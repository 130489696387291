import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  AccessSecurityParams,
  ParamsChangeAccess,
  ParamsCreateUser,
  ParamsUserLog,
} from '@models/entities/access';
import {
  ResponseActions,
  ResponseChangeAccess,
  ResponseCreateAccessUser,
  ResponseSystemRoleList,
  ResponseUserDataList,
  ResponseUserLog,
} from '@models/responses/response-access';
import { ResponseGeneral } from '@models/responses/response-general';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessSecurityService {
  url: string;

  private readonly userDataList = '/api/userdata';
  private readonly systemRoleList = '/api/systemRoles';
  private readonly getUserLogData = '/api/userlog';
  private readonly getActionList = '/api/userlog/actions';
  private readonly closeSessions = '/api/userdata/logout';
  private readonly changeAccessRole = '/api/userdata/systemroles';
  private readonly unsubscribeUser = '/api/userdata/unsubscribe';

  constructor(
    private http: HttpClient,
    private methodGeneral: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  getSystemRoleList(): Observable<ResponseSystemRoleList> {
    return this.http.get<ResponseSystemRoleList>(
      this.url + this.systemRoleList
    );
  }

  getUserDataList(
    params: AccessSecurityParams
  ): Observable<ResponseUserDataList> {
    return this.http.get<ResponseUserDataList>(
      this.url +
        this.userDataList +
        '?' +
        this.methodGeneral.toQueryString(params)
    );
  }

  createAccessUser(
    params: ParamsCreateUser
  ): Observable<ResponseCreateAccessUser> {
    return this.http.post<ResponseCreateAccessUser>(
      this.url + this.changeAccessRole,
      params
    );
  }

  getUserLog(params: ParamsUserLog): Observable<ResponseUserLog> {
    return this.http.get<ResponseUserLog>(
      this.url +
        this.getUserLogData +
        '?' +
        this.methodGeneral.toQueryString(params)
    );
  }

  getActions(): Observable<ResponseActions> {
    return this.http.get<ResponseActions>(this.url + this.getActionList);
  }

  setCloseAllSessions(
    managementGroup: string,
    hasMfa: boolean
  ): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(this.url + this.closeSessions, {
      managementGroup,
      hasMfa,
    });
  }

  closeUserSession(
    id: number,
    params: { hasMfa: boolean }
  ): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(
      `${this.url}${this.closeSessions}/${id}`,
      params
    );
  }

  deleteAccess(id: number): Observable<ResponseGeneral> {
    return this.http.delete<ResponseGeneral>(
      this.url + this.changeAccessRole + '/' + id
    );
  }

  changeAccess(
    params: ParamsChangeAccess,
    id: number
  ): Observable<ResponseChangeAccess> {
    return this.http.put<ResponseChangeAccess>(
      this.url + this.changeAccessRole + '/' + id,
      params
    );
  }

  async unsubscribeAnUser(id: number): Promise<ResponseGeneral> {
    const unsubscribe$ = this.http.delete<ResponseGeneral>(
      this.url + this.unsubscribeUser + '/' + id
    );

    return await lastValueFrom(unsubscribe$);
  }
}
