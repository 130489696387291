import { IndPhone } from '@models/entities/general';

export class User {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  middle_name: string;
  email: string;
  email_verified: boolean;
  birthdate: any;
  sex: Sex;
  id: number;
  username: string;
  phone: IndPhone;
  photo: string;
  urlavatar: string;
  hasMfa: boolean;
}

export enum Sex {
  Male = 'Male',
  Female = 'Female',
}

export interface ManagementGroup {
  _id: string;
  assignedRole: AssignedRole;
  owner: OwnerData;
  type: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface RoleAssignment {
  createdAt: string;
  principalId?: string;
  principalType: number;
  roleDefinition: Role;
  scope: string;
  scopeType: string;
  updatedAt: string;
  _id: string;
}

export class Role {
  roleName?: string;
  description?: string;
  displayName: string;
  permissions?: Permission[];
  _id: string;
}

export class Permission {
  public actions?: string[];
  public notActions?: string[];
  public dataActions?: string[];
  public notDataActions?: string[];
  public assignableScopes?: string[];
}

interface AssignedRole {
  description: string;
  displayName: string;
  roleName: string;
  _id: string;
}

interface OwnerData {
  accountId: string;
  email: string;
  name: string;
}

export interface TwoFactorData {
  user: User;
  activeManagementGroupId: string;
  roleAssignment: RoleAssignment;
}
