export const TIMES_TO_CHECK = {
  WEEK: 10080,
  MONTH: 43800, // 1 MONTH
  HOUR: 60, // 1 HOUR
  HALF_HOUR: 30, // 30 MINUTES
  MEDIUM_DAY: 720, // 12 HOURS
  day: 1440, // 24 HOURS
  TEN_MIN: 10, // 10 MINUTES
  YAER: 525600, // 1 YEAR
};

export const isLessThanXMins = (currentDate, lastDate, mins) => {
  const diff = currentDate - lastDate;
  const minutes = diff / 60000;
  return minutes <= mins;
};

export const ITEM_CACHE = {
  country: 1,
  countryTime: 'lastTimeCountry',
  countryLocal: 'countryItem',
  idType: 2,
  idTypeTime: 'lastTimeIdType',
  idTypeLocal: 'idTypeItem',
  businessActivity: 3,
  businessActivityTime: 'lastTimeBusinessAct',
  businessActivityLocal: 'businessActItem',
  position: 4,
  positionTime: 'lastTimePosition',
  positionLocal: 'positionItem',
  fund: 5,
  fundTime: 'lastTimeFund',
  fundLocal: 'fundItem',
  currency: 6,
  currencyTime: 'lastTimeCurrency',
  currencyLocal: 'currencyItem',
  subscription: 7,
  subscriptionTime: 'lastTimeSubscription',
  subscriptionLocal: 'subscriptionItem',
  ecommerceCredentails: 8,
  ecommerceCredentailsTime: 'lastTimeEcommerceCred',
  ecommerceCredentailsLocal: 'ecommerceCredItem',
  paymentMethods: 9,
  paymentMethodsTime: 'lastTimePaymentMethod',
  paymentMethodsLocal: 'paymentMethodItem',
  billings: 10,
  billingsTime: 'lastTimeBilling',
  billingsLocal: 'billingItem',
  systemRoles: 11,
  systemRolesTime: 'lastTimeSysRole',
  systemRolesLocal: 'sysRoleItem',
  actions: 12,
  actionsTime: 'lastTimeActions',
  actionsLocal: 'actionsItem',
  privileges: 13,
  privilegesTime: 'lastTimePrivileges',
  privilegesLocal: 'privilegesItem',
};
