// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class LoaderManagmentService {
  private skeleton = false;
  private loader = false;

  presentLoading(): void {
    this.loader = true;
  }

  dismissLoading(): void {
    this.loader = false;
  }

  presentSkeleton(): void {
    this.skeleton = true;
  }

  dismissSkeleton(): void {
    this.skeleton = false;
  }

  isLoading(): boolean {
    return this.loader;
  }

  isSkeleton(): boolean {
    return this.skeleton;
  }
}
