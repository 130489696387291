// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container .tabset__tabs.w-100 {
  width: 100% !important;
}
.content-container .tabset__all-tabs {
  width: calc(100% - 294px);
}

@media screen and (max-width: 500px) {
  .tabset__tabs {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/list-tab/list-tab.scss"],"names":[],"mappings":"AAEI;EACE,sBAAA;AADN;AAGI;EACE,yBAAA;AADN;;AAMA;EACE;IACE,sBAAA;EAHF;AACF","sourcesContent":[".content-container {\n  .tabset {\n    &__tabs.w-100 {\n      width: 100% !important;\n    }\n    &__all-tabs {\n      width: calc(100% - 294px);\n    }\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .tabset__tabs {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
