import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  AccessSecurityParams,
  ParamsChangeAccess,
  ParamsCreateUser,
  ParamsUserLog,
} from '@models/entities/access';
import {
  ResponseActions,
  ResponseChangeAccess,
  ResponseCreateAccessUser,
  ResponsePrivileges,
  ResponseSystemRoleList,
  ResponseUserDataList,
  ResponseUserLog,
} from '@models/responses/response-access';
import { ResponseGeneral } from '@models/responses/response-general';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessSecurityService {
  url: string;

  private readonly systemRoles = '/api/systemroles';
  private readonly userDataList = '/api/userdata';
  private readonly systemRoleList = '/api/systemRoles';
  private readonly getUserLogData = '/api/userlog';
  private readonly getActionList = '/api/userlog/actions';
  private readonly closeSessions = '/api/userdata/logout';
  private readonly changeAccessRole = '/api/userdata/systemroles';
  private readonly unsubscribeUser = '/api/userdata/unsubscribe';
  private readonly privileges = '/api/systemroles/byAccount';

  constructor(
    private http: HttpClient,
    private methodGeneral: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  async deleteAccessGroup(
    activeManagementGroupId: string
  ): Promise<ResponseGeneral> {
    const delete$ = this.http.delete<ResponseGeneral>(
      `${this.url}${this.systemRoles}/${activeManagementGroupId}`
    );

    return await lastValueFrom(delete$);
  }

  async getSystemRoleList(): Promise<ResponseSystemRoleList> {
    const systemRole$ = this.http.get<ResponseSystemRoleList>(
      this.url + this.systemRoleList
    );

    return await lastValueFrom(systemRole$);
  }

  async getUserDataList(
    params: AccessSecurityParams
  ): Promise<ResponseUserDataList> {
    const users$ = this.http.get<ResponseUserDataList>(
      this.url +
        this.userDataList +
        '?' +
        this.methodGeneral.toQueryString(params)
    );

    return await lastValueFrom(users$);
  }

  async createAccessUser(
    params: ParamsCreateUser
  ): Promise<ResponseCreateAccessUser> {
    const createUser$ = this.http.post<ResponseCreateAccessUser>(
      this.url + this.changeAccessRole,
      params
    );

    return await lastValueFrom(createUser$);
  }

  async getUserLog(params: ParamsUserLog): Promise<ResponseUserLog> {
    const logs$ = this.http.get<ResponseUserLog>(
      this.url +
        this.getUserLogData +
        '?' +
        this.methodGeneral.toQueryString(params)
    );

    return await lastValueFrom(logs$);
  }

  async getActions(): Promise<ResponseActions> {
    const actions$ = this.http.get<ResponseActions>(
      this.url + this.getActionList
    );
    return await lastValueFrom(actions$);
  }

  async setCloseAllSessions(
    managementGroup: string,
    hasMfa: boolean
  ): Promise<ResponseGeneral> {
    const all$ = this.http.post<ResponseGeneral>(
      this.url + this.closeSessions,
      {
        managementGroup,
        hasMfa,
      }
    );

    return await lastValueFrom(all$);
  }

  async closeUserSession(
    id: number,
    params: { hasMfa: boolean }
  ): Promise<ResponseGeneral> {
    const closeSession$ = this.http.post<ResponseGeneral>(
      `${this.url}${this.closeSessions}/${id}`,
      params
    );

    return await lastValueFrom(closeSession$);
  }

  async deleteAccess(id: number): Promise<ResponseGeneral> {
    const delete$ = this.http.delete<ResponseGeneral>(
      this.url + this.changeAccessRole + '/' + id
    );

    return await lastValueFrom(delete$);
  }

  async changeAccess(
    params: ParamsChangeAccess,
    id: number
  ): Promise<ResponseChangeAccess> {
    const change$ = this.http.put<ResponseChangeAccess>(
      this.url + this.changeAccessRole + '/' + id,
      params
    );

    return await lastValueFrom(change$);
  }

  async unsubscribeAnUser(id: number): Promise<ResponseGeneral> {
    const unsubscribe$ = this.http.delete<ResponseGeneral>(
      this.url + this.unsubscribeUser + '/' + id
    );

    return await lastValueFrom(unsubscribe$);
  }

  async getPrivileges(): Promise<ResponsePrivileges> {
    const privileges$ = this.http.get<ResponsePrivileges>(
      this.url + this.privileges
    );

    return await lastValueFrom(privileges$);
  }

  async updatePrivileges(params: any): Promise<ResponseGeneral> {
    const privileges$ = this.http.post<ResponseGeneral>(
      this.url + this.systemRoles,
      params
    );

    return await lastValueFrom(privileges$);
  }
}
