import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'normal-modal',
  templateUrl: './normal-modal.html',
})
export class NormalModalComponent {
  title = '';
  message = '';
  btnCancel = '';
  btnSave = '';
  dataa;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.dataa = data.dataa;
    this.title = data.title;
    this.message = data.message;
    this.btnCancel = data.btnCancel;
    this.btnSave = data.btnSave;
  }

  close(save = false): void {
    this.dialogRef.close(save);
  }
}
