import { NgModule } from '@angular/core';
import { FormatPipe } from './format';
import { CreditCardPipe } from './format-creditcard';
import { TranslatePipe } from './translate/translate.pipe';

@NgModule({
  declarations: [CreditCardPipe, FormatPipe, TranslatePipe],
  exports: [CreditCardPipe, FormatPipe, TranslatePipe],
})
export class PipeModule {}
