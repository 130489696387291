import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { SharedService } from '@providers/shared/shared.service';
import { changeTheme, showSnackbar } from '@deprecated/state/actions/shared.actions';

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private sharedService: SharedService
  ) {}

  changeTheme$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(changeTheme),
        tap(theme => {
          return this.sharedService.changeTheme(theme.theme);
        })
      );
    },
    { dispatch: false }
  );

  showSnackbar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(showSnackbar),
        tap(dataSnack => {
          if (dataSnack?.title || dataSnack?.message) {
            this.sharedService.showSnackBar(
              dataSnack.title,
              dataSnack.message,
              dataSnack?.duration
            );
          }
        })
      );
    },
    { dispatch: false }
  );
}
