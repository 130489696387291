import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { countryNameOption } from '@data/countries.data';
import { TranslationService } from '@providers/translation-new/translation.service';

@Component({
  selector: 'app-double-input',
  templateUrl: 'double-input.html',
})
export class DoubleInputComponent {
  @Input() selectLabel: string;
  @Input() selectControl: FormControl;
  @Input() countrySelect = false;
  @Input() list: Array<any> = [];
  @Input() selectOption: string;
  @Output() selectChange = new EventEmitter<void>();

  @Input() inputLabel: string;
  @Input() inputControl: FormControl;
  @Output() inputChange = new EventEmitter<void>();

  constructor(private translation: TranslationService) {}

  changeSelect() {
    this.selectChange.emit();
  }
  changeInput() {
    this.inputChange.emit();
  }

  getSelectName(item: any): string {
    if (this.countrySelect) {
      return countryNameOption(this.translation.currentlanguage, item);
    }
    return item[this.selectOption];
  }
}
