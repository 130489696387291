import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FormatPipe',
})
export class FormatPipe implements PipeTransform {
  transform(str: any = '0', noCurrency = false, exchange = '01'): any {
    let p = 0;
    str = `${str ? str : 0}`;
    if (str.includes('.')) {
      str = str.slice(0, str.indexOf('.') + 3);
      p = str.length - str.indexOf('.');
    }

    for (let i = str.length - 4 - p; i >= 0; i -= 3) {
      str = str.substr(0, i) + str.charAt(i) + '.' + str.substr(i + 1);
    }

    return noCurrency ? str : this.assignCurrency(exchange, str);
  }

  assignCurrency(exchange, amount): string {
    if (parseInt(exchange) === 1) return `₡ ${amount}`;
    else if (parseInt(exchange) === 2) return `$ ${amount}`;
    else return `N ${amount}`;
  }

  deleteComa(price: string): number {
    return Number(price.includes('.') ? price.replace(/\./g, '') : price);
  }

  printDisc(noComma: string, ticket): any {
    return this.deleteComa(noComma) * ticket.discount;
  }
  getTotal(noComma: string, ticket): any {
    const price: number = this.deleteComa(noComma);
    return this.transform(price - this.printDisc(noComma, ticket), false);
  }
}
