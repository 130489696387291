// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-tab {
  width: 100% !important;
}

@media screen and (min-width: 480px) {
  .btn-tab {
    width: auto !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/_base/base-tabs/base-tabs.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF","sourcesContent":[".btn-tab {\n  width: 100% !important;\n}\n\n@media screen and (min-width: 480px) {\n  .btn-tab {\n    width: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
