import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ResponseListPlans,
  ResponseListSubscription,
} from '@models/responses/response-subscription';
import { environment } from '@environments/environment';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { ResponseGeneral } from '@models/responses/response-general';
import { NewSubscription } from '@models/entities/subscription';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  url: string;

  public readonly GET_SUBSCRIPTION = '/api/company/subscription';
  public readonly GET_PLANS = '/api/subscriptions';
  public readonly UPDATE_SUBSCRIPTION = '/api/subscriptions/change';
  public readonly CREATE_SUBSCRIPTION = '/api/company/subscription';

  constructor(
    private http: HttpClient,
    private generalMethods: MethodsGeneralService
  ) {
    this.url = environment.SERVER_URL;
  }

  getSubscriptions(id: string): Observable<ResponseListSubscription> {
    return this.http.get<ResponseListSubscription>(
      `${this.url}${this.GET_SUBSCRIPTION}/${id}`
    );
  }

  getPlansByType(type): Observable<ResponseListPlans> {
    return this.http.get<ResponseListPlans>(
      this.url + this.GET_PLANS + '?' + this.generalMethods.toQueryString(type)
    );
  }

  createSubscription(
    dataSubscription: NewSubscription
  ): Observable<ResponseGeneral> {
    return this.http.post<ResponseGeneral>(
      this.url + this.CREATE_SUBSCRIPTION,
      dataSubscription
    );
  }

  updateSubscription(updateData): Observable<ResponseGeneral> {
    return this.http.put<ResponseGeneral>(
      this.url + this.UPDATE_SUBSCRIPTION,
      updateData
    );
  }

  cancelSubscription(company: number): Observable<ResponseGeneral> {
    return this.http.delete<ResponseGeneral>(
      this.url +
        this.GET_PLANS +
        '?' +
        this.generalMethods.toQueryString({ company })
    );
  }
}
